import React, { useEffect, useState } from "react";
import Formsy from "formsy-react";
import FormsyInputField from "../../../../elements/FormsyInputField";
import generalService from "../../../../../services/generalService";
import { MasterService } from "../../../../../services";

const PartnerFieldVisit = ({ field_list_reason  , handlefieldVisitReason , leadData}) => {
  const options = field_list_reason;
  const [cityList, setCityList] = useState([]);
  const [city_rm_list, setCityRmList] = useState([]);
  const [region_rm_list, setRegionRmList] = useState([]);
  const [yodha_designation , set_yodha_designation] = useState("")
  const [reqObj, setReqObj] = useState({
    purpose_id:  "",
    city_id:"",
    city_name: "",
    dateTime: "",
    regional_head_id: "",
    rm_name: "",
    city_head_id: "",
    city_head_name: "",
    address: "",
    comments: "",
  });
  
  let CITY_OBJ = [
    {
      name : "Delhi",
      id : 1630
    },
    {
      name : "Gurgaon",
      id : 69
    },
    {
      name : "Noida",
      id : 327
    },
    {
      name : "Ghaziabad",
      id : 328
    },
    {
      name : "Faridabad",
      id : 67
    },
    {
      name : "Mumbai",
      id : 188
    },
    {
      name : "Navi Mumbai",
      id : 428
    },
    {
      name : "Hyderabad",
      id : 7
    },
    {
      name : "Jaipur",
      id : 251
    },
    {
      name : "Banglore",
      id : 49
    },
    {
      name : "Other",
      id : -1
    }

  ]

  const Yodha_Designation = [
    {
      label: "Regional Head",
      id : "2"
    },
    {
      label: "City Bussiness Manager",
      id : "4"
    },
    {
      label: "Area Bussiness Manager",
      id : "7"
    },
    {
      label: "Bussiness Manager",
      id : "3"
    }
  ]


  useEffect(() => {
    getCityList();
    
  }, []);
  const formatDateTime = (dateTime) => {
    if (!dateTime) return "";
    const date = new Date(dateTime);
    const formattedDateTime = date.toISOString().slice(0, 16); // Format to "YYYY-MM-DDTHH:mm"
    return formattedDateTime;

  }
  useEffect(() => {
    setReqObj((prev) => ({
      ...prev,
      // purpose_id: leadData?.purpose_id || "",
      // city_id: leadData?.field_visit_city_id || "",
      // regional_head_id: leadData?.rm_id || "",
      // city_head_id:leadData?.city_head_id || "",
      // address: leadData?.field_visit_reason || "",
      // comments :leadData?.field_visit_comments || "",
      // dateTime : formatDateTime(leadData?.connected_at) || ""
    }));
  }, [leadData]);
  
  const handleDesignationChange = (data) => {
    console.log (data.target.value)
    set_yodha_designation(data.target.value)
    getNationalSalesHead(data.target.value);
  }
  const handleChange = (key) => (event) => {
    const value = event.target.value;
    setReqObj((prev) => {
      let updatedObj = { ...prev, [key]: value };
  
      if (key === "city_id") {
        const selectedCity = cityList.find((city) => city.id === Number(value));
        updatedObj.city_name = selectedCity ? selectedCity.name : "";
        // getNationalSalesHead(value);
        // getRegionalSalesHead(value);
      }
  
      if (key === "regional_head_id") {
        const selectedRm = region_rm_list.find((head) => head.id === Number(value));
        updatedObj.rm_name = selectedRm ? selectedRm.name : "";
      }
  
    
      if (key === "city_head_id") {
        const selectedCityHead = city_rm_list.find((head) => head.id === Number(value));
        updatedObj.city_head_name = selectedCityHead ? selectedCityHead.name : "";
      }
      return updatedObj;
    });
  };
  
  const getCityList = async () => {
    try {
      const response = await generalService.stateCityList();
      const city = response?.data?.data?.city || [];
      setCityList(city);
    } catch (error) {
      console.error("Error fetching city list:", error);
    }
  };

  const getNationalSalesHead = async (data) => {
    try {
      let city_id_arr = [reqObj.city_id]
      if (reqObj.city_id == -1){
        city_id_arr =[]
      }
      const response = await MasterService.post("/sfa/dealer/executiveListByCities", {
        search_text: "",
        city_id: city_id_arr,
        vertical_id: 1,
        page: 1,
        role_id: [data],
      });
     

      setCityRmList(response?.data?.data?.executive_data || []);
    } catch (error) {
      console.error("Error fetching city RM list:", error);
    }
  };

  const getRegionalSalesHead = async (city_id) => {
    try {
      let city_id_arr = [city_id]
      if (city_id == -1){
        city_id_arr =[]
      }
      const response = await MasterService.post("/sfa/dealer/executiveListByCities", {
        search_text: "",
        city_id:city_id_arr,
        vertical_id: 1,
        page: 1,
        role_id: [2],
      });
      setRegionRmList(response?.data?.data?.executive_data || []);
    } catch (error) {
      console.error("Error fetching region RM list:", error);
    }
  };
  
  handlefieldVisitReason(reqObj)
  return (
    <Formsy autoComplete="off">
      <div className="row">
        {/* Purpose of Visit */}
        <div className="btnmarkdoneflex col-sm-6 mb-b20">
          <div className="box">
            <select
              value={reqObj.purpose_id}
              onChange={handleChange("purpose_id")}
              required
            >
              <option value="">Purpose of visit*</option>
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.purpose}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="btnmarkdoneflex col-sm-6 mb-b20">
          <div className="box">
            <select
              value={reqObj.city_id}
              onChange={handleChange("city_id")}
              required
            >
              <option value="">Select City*</option>
              {CITY_OBJ.map((city) => (
                <option key={city.name} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Regional Head */}
        <div className="btnmarkdoneflex col-sm-6 mb-b20">
          <div className="box">
            <select
              value={yodha_designation}
              onChange={(e) => {handleDesignationChange(e)}}
              required
            >
              <option value="">Yodha Designation*</option>
              {Yodha_Designation.map((head) => (
                <option key={head.id} value={head.id}>
                  {head.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* City RM */}
        <div className="btnmarkdoneflex col-sm-6 mb-b20">
          <div className="box">
            <select
              value={reqObj.city_head_id}
              onChange={handleChange("city_head_id")}
              required
            >
              <option value="">Select Yodha*</option>
              {city_rm_list.map((head) => (
                <option key={head.id} value={head.id}>
                  {head.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Date/Time */}
        <fieldset className=" col-sm-12 mb-b20">
        
          <FormsyInputField
            id="followup_time"
            name="followup_time"
            type="datetime-local"
            value={reqObj.dateTime}
            onChange={handleChange("dateTime")}
            label="Date/Time*"
          />
        </fieldset>


        {/* Address */}
        <fieldset className="col-sm-12 mb-b20">
          <FormsyInputField
            id="address"
            name="address"
            type="text"
            value={reqObj.address}
            onChange={handleChange("address")}
            label="Address*"
          />
        </fieldset>

        {/* Comments */}
        <fieldset className="col-sm-12 mb-b20">
          <div className="material">
            <textarea
              name="comments"
              id="comments"
              placeholder=" "
              className="form-input"
              rows="2"
              value={reqObj.comments}
              onChange={handleChange("comments")}
            />
            <label data-label="Add Remark*" className="form-label"></label>
          </div>
        </fieldset>
      </div>
    </Formsy>
  );
};

export default PartnerFieldVisit;
