import { useApolloClient } from '@apollo/client';
import Formsy from 'formsy-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext,useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { DISBURSE_REJECTION_ID, IS_USER_ACCESS_PAGE } from '../../../config/constants';
import { SAVE_LOAN_DETAILS } from '../../../services/customer.gql';
import { getLoanDetail } from '../../../store/action/allAction';
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import DocumentGallery from '../DocumentGallery';
import FormFields from "../formFields/customerDetailsFormFields.json";
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import dateFormat from 'dateformat';
import crypto from "../../../config/crypto";

const FORM_FIELDS = FormFields["LOAN_DETAILS"];
const APPLIED_FOR_LOD = FormFields["APPLIED_FOR_LOD"];

const LoanDetails = (props) => {
  const [loanDetails, setLoanDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [balance, setBalance] = useState("");
  const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
  }));
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;
  const client = useApolloClient();
  let navigate = useNavigate(); 
  const dispatch = useDispatch();
  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []
  let editAccess = IS_USER_ACCESS_PAGE("loan_detail", "loan_detail")?.is_edit_access;

  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.customer && lead.leadDetail.lead_details) {    
      let loan_details = { ...lead.leadDetail.lead_details, fulfillment_type: lead?.leadDetail?.fulfillment_type, loan_type: lead?.leadDetail?.loan_type, loan_sub_type: lead?.leadDetail?.loan_sub_type?.split(',') };
      loan_details["re_account_number"] = loan_details["account_number"] || "";
      loan_details["lod_applied_date"] = loan_details["lod_applied_date"] ? dateFormat(loan_details["lod_applied_date"],"dd mmm, yyyy") : "";
      loan_details["offer_share_date"] = loan_details["offer_share_date"] ? dateFormat(loan_details["offer_share_date"],"dd mmm, yyyy") : "";
      setLoanDetails(loan_details);
      setBalance(lead.leadDetail.lead_details?.lod_apply);
    }
  }, [lead]);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    return options;
  }, [masterdata.data]);

  const handleInputChange = event => {
    if (event.target.name) {
      setLoanDetails(currentValue => ({
        ...currentValue,
        [event.target.name]: event.target.value
      }))
    }
  }

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setLoanDetails(currentValue => ({
        ...currentValue,
        [field_name]: date
      }))
    }
  }

  const BalanceTransfer =(event) =>{    
    const { value } = event.target;
    setBalance(value);
  }

  const handleMultiCheckbox = event => {
    if (event.target.name) {
      let loan_sub_type = (loanDetails?.loan_sub_type?.length && loanDetails.loan_sub_type)  || [];
      if (event.target.checked) loan_sub_type.push(event.target.value);
      else {
          const index = loan_sub_type.indexOf(event.target.value);
          if (index > -1) loan_sub_type.splice(index, 1); 
      }
      setLoanDetails(currentValue => ({ ...currentValue, loan_sub_type: loan_sub_type }))
    }
  }

  const handlePatternFormatChange = (data, field_name) => {
    if (field_name && data.value !== undefined) {
      setLoanDetails(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setLoanDetails(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleSubmit = () => {
    let lead_id = lead?.leadDetail?.id || "";

    let mutation = SAVE_LOAN_DETAILS(user_id),
      variables = {
        api_called_by: 'web',
        LeadAdditionalInput: {
          lead_id: lead_id,
          lead_details: {
            loan_amount: loanDetails?.loan_amount,
            tenure: loanDetails?.tenure || 1,
            fulfillment_type: loanDetails?.fulfillment_type,
            loan_type: +loanDetails?.loan_type,
            loan_sub_type: loanDetails?.loan_sub_type?.join(",") || '',
            lod_applied_date: loanDetails["lod_applied_date"] && balance>0 && loanDetails?.loan_sub_type.includes("1") ? dateFormat(loanDetails["lod_applied_date"],"dd mmm, yyyy") : "",
            lod_apply: loanDetails.loan_sub_type?.includes("1") ? balance : null,
            offer_share_date:loanDetails["offer_share_date"] ? dateFormat(loanDetails["offer_share_date"], "dd mmm, yyyy") : ""
          }
        }
      };
    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.LeadAdditionalInput || null;
      if (response && !response.success) {
        throw new Error(response.error || "Something went wrong");
      }
      toast.success("Success");
      dispatch(getLoanDetail(lead_id, client))

    })
      .catch(err => {
        toast.error(err.message);
      })
  }

  const movetoNext = ()=>{ 
    let lead_id = lead?.leadDetail?.id || ""; 
    handleSubmit(); 
    if(allowSubmit){
      navigate(`/lead-detail/customer-details/basic-details/${crypto.encode(lead_id)}`);   
    } 
  }

  const maxDate = new Date();
maxDate.setDate(maxDate.getDate() + 90);

const minDate = new Date();
minDate.setDate(minDate.getDate() - 90);

  let LoanDetailsForm = FORM_FIELDS;
  const rejectDisable = (lead.leadDetail.reject_reason_id > 0 && !DISBURSE_REJECTION_ID.includes(lead.leadDetail.reject_reason_id) && [2, 3].includes(lead.leadDetail.status_id)) ? true : false
  const  IS_LOST = (lead?.leadDetail?.status_id ==5)? true: false 

   return (
    <div className="image-form-outer">
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="4" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <h2>Loan Requirements</h2>
          </div>

          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className='loanfulfillment' style={{marginBottom:30}}>
              <div className='loaninput'>
                <input type="radio" required id="ambakFulfillment" name="fulfillment_type" value="ambak" checked={loanDetails.fulfillment_type === 'ambak'} onChange={handleInputChange} />
                <label htmlFor='ambakFulfillment'> Ambak Fulfillment</label>
              </div>
              <div className='loaninput'>
                <input type="radio" required id="selfFulfillment" name="fulfillment_type" value="self" checked={loanDetails.fulfillment_type == "self"} onChange={handleInputChange} />
                <label htmlFor='selfFulfillment'> Self Fulfillment</label>
              </div>
            </div>

            <div className="row">
              {LoanDetailsForm.map((field, index) => (
                ["text", "password", "pattern-format", "number-format"].includes(field.type) ? (
                  <fieldset className="form-filed col-md-6 " key={index}>
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={loanDetails && loanDetails[field.name]}
                      placeholder=" "
                      thousandsGroupStyle="lakh"
                      thousandSeparator={','}
                      decimalSeparator={'.'}
                      label={field.label}
                      onChange={
                        ["pattern-format", "number-format"].includes(field.type)
                          ? (data) => handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={field.validations}
                      validationError={
                        loanDetails[field.name] ? field.validationError : ""
                      }
                      required={field.required}
                    />
                  </fieldset>
                ) :

                  field.type === "dropdown" ? (
                    <fieldset className="single-select col-md-6" key={index}>
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                          options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                          placeholder: field.label,
                          className: "react-select",
                          classNamePrefix: "react-select",
                          value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value == loanDetails[field.name]),
                          isDisabled: field.readOnly,
                          required: true
                        }} 
                        required={field.required}
                        validationError={
                          loanDetails[field.name] ? field.validationError : ""
                        } 
                        validations={{
                          isInOptions: (values) => !!values?.[field.name] 
                        }} 
                        value={loanDetails[field.name]} 
                       onChange={(data) => handleSelectChange(data, field.name)}
                      />
                    </fieldset>
                  ) : null
              ))}

            </div>

            <div className='loanfulfillment' style={{marginBottom:30}}>
              <div className='loaninput'>
                <input type="radio" required id="homeloan" name="loan_type" value="1" checked={loanDetails.loan_type == 1} onChange={handleInputChange} />
                <label htmlFor='homeloan'>Home Loan</label>
              </div>
              <div className='loaninput'>
                <input type="radio" required id="loanproperty" name="loan_type" value="2" checked={loanDetails.loan_type == 2} onChange={handleInputChange} />
                <label htmlFor='loanproperty'>Loan Against Property</label>
              </div>
            </div>

            <div className='loanfulfillment' style={{marginBottom:30}}>
              <div className='loaninput'>
                <input type="checkbox" id="balancetransfer" name="bt" value="1" checked={loanDetails?.loan_sub_type?.includes("1")} onChange={handleMultiCheckbox} />
                <label htmlFor='balancetransfer'>Balance Transfer</label>
              </div>
              <div className='loaninput'>
                <input type="checkbox" id="top-up" name="top_up" value="2" checked={loanDetails?.loan_sub_type?.includes("2")} onChange={handleMultiCheckbox} />
                <label htmlFor='top-up'>Top-Up</label>
              </div>
            </div>

            {loanDetails?.loan_sub_type?.includes("1") ?
            <>
              <p style={{marginBottom:"20px"}}>Have you applied for LOD & Fc Letter ?</p>
                <div className='loanfulfillment' style={{marginBottom:30}}>
                  <div className='loaninput'>
                    <input type="radio"  id="homeloan" name="balance_transfer" value="1" checked={balance === "1"} onChange={BalanceTransfer} />
                    <label htmlFor='homeloan' >Yes</label>
                  </div>
                  <div className='loaninput'>
                    <input type="radio"  id="loanproperty" name="balance_transfer" value="0" checked={balance === "0"} onChange={BalanceTransfer} />
                    <label htmlFor='loanproperty'>No</label>
                  </div>
                </div>
            </>
            :""}
          <div className="row">
            { balance === "1" & loanDetails?.loan_sub_type?.includes("1") ?
            <>
                {APPLIED_FOR_LOD?.map((field) => (
                  field.type === "date-picker" ? (
                    <fieldset className="form-filed col-md-6 ">
                      <FormsyDatePicker
                        name={field.name}
                        selectedDate={loanDetails && loanDetails[field.name] && new Date(loanDetails[field.name])}
                        placeholder={field.label}
                       // minDate={new Date()}
                       // maxDate={maxDate}
                        value={loanDetails && loanDetails[field.name]}
                        onDateSelect={(date) => handleDateChange(date, field.name)}
                        dateFormat="dd MMM, yyyy"
                        validations={field.validations}
                        validationError={
                          loanDetails[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                        hasError={(loanDetails.hasOwnProperty(field.name) && !loanDetails[field.name]) ? true : false}
                      />
                    </fieldset>
                  ) : null
                ))}
               </>      
            :null}
            
                {FORM_FIELDS?.map((field) => (
                  field.type === "date-picker" ? (
                    <fieldset className="form-filed col-md-6">
                      <FormsyDatePicker
                        name={field.name}
                        selectedDate={loanDetails && loanDetails[field.name] && new Date(loanDetails[field.name])}
                        placeholder={field.label}
                        minDate={minDate}
                        maxDate={maxDate}
                        value={loanDetails && loanDetails[field.name]}
                        onDateSelect={(date) => handleDateChange(date, field.name)}
                        dateFormat="dd MMM, yyyy"
                        validations={field.validations}
                        validationError={
                          loanDetails[field.name] ? field.validationError : ""
                        }
                       // required={field.required}
                        hasError={(loanDetails.hasOwnProperty(field.name) && !loanDetails[field.name]) ? true : false}
                      />
                    </fieldset>
                  ) : null
                ))}
            </div>

            <div className="btn-save-remarks btn-save-next">
              <button type="submit" className="btn-primary" disabled={ !allowSubmit || editAccess !== 1 || IS_LOST}>
                Save
              </button>

              <button type="submit" className="btn-primary-outline btn-next" onClick={movetoNext} >
                Next
              </button>
            </div>
          </Formsy>
        </div>
      </div>
    </div>
  );
}
export default LoanDetails;