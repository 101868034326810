import { useApolloClient } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { SETTING } from "../../config/constants";
import { Login_User, VALIDATE_EMAIL, VALIDATE_OTP } from "../../services/login.gql";
import { loggedIn } from '../../store/reducers/userReducer';
import Loader from "../elements/Loader";
import { getUserAccess } from "./../../store/action/allAction";
import Captcha from "./Captcha";
import {USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE} from '../../config/constants';
//Login with gmail changes//
import { GOOGLE_AUTH_URL } from "../../config/constants";
import googleLogo from "./../../webroot/images/google-logo.png";

const OtpLogin = (props) => {
  let {otp, setOtp, LoginData, setLoginData, errorForm, setErrorForm, otpEnabled, setOtpEnabled,isLoading,setIsLoading,parentCallback} = props;
  const dispatch = useDispatch();
  const client = useApolloClient();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [captchaVal , setCaptchaVal] = useState('');
  const [reloadCaptcha, setReloadCaptcha] = useState(1);
  const [timer, setTimer] = useState('00:30');
  const [isAccountBlocked , setIsAccountBlocked] = useState(false)

  //Login with gmail
  const redirectUrl = GOOGLE_AUTH_URL+window.location.href;
  let requestedUser = (new URLSearchParams(window.location.search)).get("userdata");
  const querydata = requestedUser  && requestedUser!=="" ? JSON.parse(requestedUser) : {};
  const googleEmail = querydata && querydata.email ? querydata.email : "";
 
    
    
  useEffect(()=>{
    //Service call with only email
    if(googleEmail){
    setIsLoading(true);
    setTimeout(() => {
      //console.log("VALIDATE_EMAIL", VALIDATE_EMAIL);
      //console.log("VALIDATE_EMAIL", googleEmail);
      executeGraphQLQuery(VALIDATE_EMAIL(googleEmail),client)
      .then(async(response) => {
        let LoginData = response?.data?.login_with_email || {};
        if(LoginData?.id) {
          let user_data = {user_id:LoginData.id,name:LoginData?.name,email:LoginData?.email,mobile:LoginData?.mobile};
          LoginData = {...LoginData,access_token:LoginData.access_token,user_data:user_data}
          localStorage.setItem('user_information', JSON.stringify(LoginData));
          localStorage.setItem('loggedIn', '1');
          await dispatch(getUserAccess(LoginData.id, client));
          toast.success('Logged in successfully');
          setErrorForm({});
           dispatch(loggedIn());
           parentCallback();
           let lead_dashboard = IS_USER_ACCESS_PAGE("menu", "lead_dashboard")?.is_edit_access;
           let partner_dashboard = IS_USER_ACCESS_PAGE("menu", "partner_dashboard")?.is_edit_access;
           let manage_dashboard =  IS_USER_ACCESS_PAGE("menu", "manage_leads")?.is_edit_access;
           let payouts =  IS_USER_ACCESS_PAGE("menu", "payouts")?.is_edit_access;
           if (lead_dashboard === 1 || lead_dashboard === 2) {
             navigate('/dashboard');
             

           } else if (partner_dashboard === 1 || partner_dashboard === 2) {
             navigate('/partner-dashboard');
           }    
           else if (manage_dashboard === 1 || manage_dashboard === 2) {
            navigate('/lead-list');
          }    
          else if (payouts === 1 || payouts === 2) {
            navigate('/schemes/list');
          }  
          else {
            navigate('/no-access');
          }      
        } else {   
          //  toast.error('Account does not exist, inactive, or has been deleted.');
           navigate('/login');
           setTimeout(function(){
            window.location.reload();
          },1500)
        }
    })
    .finally(() => {
      // setIsLoading(false);
    })
    }, 1000);
  }
  },[googleEmail])
  const Ref = useRef(null);
  const inputChangeHandler = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    let mobileRule = /^[0-9]{10}$/;
    let allowNumReg = /^[0-9]*$/;
    let updatedErrorForm = {};

    updatedErrorForm[name] = value === '' ? 'error' : '';

    if (name === 'mobile') {
      updatedErrorForm = {
        ...errorForm,
        [name]: value === '' || !mobileRule.test(value) ? 'error' : '',
      };
    if(allowNumReg.test(value)) setMobile(value);
    }else if (name === 'Password') {
      updatedErrorForm = {
        ...errorForm,
        [name]: value === '' ? 'error' : '',
      };
      setPassword(value);
    } else {
      updatedErrorForm = {
        ...errorForm,
        [name]: value === '' ? 'error' : '',
      };
    }

    setErrorForm({ ...updatedErrorForm });
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / 1000 * 60 * 60) % 24);
      return {
          total, hours, minutes, seconds
      };
  }

  const startTimer = (e) => {
      let { total, minutes, seconds } = getTimeRemaining(e);
      if (total >= 0) {
          setTimer((minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds))
      }
  }

  const clearTimer = (e) => {
      setTimer('00:01');
      if (Ref.current) clearInterval(Ref.current);
      const id = setInterval(() => {
          startTimer(e);
      }, 100)
      Ref.current = id;
  }

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + SETTING.RESEND_OTP_TIME_IN_SECOND);
    return deadline;
  }

  const submitLogin = () => {
    setIsLoading(true);
    if (mobile === '' || password === '') {
      toast.error('Fill all Required Fields');
    } else {
      if (validateMobileNumber(mobile)) {
        executeGraphQLMutation(Login_User, { username: mobile, password }, client)
          .then((result) => {
            const loginData = result?.data?.login_user;
            if (loginData && loginData.message === 'Success') {
              setLoginData(loginData);
              dispatch(loggedIn(loginData));
              setOtpEnabled(true);
              clearTimer(getDeadTime());
              toast.success('OTP sent successfully');
              setErrorForm({});
              setOtp('');
            } 
            else{
              toast.error(loginData.message);
              setReloadCaptcha(1 - reloadCaptcha)
            }
          })
          .catch((error) => {
            toast.error("Invalid Username and Password");
            console.log(error);
            setReloadCaptcha(1 - reloadCaptcha)
          })
          .finally(() => {
            setIsLoading(false);
          })
      }
    }
  };

  const otpChangeHandler = (event) => {
    const value = event.target.value;
    setOtp(value);
    setErrorForm({ ...errorForm, OTP: value === '' ? 'error' : '' });
  };

  const captchaFiledHandler = (val) => {
    setCaptchaVal(val)
  }

  const handleOTPValidation = () => {
    setIsLoading(true);
    setTimeout(() => {
      executeGraphQLQuery(VALIDATE_OTP(mobile,otp,captchaVal),client)
      .then(async(res) => {
        if(res.data.validate_otp.message === 'success') {
          let user_data = {user_id:LoginData.id,name:LoginData.name,email:LoginData.email,mobile:LoginData.mobile};
          LoginData = {...LoginData,access_token:res.data.validate_otp.access_token,user_data:user_data,report_access: res.data.validate_otp.report_access}
          localStorage.setItem('user_information', JSON.stringify(LoginData));
          localStorage.setItem('loggedIn', '1');
          await dispatch(getUserAccess(LoginData.id, client));
          toast.success('OTP Validation Successful');
          setErrorForm({});
           dispatch(loggedIn());
           parentCallback();

          navigate('/dashboard'); 
        }
        else {
          toast.error(res.data.validate_otp.message);
          if (res.data.validate_otp.message === 'Account Blocked otp max try reached') setIsAccountBlocked(true)
          setErrorForm({ ...errorForm, OTP: 'error' });
        }
    })
    .finally(() => {
      setIsLoading(false);
    })
    }, 1000);
    
  };

  const validateMobileNumber = (mobileNumber) => {
    const mobileNumberRegex = /^[0-9]{10}$/;
    if (!mobileNumberRegex.test(mobileNumber)) {
      setIsLoading(false);
      toast.error('Invalid mobile number');
      return false;
    }
    return true;
  };

  return (
    <div className="login-form">
      {/* {!otpEnabled && (
        <fieldset className={errorForm.mobile ? 'error' : ''}>
          <div className="material">
            <input
              id="mobileNo"
              type="number"
              placeholder=" "
              name="mobile"
              className="form-input"
              value={mobile}
              onChange={inputChangeHandler}
            />
            <i className="ic-mobile icn-login"></i>
            <label data-label="Mobile No." className="form-label"></label>
          </div>
        </fieldset>
      )}
      {!otpEnabled && (
        <fieldset className={errorForm.Password ? 'error' : ''}>
          <div className="material">
            <input
              id="password"
              type="password"
              placeholder=" "
              name="Password"
              className="form-input"
              value={password}
              onChange={inputChangeHandler}
            />
            <i className="ic-password icn-login"></i>
            <label data-label="Password" className="form-label"></label>
            <div className="otp-success-icon" style={{ display: "none" }}>
              <i className="ic-check"></i>
            </div>
          </div>
        </fieldset>
      )}
      {otpEnabled && (
        <fieldset className={errorForm.OTP ? 'error' : ''}>
          <div className="material">
            <input
              id="otp"
              type="text"
              placeholder=" "
              name="OTP"
              className="form-input"
              value={otp}
              onChange={otpChangeHandler}
            />
            <i className="ic-otp icn-login">
              <i className="path1"></i>
              <i className="path2"></i>
              <i className="path3"></i>
              <i className="path4"></i>
            </i>
            <span className="otp-time">{timer}</span>
            <label data-label="OTP" className="form-label"></label>
          </div>
        </fieldset>
      )}
      {(props.redisFlag && otpEnabled) ?
        <fieldset className="">
            <Captcha onKeyPressCaptcha={captchaFiledHandler} reloadCaptcha={reloadCaptcha} />
        </fieldset> : ''
      }

      { isLoading ? <Loader/> : ''}
      {
      <>
      <button
        type="button"
        className="btn-primary login-full-width"
        disabled={
          (otpEnabled
            ? otp === '' || isLoading
            : password === '' || mobile === '' || errorForm.mobile || errorForm.Password || isLoading)
        }
        onClick={otpEnabled ? handleOTPValidation : submitLogin}
      >
        {otpEnabled ? 'Verify OTP' : 'Log In'}
      </button>
      </>
      } 

      &nbsp; &nbsp;
      {(timer === "00:00") && !isAccountBlocked ? (
            <button
              type="button"
              disabled={isLoading}
              onClick={submitLogin}
              className="btn-line"
            >
              Resend OTP
            </button>
      ):""} */}
      <fieldset className="">
          <div className='logingsign'>
              <div style={{ marginLeft: 120, marginRight: 10, marginTop: 10, marginBottom: 10 }}> </div>
          </div>
          <a href={redirectUrl} className='logingmail'>
              <img src={googleLogo} alt='logo' width={20} height={20} />
              <div style={{ marginLeft: 5 }}>Sign in with Google</div>
          </a>
      </fieldset>      
    </div>
  );
};

export default OtpLogin;
