import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import FormFields from "../formFields/breOfferFormFields.json";
import Formsy from "formsy-react";
import FormsyInputField from "../../elements/FormsyInputField";
import { useSelector } from "react-redux";

const ITR_FIELDS = FormFields["ITR"];

const BreITRSection = ({
  setITRDetails,
  customerType = "customer",
  coApplicantOfferDetails = [],
}) => {
  const [activeTab, setActiveTab] = useState("year_1");
  const [itrDetails, setItrDetails] = useState({});
  const [isFormTouch, setTouch] = useState({});

  const lead = useSelector((state) => state.lead);

  useEffect(() => {
    if (lead && lead.leadDetail) {
      let itrDetails = [];
      if (customerType === "customer") {
        itrDetails = lead.leadDetail?.customer.offer_itr_details;
      } else {
        itrDetails = coApplicantOfferDetails;
      }
      const obj = {};
      for (let i = 0; i < itrDetails.length; i++) {
        if (itrDetails[i].itr_year === "1") {
          obj["year_1"] = { ...itrDetails[i] };
        }
        if (itrDetails[i].itr_year === "2") {
          obj["year_2"] = { ...itrDetails[i] };
        }
      }
      setItrDetails((current) => ({
        ...current,
        ...obj,
      }));
      setITRDetails(null, null, obj);
    }
  }, [lead]);

  const handlePatternFormatChange = (data, field_name) => {


    if (data.value && field_name) {
      setTouch({
        ...isFormTouch,
        [field_name]: true,
      });
      const prevDetails = { ...itrDetails };
      if (prevDetails[activeTab]) {
        prevDetails[activeTab][field_name] = data.value;
      } else {
        prevDetails[activeTab] = {
          [field_name]: data.value,
        };
      }
      setItrDetails(prevDetails);
      setITRDetails(null, null, prevDetails);
    }
  };

  return (
    <>
      <div>
        <Tab.Container
          id="left-tabs-example"
          activeKey={activeTab}
          onSelect={(tab) => setActiveTab(tab)}
        >
          <Nav variant="pills" className="flex-column">
            <div className="tab-list">
              <Nav.Item>
                <Nav.Link eventKey="year_1">Year 1</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="year_2">Year 2</Nav.Link>
              </Nav.Item>
            </div>
          </Nav>
        </Tab.Container>

        <Formsy
          className="lead-form-filed"
          autoComplete="off"
          onValid={() => null}
          onInvalid={() => null}
        >
          <div className="row">
            {ITR_FIELDS.map((field, index) =>
              ["number-format"].includes(field.type) ? (
                <fieldset className={"form-filed col-md-6"} key={index}>
                  <FormsyInputField
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    value={
                      (itrDetails[activeTab] &&
                        itrDetails[activeTab][field.name]) ||
                      ""
                    }
                    placeholder=" "
                    thousandsGroupStyle="lakh"
                    thousandSeparator={","}
                    decimalSeparator={"."}
                    maxLength={field.maxLength}
                    label={`${field.label} \u2726`}
                    format={field.ApplyNumberformat ? field.format : ""}
                    validations={
                      isFormTouch[field.name] ? field.validations : null
                    }
                    validationError={""}
                    readOnly={field.readOnly}
                    required={field.required}
                    onChange={(data) =>
                      handlePatternFormatChange(data, field.name)
                    }
                  />
                </fieldset>
              ) : null
            )}
          </div>
        </Formsy>
      </div>
    </>
  );
};

export default BreITRSection;
