import React, { useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { convertCsvToJson, convertJsonToCsv, downloadFile } from "../../helpers/helpers";
import { BULK_PARTNER_LIST, BULK_PARTNER_SUBSCRIBED } from "../../services/scheme.gql";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../common/partnerExecuteGraphQLQuery";

const BULK_PARTNER_SUBSCRIBED_FORMAT = [
  { partner_id: 12345 },
  { partner_id: 11111 },
];

const BulkSubscriptionsPartnerList = (props) => {
  const [bulkPartnerList, setBulkPartnerList] = useState([]);
  const [partnerDetails, setPartnerDetails] = useState([]);
  const fileInputRef = useRef(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const subscribed_id = props.scheme_id;

  const handleFileChange = async (event) => {
    const { files } = event.target;
    if (files.length > 0) {
      try {
        setUploadedFile(files[0]); // Use files[0] correctly
        const jsonArray = await convertCsvToJson(files[0]); // Process the first file
        const extractedPartnerIds = jsonArray.map(item => +(Object.values(item)[0]));
        setUploadedFile(extractedPartnerIds); // Update state with extracted IDs
      } catch (error) {
        toast.error("Error processing the file. Please upload a valid CSV.");
      }
    } else {
      setUploadedFile(null);
    }
  };

  const fetchPartnerList = async () => {
    try {
      if (!uploadedFile || uploadedFile.length === 0) {
        toast.error("No file uploaded or file is empty.");
        return;
      }
      setUploadLoading(true);
      const response = await executeGraphQLQuery(BULK_PARTNER_LIST(uploadedFile.join(",")));
      if (response.data && response.data.partner_list) {
        const mappedIds = response.data.partner_list?.map(obj => obj.id);
        const misMatchedIds = uploadedFile.filter(partnerId => !mappedIds.includes(partnerId));
        const errMsg = misMatchedIds.length ? `* Found mismatched Ids: ${misMatchedIds.join(",")}` : "";
        setErrorMessage(errMsg);
        setPartnerDetails(response.data.partner_list);
        setBulkPartnerList(response.data.partner_list);
        toast.success("Partner list fetched successfully.");
      } else {
        toast.error("Failed to fetch partner list.");
      }
    } catch (error) {
      console.error(error.message);
      toast.error("Invalid file format");
    } finally {
      setUploadLoading(false);
    }
  };

  const handlePartnerSubscribe = async () => {
    const allPartnerIds = bulkPartnerList.map((row) => row.id);
    if (allPartnerIds.length === 0) {
      toast.error("No partners to subscribe.");
      return;
    }
    try {
      const variables = {
        data: {
          partner_ids: allPartnerIds,
          scheme_id: props.scheme_id,
        },
      };
      const response = await executeGraphQLMutation(BULK_PARTNER_SUBSCRIBED, variables);
      if (response && response.data) {
        toast.success("All partners subscribed successfully.");
        setUploadedFile(null);
        setPartnerDetails([]);
        if (fileInputRef.current) {
          fileInputRef.current.files = null;
          fileInputRef.current.value = "";
          props.modalClose();
        }
      } else {
        toast.error("Failed to subscribe all partners.");
      }
    } catch (error) {
      console.error("Error subscribing partners:", error);
      toast.error("An error occurred while subscribing partners.");
    }
  };

  const getDateAndTimeStr = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  };

  const handleSampleDownload = () => {
    const csvData = convertJsonToCsv(BULK_PARTNER_SUBSCRIBED_FORMAT);
    downloadFile(csvData, "text/csv", `bulk_partner-subscribed_format_${getDateAndTimeStr()}.csv`);
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label>Upload Bulk Partner Subscriptions</label>
            <input
              type="file"
              name="uploadMis"
              id="fileName_assignLead"
              accept=".csv"
              placeholder="Upload MIS"
              label="Upload CSV"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            <p
              className="text-muted"
              style={{ fontSize: "10px", marginTop: "5px", color: "red" }}
            >
              * Please upload a CSV file.
            </p>
          </div>
          <div className="col-sm-6" style={{ marginTop: "26px" }}>
            <button
              style={{ marginRight: "10px" }}
              className="add-schemebtn"
              onClick={handleSampleDownload}
            >
              Sample Download
            </button>
          </div>
          <div className="text-center col-sm-6" style={{ marginTop: 20 }}>
            {uploadedFile && (
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={fetchPartnerList}
                  disabled={uploadLoading}
                >
                  {uploadLoading ? "Uploading..." : "Upload"}
                </button>

                <p style={{ fontSize: "12px", color: "red", margin: "10px" }}>{errorMessage}</p>
              </div>
            )}
          </div>
        </div>

        <div className="clearfix">
          {partnerDetails.length ? (
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered tablefontsize">
                  <thead>
                    <tr>
                      <th>Partner Code</th>
                      <th>Partner Name</th>
                      <th>Company</th>
                      <th>Profession</th>
                      <th>City</th>
                    </tr>
                  </thead>
                  <tbody>
                    {partnerDetails.map((point, index) => (
                      <tr key={index}>
                        <td>{point.agent_code}</td>
                        <td>{point.first_name}</td>
                        <td>{point.organization}</td>
                        <td>{point.profession_name}</td>
                        <td>{point.cityname}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handlePartnerSubscribe}
                >
                  Partner Subscribed
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(BulkSubscriptionsPartnerList)
);
