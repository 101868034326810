import React, { useEffect, useState } from 'react';
import DocumentGallery from '../DocumentGallery';
import { useOutletContext,useNavigate } from 'react-router-dom';
import BreOfferSection from "../../leads/BreOffer/BreOfferSection";
import BreProperty from "../../leads/BreOffer/BreProperty";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";
import crypto from "../../../config/crypto";
import { Tab, Nav } from 'react-bootstrap';
import OfferFlag from './OfferFlag';
import { GET_BRE_MITIGANTS } from '../../../services/bre.gql';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner';

const RENTAL_INCOME_ID = 1;


const OfferDetails = (props) => {
  const client = useApolloClient();
  const [bankOffers, setBankOffers] = useState([]);
  const [showOfferTabs, setShowOfferTabs] = useState(false);
  const [fetchOfferByTabs, setFetchOfferByTabs] = useState(null);
  const [loadingOffer, setLoadingOffer] = useState(false);
  const [breData, setBreData] = useState({});
  const getContext = useOutletContext();
  const [recalculate, setRecalculate] = useState({});
  const [breMitigantsData, setBreMitigantsData] = useState({});
  const [isLeadDataUpdated, setLeadDataUpdated] = useState(false);

  const documentCategoryList = getContext.docList || [];
  const [activeTab, setActiveTab] = useState("basic_details");
  const [activeProductTab, setActiveProductTab] = useState('offer');
  let navigate = useNavigate(); 


  const { lead } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
  }));

  const getBreMitigants = async (payload) => {
    try {
      const variables = { breMitigantsInput: payload };
      const mutation = GET_BRE_MITIGANTS;
      const response = await executeGraphQLMutation(mutation, variables, client);
      if (response.data?.get_bre_mitigants) {
        setBreMitigantsData(response.data.get_bre_mitigants?.data || {});
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.lead_details) {

      const coApplicants = lead.leadDetail.co_applicant.filter((item) => item.ca_profession > 0);
      const rentalIncome = lead.leadDetail?.customer.additional_income?.filter(obj => obj.amount_type === "cash" && obj.income_type_id === RENTAL_INCOME_ID);

      setBreData((current) => ({
        ...current,
        profession: lead.leadDetail.lead_details.profession,
        first_name: lead.leadDetail.customer.first_name,
        last_name: lead.leadDetail.customer.last_name,
        phone_number: lead.leadDetail.customer.mobile,
        dob: dateFormat(lead.leadDetail.customer.dob, "dd mmm, yyyy"),
        loan_amount: lead.leadDetail.lead_details?.loan_amount,
        gross_monthly_salary: lead.leadDetail.lead_details?.gross_monthly_salary,
        monthly_salary: lead.leadDetail.lead_details?.monthly_salary, 
        work_experience: lead.leadDetail.lead_details?.work_experience,
        company_name: lead.leadDetail.lead_details?.company_name,
        tenure: lead.leadDetail.lead_details?.tenure,
        company_type: lead?.leadDetail?.lead_details?.company_type,
        loan_type: lead?.leadDetail?.loan_type,
        fulfillment_type: lead?.leadDetail?.fulfillment_type,
        industry: lead.leadDetail.customer?.industry,
        business_vintage: lead.leadDetail.customer?.business_vintage_years,
        co_applicant: coApplicants,
        is_co_applicant: lead.leadDetail.co_applicant.length ? "yes" : "no",
        is_property_identified: lead.leadDetail.lead_details?.is_property_identified,  
        expected_property_value: lead.leadDetail.lead_details?.expected_property_value,  
        property_value: lead.leadDetail.lead_details?.property_value,  
        property_type: lead.leadDetail.lead_details?.property_type,  
        property_sub_type: lead.leadDetail.lead_details?.property_sub_type,  
        property_agreement_value: lead.leadDetail.lead_details?.property_agreement_value,  
        property_city: lead.leadDetail.lead_details?.property_city,  
        agreement_type: lead.leadDetail.lead_details?.agreement_type,  
        builder_name_id: lead.leadDetail.lead_details?.builder_name_id,  
        project_name: lead.leadDetail.lead_details?.project_name,  
        check_oc_cc: lead.leadDetail.lead_details?.check_oc_cc,  
        ready_for_registration: lead.leadDetail.lead_details?.ready_for_registration,  
      }));

      const timer = setTimeout(() => {
        setLeadDataUpdated(true);
        const mitigantsPayload = {
          profession: +lead.leadDetail?.lead_details?.profession,
          work_experience: +lead.leadDetail?.lead_details?.work_experience,
          age: calculateAge(lead.leadDetail?.customer?.dob),
          business_vintage_years: +lead.leadDetail?.customer?.business_vintage_years,
          company_type: +lead?.leadDetail?.lead_details?.company_type,
          cibil_score: +lead.leadDetail?.lead_details?.cibil_score,
          loan_type: +lead?.leadDetail?.loan_type,
          occupancy_status: lead.leadDetail?.lead_details?.occupancy_status,
          co_applicants: coApplicants.filter(obj => obj?.ca_type === "financing").map(obj => obj?.relationship_with_customer),
          additional_income: rentalIncome.length > 0,
        };
        getBreMitigants(mitigantsPayload);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [lead]);

  const handleSetBankOffers = (offers) => {
    setBankOffers(offers);
  };

  const handleFetchOffer = (isPropertyConsidered) => {
    setFetchOfferByTabs(isPropertyConsidered);
  };

  const handleOfferTabsVisiblity = (visiblity) => {
    setShowOfferTabs(visiblity);
  };

  const handleOfferLoader = (status) => {
    setLoadingOffer(status);
  };

  const handleFetchOffer2 = (recalculatedObj) => {
    setRecalculate(recalculatedObj);
  };
  const movetoNext = ()=>{ 
    let lead_id = lead?.leadDetail?.id || ""; 
    navigate(`/lead-detail/customer-details/reference/${crypto.encode(lead_id)}`);  
  }

  return (
    <div className="image-form-outer">
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="5" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <h2>Offer Check</h2>
          </div>

          <div className="tab-data">
                          <Tab.Container
                              id="left-tabs-example"
                              activeKey={activeProductTab} 
                              onSelect={(tab) => setActiveProductTab(tab)}
                          >
                              <Nav variant="pills" className="flex-column nav nav-pills">
                                  <div className="tab-list">
                                      <Nav.Item>
                                          <Nav.Link eventKey="offer">Offer</Nav.Link>
                                      </Nav.Item>
                                  </div>
                                  <div className="tab-list">
                                      <Nav.Item>
                                          <Nav.Link eventKey="flag">Flags Raised<span>({breMitigantsData?.total_count || 0})</span></Nav.Link>
                                      </Nav.Item>
                                  </div>
                              </Nav>
                              <Nav >
                                  
                              </Nav>
                              
                              
                          </Tab.Container>
                      </div>

          {isLeadDataUpdated ? (
            <>
            {activeProductTab === "offer"  && 
          <>
          <BreProperty
            breData={breData}
            setBankOffersDetails={handleSetBankOffers}
            setOfferTabsVisiblity={handleOfferTabsVisiblity}
            fetchOfferByTab={fetchOfferByTabs}
            isLoadingOffer={handleOfferLoader}
            activeTab={activeTab}
            isGet={true}
            recalculate={recalculate}
            lead = {lead}
          />

          <BreOfferSection
            checkBankOffers={bankOffers}
            showTabs={showOfferTabs}
            fetchOffer={handleFetchOffer}
            isLoadingOffer={loadingOffer}
            recalculateOffer={handleFetchOffer2}
          />
           <div className="btn-save-remarks btn-save-next">
            <button className="btn-primary-outline btn-next" onClick={movetoNext}>
              Next
            </button>
          </div>
          </>
          
        }

        {activeProductTab === "flag" && 
        <>
          <OfferFlag breMitigantsData={breMitigantsData}/>
        </>
        }
            </>
          ) : (
            <div style={{ textAlign: "center" }}>
              <RotatingLines
                visible={true}
                height="80"
                width="80"
                strokeColor="grey"
                strokeWidth="4"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
              <p>Please wait while generating offer</p>
            </div>
          )}

         

        </div>
      </div>
    </div>
  );
}
export default OfferDetails;