import React, { useEffect, useRef, useState } from "react";
import FormsyInputField from "../../elements/FormsyInputField";
import Formsy from "formsy-react";
import { CREATE_MITIGANT_LOGS } from "../../../services/bre.gql";
import { toast } from "react-toastify";
import { executeGraphQLMutation } from "../../../common/executeGraphQLQuery";
import { useApolloClient } from "@apollo/client";
import { useSelector } from "react-redux";

const OfferFlag = ({ breMitigantsData }) => {
  const client = useApolloClient();
  const [additionalComment, setAdditionalComment] = useState({});
  const [listOfMitigantsChecked, setListOfMitigantsChecked] = useState([]);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);
  const isFirstRender = useRef(true);

  const { lead } = useSelector(({ lead }) => ({ lead }));
  const userInfo = JSON.parse(localStorage.getItem("user_information"));

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (
      listOfMitigantsChecked.length ||
      Object.keys(additionalComment).length
    ) {
      setIsSaveBtnDisabled(false);
    } else {
      setIsSaveBtnDisabled(true);
    }
  }, [listOfMitigantsChecked, additionalComment]);

  const handleCheckboxChange = (event, msg, isComment = false) => {
    if (event.target) {
      const { checked } = event.target;

      if (!isComment) {
        const exitsingList = [...listOfMitigantsChecked];
        if (checked) {
          exitsingList.push(msg);
        } else {
          const uncheckMsgIndex = exitsingList.findIndex((str) => str === msg);
          exitsingList.splice(uncheckMsgIndex, 1);
        }

        setListOfMitigantsChecked(exitsingList);
      } else {
        const existingComments = { ...additionalComment };
        if (checked) {
          existingComments[msg] = "";
        } else {
          delete existingComments[msg];
        }

        setAdditionalComment(existingComments);
      }
    }
  };

  const handleInputChange = (event, msgStr) => {
    if (event.target) {
      const { value } = event.target;
      setAdditionalComment((prev) => ({
        ...prev,
        [msgStr]: value,
      }));
    }
  };

  const handleSave = async () => {
    try {
      setIsSaveBtnDisabled(true);
      const lead_id = lead?.leadDetail?.id;
      const logData = [
        ...listOfMitigantsChecked.map((text) => ({
          lead_id,
          created_by: userInfo?.id,
          mitigants_text: text,
          comment: additionalComment[text] || "",
        })),
        ...Object.keys(additionalComment)
          .filter((text) => !listOfMitigantsChecked.includes(text))
          .map((text) => ({
            lead_id,
            created_by: userInfo?.id,
            mitigants_text: "Mention other reasons to mitigate this flag",
            comment: additionalComment[text],
          })),
      ];

      if (logData.length) {
        const breMitigantsLogsInput = {
          mitigantLogsList: logData,
        };

        const variables = { breMitigantsLogsInput };
        const mutation = CREATE_MITIGANT_LOGS;
        const response = await executeGraphQLMutation(
          mutation,
          variables,
          client
        );

        if (response.data?.create_mitigant_logs) {
          toast.success(response.data.create_mitigant_logs);
          setIsSaveBtnDisabled(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error("Internal server error");
          setIsSaveBtnDisabled(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } else {
        toast.warn("No data to save");
        setIsSaveBtnDisabled(false);
      }
    } catch (error) {
      toast.error(error.message);
      setIsSaveBtnDisabled(false);
    }
  };

  const listOfMitigantsFlags = Object.keys(breMitigantsData).filter(
    (key) => key !== "total_count"
  );

  return (
    <>
      {listOfMitigantsFlags?.map((heading, index) => (
        <div className="flagcardunderline" key={index}>
          <span className="flagraisedcard">{heading}</span>

          {breMitigantsData?.[heading] &&
            breMitigantsData?.[heading]?.map((msg, childIndex) => (
              <div
                className="flagcommon"
                key={`${index}_${childIndex}`}
                style={{ marginTop: 20 }}
              >
                <div className="flaginput">
                  <input
                    type="checkbox"
                    required
                    id={`mitigants_${index}_${childIndex}`}
                    name={`mitigants_${index}_${childIndex}`}
                    checked={
                      listOfMitigantsChecked.findIndex((str) => str === msg) >
                      -1
                    }
                    onChange={(e) => handleCheckboxChange(e, msg)}
                  />
                </div>
                <div>{msg}</div>
              </div>
            ))}

          <div className="flagcommon" key={index} style={{ marginTop: 20 }}>
            <div className="flaginput">
              <input
                type="checkbox"
                required
                id={`mitigants_${index}`}
                name={`mitigants_${index}`}
                checked={additionalComment.hasOwnProperty(heading)}
                onChange={(e) => handleCheckboxChange(e, heading, true)}
              />
            </div>
            <div style={{ marginBottom: "10px" }}>
              Mention other reasons to mitigate this flag
            </div>
          </div>

          {additionalComment.hasOwnProperty(heading) && (
            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => null}
              onInvalid={() => null}
            >
              <div className="row">
                <fieldset
                  className={"form-filed col-md-12"}
                  style={{ marginTop: 10 }}
                >
                  <FormsyInputField
                    id={`additionalComment_${index}`}
                    name={`additionalComment_${index}`}
                    type={"text"}
                    value={additionalComment[heading]}
                    placeholder=" "
                    onChange={(e) => handleInputChange(e, heading)}
                    label={""}
                  />
                </fieldset>
              </div>
            </Formsy>
          )}
        </div>
      ))}

      <button
        className="btn btn-primary"
        onClick={handleSave}
        disabled={isSaveBtnDisabled}
      >
        Save
      </button>
    </>
  );
};

export default OfferFlag;
