import React, { useEffect, useState } from "react";
import { MasterService } from "../../../../services";
import DateFormate from "dateformat";

const FosComponent = (props) => {
  const [delaer_list, set_dealer_list] = useState([]);

  return (
    <>
      <div className="table-responsive" width={100}>
        <table className="table table-bordered tablefontsize">
          <thead>
            <tr>
              <th>Lead Deatils</th>
              <th>Type</th>
              <th>Source/ Sub-Source</th>
              <th>Call Status/Sub-Status</th>
              <th>Latest Field Visit</th>
              <th>Transfer Details</th>
              <th>City</th>
              <th>Onboarding BM</th>
              {/* <th>Assinged BM</th> */}
              <th>Date</th>
              {/* <th>{('Action')}</th> */}
            </tr>
          </thead>
          <tbody>
            {props.fosDealerList.map((data) => {
              return (
                <tr>
                  <td>
                    {data.first_name
                      ? data.first_name
                      : "" + data.last_name
                      ? data.last_name
                      : ""}{" "}
                    <br />
                    {data.agent_code} <br />
                    {data.dealership_contact}
                  </td>
                  <td>dsfd</td>
                  <td>
                    {data.source} <br />
                    {data.subsource}
                  </td>
                  <td>
                    {data.status_name} <br />
                    {data.sub_status_name}
                  </td>
                  <div className="statusflexlist" style={{ marginTop: "40px" , display : "fl"}} >
                    {data.field_visit_date ? (
                      <>
                        <span className="supportliststatus">
                          {DateFormate(new Date(), "dS mmm yyyy") ===
                          DateFormate(data.field_visit_date, "dS mmm yyyy")
                            ? "Today"
                            : DateFormate(data.field_visit_date, "dS mmm yyyy")}
                        </span>
                        <span>
                          {DateFormate(data.field_visit_date, "hh: MM TT")}
                        </span>
                      </>
                    ) : (
                      <span>N/A</span>
                    )}
                   
                   
                  </div>
                  <div >
                      <div >
                        {data.field_visit_status == 0 ? (
                          new Date(data.field_visit_date) > new Date() ? (
                            <span
                            className="upcomingstatus"
                             
                            >
                              Upcoming
                            </span>
                          ) : (
                            
                            <span className="overduestatus" >
                              Overdue
                            </span>
                          )
                        ) : data.field_visit_status == 0 &&  (
                            <span className="completedstatus">
                            Completed
                          </span>
                        )}
                      </div>
                    </div>
                  <td>
                    {new Date(data.updated_date).toLocaleString()} <br />
                    From: {`  ${data.caller_name}  `} &nbsp; ID:{" "}
                    {data.yoddha_code}
                  </td>

                  <td>{data.field_visit_city_name ? data.field_visit_city_name : "N/A"}</td>
                  <td>
                    {data.onboarding_bm_name} <br />
                    {data.onboarding_bm_mobile}
                  </td>
                  {/* <td>{data.city_head_name || data.rm_name}</td> */}
                  <td>
                    {" "}
                    <span style={{ fontWeight: "bold" }}>
                      Created Date:
                    </span>{" "}
                    {new Date(data.created_date).toLocaleString()} <br />
                    <span style={{ fontWeight: "bold" }}>Updated Date</span>:
                    {new Date(data.updated_date).toLocaleString()}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FosComponent;
