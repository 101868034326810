import { LEADS_HISTORY_QUERY, GET_ALL_FOLLOWUP } from "../../../services/leads.gql";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import LeadStatusService from '../../../services/leadStatus';
import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { encode } from '../../../config/crypto'
import { useDispatch, useSelector } from "react-redux"
import DateFormate from "dateformat";
import CreateNewFollowup from "./CreateNewFollowup";
import Modal from "../../elements/Modal";
import { UPDATE_FOLLOWUP } from "../../../services/followup.gql";
import { getLoanDetail } from "../../../store/action/allAction";
import { toast } from "react-toastify";

const FollowUpActivityHistory = ({activityHistoryTab}) =>{

  const [updatedList , setUpdateList] = useState([])
  const [activityHistory, setActivityHistory] = useState([]);
  const [createFollowup, setCreateFollowup] = useState(false);
  const [expandedComments, setExpandedComments] = useState({});
  const client = useApolloClient();
   const dispatch = useDispatch();

   const toggleComment = (index) => {
    setExpandedComments((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const displayComment = (comment, index) => {
    const isExpanded = expandedComments[index];
    return isExpanded 
    ? comment 
    : comment 
        ? `${comment.substring(0, 80)}${comment.length > 80 ? "..." : ""}` 
        : "";  };


  const getActivityHistoryData = async (lead_id, user_id) => {
    try {
      const [allFollowsData, leadsHistoryData] = await Promise.all([
        executeGraphQLQuery(GET_ALL_FOLLOWUP(lead_id, user_id), client),
        executeGraphQLQuery(LEADS_HISTORY_QUERY(lead_id), client),
      ]);
      setActivityHistory(
         allFollowsData?.data?.get_followup,
      );
      setUpdateList(allFollowsData?.data?.get_followup)
      handleList(allFollowsData?.data?.get_followup);
    } catch (error) {
      console.error("Error fetching activity history data:", error);
    }
  };

  const OpenCreateFollowupNew =() =>{
    setCreateFollowup(true)
    document.body.classList.add("overflow-hidden");
  }
  const CloseCreateFollowupNew =() =>{
    setCreateFollowup(false)
    document.body.classList.remove("overflow-hidden");
  }

  const handlePopupSubmit = (data) => {
    setCreateFollowup(data)
    const lead_id = lead?.leadDetail?.id || "";
    const user_id = lead?.leadDetail?.customer?.customer_id;
    if (lead_id && user_id) {  
      getActivityHistoryData(lead_id, user_id);
    }
    document.body.classList.remove("overflow-hidden");
  }
  
  const handleList = (data) => {
    let filteredData = data;
    if (activityHistoryTab !== "all") {
      filteredData = data.filter((item) => item.followup_with === activityHistoryTab);
    }
    filteredData = filteredData.sort((a, b) => a.followup_status - b.followup_status);
    setActivityHistory(filteredData);
  };
  
  const { lead } = useSelector(({ lead }) => ({
      lead,
      leadDetail: lead?.leadDetail?.id,
      remarksHistory: lead.remarksHistory || [],
    })
  );

  useEffect(() => {
    const lead_id = lead?.leadDetail?.id || "";
    const user_id = lead?.leadDetail?.customer?.customer_id;
    if (lead_id && user_id) {  
      getActivityHistoryData(lead_id, user_id);
    }
  }, [lead?.leadDetail?.id, lead?.leadDetail?.customer?.customer_id,]);
  
  const toCamelCase = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const statusDone = (index ) => {
          const user_id = (JSON.parse(localStorage.getItem("user_information"))).id
          const data = activityHistory[index];
          let lead_id = lead?.leadDetail?.id || "";
        
          let reqBody = {
              lead_id: lead_id,
              comment : data.comment || '',
              id : data.id,
          };
          let mutation = UPDATE_FOLLOWUP(user_id);
          let variables = { UpdateFollowupInput: reqBody };
          executeGraphQLMutation(mutation, variables, client)
            .then((resp) => {
              let response = resp?.data?.MARK_AS_DONE || null;
              if (response && !response.success) {
                throw new Error(response.error || "Something went wrong");
              }
              const updatedActivityHistory = activityHistory.map((item, data) => {
                if (data === index) { 
                  return {
                    ...item,
                    followup_status: "2",
                    updated_user_details: {
                      name: (JSON.parse(localStorage.getItem("user_information"))).name,
                    },
                  };
                }
                return item;  
              });
              const sortedActivityHistory = updatedActivityHistory.sort((a, b) => {
                if (a.followup_status === '2' && b.followup_status !== '2') {
                  return 1;
                }
                if (b.followup_status === '2' && a.followup_status !== '2') {
                  return -1;
                }
                return 0;
              });
              setActivityHistory(sortedActivityHistory);
              toast.success("Success");
              dispatch(getLoanDetail(lead_id, client));
            })
            .catch((err) => {
              toast.error(err.message);
            });
      }

      const getFollowupStatus = (followupDate) => {
        const followupDateTime = new Date(followupDate);
        const currentDateTime = new Date();
        if (followupDateTime > currentDateTime) {
          return 'Upcoming';
        } else if (followupDateTime < currentDateTime) {
          return 'Overdue';
        }
    };


  return (
    
    <div className="historymenucard">
      <div className="menu-tab-left-panel-new-design">
        <div className="newfollowupcreate" onClick={OpenCreateFollowupNew}>+ Create</div>
        <ul className="overflowhide">
          {activityHistory?.map((item, index) => {
            let followupStatus = "";
            if (activityHistory.length && activityHistory[index]?.followup_status == 2) {
                followupStatus = "Done";
            } else if (activityHistory[index]?.followup_status == 0) {
                followupStatus = getFollowupStatus(activityHistory[index]?.followup_date);
            }

            return (
              <li key={index}>
                <div className="statusborder">
                  <div className="markasdoneflex">
                  <div className="leftfloatcard">
                  <div className="statusbtnflex">
                  <div className="historydetails">Follow up with - {toCamelCase(item?.followup_with || "")}</div>
                    {followupStatus == "Upcoming" ? (
                          <div className="historydetailsstatusbtnupcoming">Upcoming</div>
                      ) : followupStatus == "Overdue" ? (
                          <div className="historydetailsstatusbtnoverdue">Overdue</div>
                      ) : followupStatus == "Done" ? (
                          <div className="historydetailsstatusbtndone">Done</div>
                      ) : null}
                  </div>
                  
                  <div className="newstatusdetails">
                    <span><img src="https://static.ambak.com/saathi/1736155157395.png" width={10} height={12} /></span>
                    <span>Scheduled - </span>
                    <span>{item?.followup_date ? DateFormate(item?.followup_date,"dd mmm yyyy") : ""}, </span>
                    <span>{item?.followup_date ? DateFormate(item?.followup_date,"h:MM TT") : ""}</span>
                  </div>
                   <div className="newstatusdetails">
                   <img src="https://static.ambak.com/saathi/1736155506541.png" width={10} height={10} />
                      <span>{item?.created_user_details?.name} | </span>
                      <span>{item?.created_date ? DateFormate(item?.created_date,"dd mmm yyyy") : ""},</span>
                      <span>{item?.created_date ? DateFormate(item?.created_date,"h:MM TT") : ""}</span>
                    </div>
                    {/* <div className="newstatusdetails">
                    <div style={{width:"20px"}}><img src="https://static.ambak.com/saathi/1736155580968.png" width={10} height={10} /></div>
                    <span >{item?.comment}</span>
                    </div> */}
                    <div className="newstatusdetails">
                      <div style={{ width: "20px" }}>
                        <img
                          src="https://static.ambak.com/saathi/1736155580968.png"
                          width={10}
                          height={10}
                        />
                      </div>
                      <span>{displayComment(item?.comment, index)}</span>
                      {item?.comment?.length > 80 && !expandedComments[index] && (
                        <span
                          className="see-more"
                          onClick={() => toggleComment(index)}
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          See more
                        </span>
                      )}
                    </div>
                  </div>

                    <div>
                  
                    <div>
                  {item.followup_status !== '2' && (
                    <div className="markasdonebtn" onClick={() => { statusDone(index); }}>
                      Mark as Done
                    </div>
                  )}
                <>
                {item.followup_status === '2' && item.followup_with === 'customer' ? (
                    <>
                      <div className="followupdoneby">Done by</div>
                      <div className="follwowupbyname">{item?.updated_user_details?.name || "By System"}</div>
                      <div className="follwowupbyname">
                        <span>{item?.updated_date ? DateFormate(item?.updated_date, "dd mmm") : ""}, </span>
                        <span>{item?.updated_date ? DateFormate(item?.updated_date, "h:MM TT") : ""}</span>
                      </div>
                    </>
                  ) : null }
                  </>
             
                </div>
                    
                         
                    </div>
                    </div>
                </div>
            </li>
            );
          })}
        </ul>

      </div>

      <div className="view-timeline-popup image-timeline-popup">
              <Modal show={createFollowup} handleClose={CloseCreateFollowupNew} width={550} removeHeight={true} >
                <CreateNewFollowup handlePopupSubmit ={handlePopupSubmit}/>
              </Modal>
          </div>
    </div>
  );
}

export default FollowUpActivityHistory;