import { useApolloClient } from '@apollo/client';
import Formsy from 'formsy-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../elements/Loader';
import { executeGraphQLMutation } from './../../common/executeGraphQLQuery';
import { SAVE_LOAN_DETAILS } from './../../services/customer.gql';
import { getLoanDetail } from './../../store/action/allAction';
import FormsyInputField from './../elements/FormsyInputField';
import FormsySelect from './../elements/FormsySelect';
import DocumentGallery from './DocumentGallery';
import FormFields from "./formFields/leadFormField.json";
import { SOFT_DELETE_SUBVENTION } from '../../services/leads.gql';
import ConfirmPopUp from '../elements/ConfirmPopUp';

const SUBVENTION_PF_FORM = FormFields["SUBVENSION_PF"];

const DisbursedLead = (props) => {
  const [subvension, setSubvensionData] = useState({});
  const [subventionTable, setSubventionTable] = useState([]);
  const [showButton,setShowButton] = useState(false)
  const [allowSubmit, setAllowSubmit] = useState(false);
  const client = useApolloClient();
  const dispatch = useDispatch();
  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  let [loading, setIsLoading] = useState(false);
  const [showConfirmBox, setShowConfirmation] = useState(false)
  const [currentSubId, setCurrentSubId] = useState(null)
  const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({ masterdata, lead }));

  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.lead_details) {
      let record = {
        subvension_type_id: lead?.leadDetail?.lead_details?.subvension_type_id || 1,
        subvension_amount: lead?.leadDetail?.lead_details?.subvension_amount,
        subvention_applicable: lead?.leadDetail?.lead_details?.subvention_applicable || 0,
        subvention_remarks: lead?.leadDetail?.lead_details?.subvention_remarks,
      };
      setSubventionTable(lead?.leadDetail?.lead_details?.subvention_info)
      if(lead?.leadDetail?.lead_details?.subvention_info.length){
        setShowButton(true)
      }else{
        setShowButton(false)
      }
      setSubvensionData(record);
    }
  }, [lead]);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    return options;
  }, [masterdata]);



  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setSubvensionData(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleInputChange = event => {
    if (event.target.id) {
      setSubvensionData(currentValue => ({
        ...currentValue,
        [event.target.id]: event.target.value
      }))
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setSubvensionData(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleSoftDelete = async() => {
      // setIsLoading(true);
      try {
        const response = await executeGraphQLMutation(SOFT_DELETE_SUBVENTION(),{id:currentSubId},client);
        toast.success(response.data.soft_delete_subvention);
        const updatedTable = subventionTable.filter(item=>item.id!==currentSubId)
        setSubventionTable(updatedTable)
        setShowConfirmation(false);
        // setIsLoading(false);
      } catch (error) {
        // setIsLoading(false);
        toast.error(error.message);
        console.log("Error 🔻 ", error?.message);
      }
    }  

  const handleSubmit = () => {
    setIsLoading(true)
    let lead_id = lead?.leadDetail?.id || "";
    let sumAmount = subventionTable.reduce((sum, item) => sum + (+item.amount || 0), 0)
    sumAmount+= +subvension?.amount
    if(+lead.leadDetail.lead_details.approved_amount<sumAmount){
      setIsLoading(false)
      return toast.error("Amount should be less than sanction amount")
    }
    const subvention_info = [];
    if (+subvension?.subvension_header) {
      subvention_info.push({
        header_id:+subvension?.subvension_header,
        amount:+subvension?.amount,
        percentage:+subvension.subvention_percentage
      });
    }
    const variable = {
      api_called_by: 'web',
      LeadAdditionalInput: {
        lead_id: lead_id,
        lead_details: {
          subvension_type_id: +subvension?.subvension_type_id,
          subvension_amount: +subvension?.subvension_amount || 0,
          subvention_applicable:+subvension?.subvention_applicable,
          subvention_remarks:subvension?.subvention_remarks,
          subvention_info,
        }
      }
    }
    
    if(subvension.subvention_applicable==0){
      delete variable.LeadAdditionalInput.lead_details.subvension_amount
    }
    let mutation = SAVE_LOAN_DETAILS(authUser?.id),
      variables = variable;

    executeGraphQLMutation(mutation, variables, client).then(async(resp) => {
      let isError = resp?.data?.save_additional_info[0].lead_details.error_message ||null;
      if (isError) {
        setIsLoading(false)
        return toast.error(isError) 
      }

      setSubventionTable(resp?.data?.save_additional_info[0].lead_details.subvention_info)
      dispatch(getLoanDetail(lead_id, client))
      setShowButton(true)
      setIsLoading(false)
      toast.success("Success");
      
    })
    .catch(err => {
        setIsLoading(false)
        toast.error(err.message);
      })
  }




  const isSubmitDisabled = useMemo(() => {
    return (lead?.leadDetail?.status_id != 4) ? true : false;
  }, [allowSubmit, getContext]);

  return (
    <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
      {loading ? <Loader /> : null}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="65" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <h2>Subvention & PF</h2>
          </div>

          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">
              {SUBVENTION_PF_FORM.map((field) =>
                ["text", "number-format"].includes(field.type) ? (
                  ["subvension_header","amount","subvention_percentage"].includes(field.name) && showButton ?null:
                  <fieldset className={`form-filed col-md-${field?.colLength||"6"}`}>
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={((field.name=="subvension_amount" || field.name=="subvention_remarks") && subvension["subvention_applicable"]==0)?"hidden":field.type}
                      value={subvension[field.name]}
                      placeholder=" "
                      thousandsGroupStyle="lakh"
                      thousandSeparator={','}
                      decimalSeparator={'.'}
                      label={field.label}
                      onChange={
                        field.type === "number-format"
                          ? (data) => handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={field.validations}
                      validationError={
                        subvension[field.name] ? field.validationError : ""
                      }
                      readOnly={field.readOnly || (!+subvension.subvension_type_id && field.name === 'subvension_amount')}
                      required={field.id === "subvension_amount" && subvension["subvention_applicable"] == 0 ? false : field.id === "amount" && subvension["subvension_type_id"] == "1" ? true : field.id === "subvention_percentage" && subvension["subvension_type_id"] == "2" ? true : field.type === "text" && field.id === "subvention_remarks" && subvension["subvention_applicable"] == 0 ? false : field.required}
                    />
                  </fieldset>
                ):field.name === "subvention_table" ? (
                  subventionTable.length ? 
                    <div className='clearfix'>
                    <div className="card">
                      <div className="table-responsive" style={{overflowX:"scroll"}}>
                        <table className="table table-bordered tablefontsize">
                          <thead>
                            <tr>
                              <th>Header</th>
                              <th>Amount</th>
                              <th>Percentage</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {subventionTable.map((subv, index) => (
                              <>
                                <tr key={index}>
                                  <td>{ALL_OPTIONS[field.optionsKey].find(item=>item.value==subv.header_id)?.label}</td>
                                  <td>{subv.amount}</td>
                                  <td>{subv.percentage}</td>
                                  <td>
                                    <div className="btn-group btn-group-small mrg-r10" style={{width:"fit-content"}}>
                                      <i style={{ fontSize: 21 }} className="ic-delete" onClick={()=>{
                                        setShowConfirmation(true)
                                        setCurrentSubId(subv.id)
                                        }}></i>
                                                </div>
                                                </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      </div>
                    </div>:null
                ): field.type === "textarea" ? (
                  <fieldset className="col-md-12">
                    <div className="material">
                      <textarea
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={Object.keys(subvension).length && subvension[field.name]}
                        placeholder=" "
                        onChange={handleInputChange}
                        className="form-input"
                      >
                      </textarea>
                      <label
                        data-label={field.label}
                        className="form-label"
                      >
                      </label>
                    </div>
                  </fieldset>
                ): field.type === "btn" ? (
                  showButton?<fieldset className="col-md-12">
                    <div className='updatedbtn' style={{textAlign:"end"}} onClick={()=>setShowButton(false)}>{field.label}</div>
                  </fieldset>:null
                ): (field.type === "radio" && field.name === "subvension_type_id" ) ? (
                  <fieldset className="form-filed col-md-12 ">
                    <div className='followupradio'>
                      <div className='followupcall'>
                    <div style={{ marginRight: 50,fontWeight:500 }}>Type :</div>
                        <input type="radio" required id="subvension_type_id" name="subvension_type_id" value="1" 
                        checked={(Object.keys(subvension).length && subvension[field.name]=="1" )? true : false}
                        onChange={handleInputChange} />
                        <label htmlFor="flat">Flat</label>
                      </div>
                        <div className='followupcall'>
                          <input type="radio" required id="subvension_type_id" name="subvension_type_id" value="2" 
                          checked={(Object.keys(subvension).length && subvension[field.name]=="2" )? true : false}
                           onChange={handleInputChange} />
                          <label htmlFor="percentage">Percentage</label>
                        </div>
                    </div>
                  </fieldset>
                ):(field.type === "radio" && field.name === "subvention_applicable" ) ? (
                  <fieldset className="form-filed col-md-12 ">
                    <div style={{ marginRight: 50,fontWeight:500 }}>Do you want to subvent a part of PF ?</div>
                    <div className='followupradio'>
                      <div className='followupcall'>
                        <input type="radio" required id="subvention_applicable" name="subvention_applicable" value="1" 
                        checked={(Object.keys(subvension).length && subvension[field.name]==1 )? true : false}
                         onChange={handleInputChange} />
                        <label htmlFor="yes">Yes</label>
                      </div>
                        <div className='followupcall'>
                          <input type="radio" required id="subvention_applicable" name="subvention_applicable" value="0" 
                          checked={(Object.keys(subvension).length && subvension[field.name]==0 )? true : false}
                           onChange={handleInputChange} />
                          <label htmlFor="no">No</label>
                        </div>
                    </div>
                  </fieldset>
                ): field.type === "dropdown" ? (
                    field.name=="subvension_header" && showButton?null:<fieldset className={`single-select col-md-${field?.colLength||"6"}`}>
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                          options: ALL_OPTIONS && field.name=="subvension_header"?
                          ALL_OPTIONS[field.optionsKey].filter(({ id }) => {
                            const valueInSubvention = subventionTable.filter(item=>[1,2].includes(+item.header_id)).map(item=>item.header_id);
                              if (valueInSubvention.length > 0 && [1, 2].includes(+id)) {
                                return !valueInSubvention.includes(+id);
                              }
                              return true;
                          })
                          : ALL_OPTIONS[field.optionsKey] ,
                          placeholder: field.label,
                          className: "react-select",
                          classNamePrefix: "react-select",
                          value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ id }) => id == subvension[field.name]),
                          isDisabled: field.readOnly
                        }}
                        required={field.required}
                        value={field.name}
                        onChange={(data) => handleSelectChange(data, field.name)}
                      />
                    </fieldset>
                  ) : null
              )
              }
            </div>
            <div className="btn-save-remarks">
              <button type="submit" className="btn-primary" disabled={isSubmitDisabled || !allowSubmit}>Update</button>
            </div>
          </Formsy>
        </div>
      </div>
      {showConfirmBox && (
                <ConfirmPopUp
                    confirmNo={()=>setShowConfirmation(false)}
                    confirmYes={()=>handleSoftDelete()}
                    title="Confirmation "
                    message="Are you sure to delete ?"
                />
            )}
    </div>
  );
}

export default DisbursedLead;