import { useApolloClient } from "@apollo/client";
import Q from "q";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import { toast } from "react-toastify";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../common/executeGraphQLQuery";
import { WS_URL } from "../../config/constants";
import GeneralService from "../../services/generalService";
import { CHAT_SAVE } from "../../services/leadCreate.gql";
import {
  CHAT_LIST,
  CHAT_TEMPLATE_LIST,
  PARTNER_CHAT_LIST,
  PARTNER_CHAT_TEMPLATE_LIST,
  UPDATE_CHAT_READ_STATUS,
} from "../../services/leads.gql";
import dateFormat from "dateformat";
import docImage from "../../webroot/images/saveicon.svg";
import pluscrcle from "../../webroot/images/pluscircle.svg";
import sendarrow from "../../webroot/images/sendrightarrow.svg";
import Select from "react-select";
import Modal from "../elements/Modal";

const PartnerWhatsAppChat = ({ lead }) => {
  const userInformation = JSON.parse(localStorage.getItem("user_information"));
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [initiate, setInitiate] = useState(false);

  let refresh = 0;
  const [inputField, setField] = useState({
    chat_type: "TEXT",
    mode_type: 1,
    message: "",
    files: "",
  });
  const [list, setList] = useState([]);
  const [chatList, setChatList] = useState([]);
  const client = useApolloClient();
  const textareaRef = useRef(null);
  const intervalRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [template, setTemplate] = useState(null);

  const ALL_OPTIONS = {
    composeOptions: list.map((item) => ({
      value: item.template,
      label: item.name,
      bodyparameters: item.bodyparameters,
      template_id: item.templateId,
      msg_type: item.msg_type,
      media_url: item.media_url,
      file_name: item.file_name
    })),
  };

  const getPartnerTemplateList = async (
    partner_id,
    user_mobile,
    user_id,
    is_whatsapp,
    is_email
  ) => {
    try {
      const response = await executeGraphQLQuery(
        PARTNER_CHAT_TEMPLATE_LIST(
          partner_id,
          user_mobile,
          user_id,
          is_whatsapp,
          is_email
        ),
        client
      );
      setList(response?.data?.partner_chat_template_list || []);
      setTemplate(response?.data?.partner_chat_template_list || []);
    } catch (error) {
      console.error(`🔻ERROR: ${error.message}`);
    }
  };

  const isMoreThan24Hours = (date) => {
    const currentTime = new Date();
    const timeDifference = (currentTime - date) / (1000 * 60 * 60);
    return timeDifference >= 24;
  };

  const getMessageList = async (mobile) => {
    try {
      const result = await executeGraphQLQuery(
        PARTNER_CHAT_LIST(String(mobile)),
        client
      );
      let replyResult = result?.data?.partner_chat_list.filter(
        (ob) => ob.mode_type == 2
      );

      let replyResultForInitiateChat = result?.data?.partner_chat_list.filter(
        (ob) => ob.mode_type == 1
      );

      let lastChatTime;
      if (replyResultForInitiateChat?.length) {
        lastChatTime = new Date(
          replyResultForInitiateChat.at(-1)?.created_date
        );
      }
      if (
        !replyResult.length ||
        (lastChatTime && isMoreThan24Hours(lastChatTime))
      ) {
        setInitiate(true);
      } else {
        setInitiate(false);
      }
      let curCount = result?.data?.partner_chat_list?.length;
      if (!(refresh == curCount)) {
        setTimeout(() => scrollToBottom(), 200);
      }
      refresh = curCount;
      setChatList(result?.data?.partner_chat_list);
    } catch (error) {
      console.error(`🔻 ERROR: ${error.message}`);
    }
  };

  useEffect(() => {
    getPartnerTemplateList(
      lead?.id,
      userInformation.mobile,
      userInformation.id,
      "1",
      "0"
    );
    getMessageList(lead?.dealership_contact);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chatList]);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setField({
      ...inputField,
      message: value,
    });
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "28px";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }, 0);
  };

  const handleTemplateChange = (message) => {
    setField((prev) => ({ ...prev, message }));
    // add a slight delay
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "28px";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }, 0);
  };

//   const openTemplateModal = () => {
//       document.body.classList.add("overflow-hidden");
//     setModalOpen(true);
// };

const closeTemplateModal = () => {
    setModalOpen(false);
    document.body.classList.remove("overflow-hidden");

};

const sendMessage = (reqParam = null, initiate = false,bodyparameters,template_id,message,msg_type,media_url,file_name) => {
  if (inputField.message.trim() !== "" || reqParam || initiate) {
    let chatField = {
      ...inputField,
      lead_id: lead?.id,
      user_id: lead?.user_id,
      mobile: lead?.dealership_contact,
      // mobile: "7261061334",
      isPartner:1

    };
  
    if(msg_type==="DOCUMENT"){
        chatField.chat_type="DOCUMENT"
        chatField.media_url=media_url
        chatField.file_name=file_name
    }
    if (initiate) {
      chatField.initiate = 1;
      chatField.templateId = template_id;
      chatField.bodyparameters = bodyparameters ;
      chatField.message = message
    }
    if (reqParam && reqParam?.chat_type) {
      chatField = { ...chatField, ...reqParam };
    }

    let variables = {
      api_called_by: "partner",
      chatInput: chatField,
      call_by: "whatsapp_page",
      tempelate_name: "sangam",
    };

    let mutation = CHAT_SAVE;
    executeGraphQLMutation(mutation, variables, client)
      .then((result) => {
        getMessageList(lead?.dealership_contact);
        // getMessageList("7261061334");
      })
      .catch((error) => {console.error(error)}) ;
    setField({
      ...inputField,
      message: "",
    });
    if(!initiate){
      textareaRef.current.style.height = '28px';

    }
  }
};

  const handleChangeImage = (event) => {
    let allowedExtensions = /\.(jpg|jpeg|png|pdf|doc|docx|csv|json)$/i;
    
    let files = Array.from(event.target.files),
      awsRes = [],
      leadDocArr = [];
      let validFiles = files.filter((file) => allowedExtensions.test(file.name));
      if (validFiles.length === 0) {
        toast.error("Invalid file format. Allowed formats are jpg, jpeg, png, pdf, doc, docx, csv, json.");
        return; 
      }
    if (files && files.length) {
      var formData = new FormData();
      formData.append("dealer_id", lead.id);
      formData.append("upload_type", "dealer_docs");
      validFiles.forEach((file) => formData.append("images", file));

      awsRes.push(
        GeneralService.uploadFilesToS3(formData, {
          "Content-Type": "multipart/form-data",
        })
      );
      Q.allSettled(awsRes).then((uploadOutput) => {
        if(uploadOutput[0]?.value?.data?.message==="Success"){
          toast.success(uploadOutput[0]?.value?.data?.message);
          uploadOutput.forEach((res, index) => {
          let response = res.value || [];
          if (res.state === "fulfilled") {
            let docPath = response?.data?.data?.length
              ? response?.data?.data?.[0]?.file_url
              : "";
            let chat_type = "DOCUMENT";
            if (
              docPath.endsWith(".jpg") ||
              docPath.endsWith(".jpeg") ||
              docPath.endsWith(".png") ||
              docPath.endsWith(".gif")
            ) {
              chat_type = "IMAGE";
            }
            let params = {
              files: docPath,
              chat_type: chat_type,
            };
            sendMessage(params);
          }
        });
        }else{
          toast.error(uploadOutput[0]?.value?.data?.message);
        }
        
      });
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  // WebSocket Code Initialize
  useEffect(() => {
    if (lead?.id) {
      const identifierType = "activeMessage";
      const connectionId = Math.floor(Date.now() / 1000);
      const socketData = {
        transports: ["websocket"],
        query: {
          type: identifierType,
          identifier: lead?.id,
          connectionId,
        },
        reconnectionAttempts: 10,
        reconnectionDelay: 60000,
        randomizationFactor: 5,
      };
      const newSocket = io(`${WS_URL}/socket`, socketData);

      newSocket.on(`${identifierType}-${lead?.id}_${connectionId}`, (msg) => {
        if (msg) {
          const msgFrmt = {
            created_date: msg.message.created_date,
            message: msg.message.message,
            chat_type: msg.message.chat_type,
            files: msg?.message?.files,
            mode_type: msg.message.mode_type,
          };
          setChatList((prevMessages) => [...prevMessages, msgFrmt]);
          scrollToBottom();
          setInitiate(false);
          updateReadStatus(msg.identifier.msgId, msg.identifier.userId);
        }
      });

      return () => {
        newSocket.disconnect();
      };
    }
  }, [lead?.id]);
  // WebSocket Code Ends

  const updateReadStatus = (msgId, userId) => {
    executeGraphQLMutation(
      UPDATE_CHAT_READ_STATUS,
      { chatId: msgId, userId },
      client
    )
      // .then(result => {
      //   console.log("\n\n updateReadStatus >> executeGraphQLQuery => result -> ", result)
      // })
      .catch((error) => {
        console.error(
          "\n\n updateReadStatus >> executeGraphQLQuery => error -> ",
          error
        );
      });
  };

  const handleSelectTemplate = (template) => {
   sendMessage(null, 1, template?.bodyparameters, template?.template_id,template?.value,template?.msg_type,template?.media_url,template?.file_name);
    closeTemplateModal();
};


  return (
    <>
      <div className="wahtsapp_main_container">
        <div className="Whatspp_header">
          <div className="whatsappInbox">WhatsApp Chat</div>
          {!initiate && (
              <div className="select_tempWhatsapp">
              <Select
                name="compose_option"
                id="compose_option"
                styles={{
                  control: (base) => ({
                    ...base,
                    borderRadius: "20px",
                  }),
                }}
                options={ALL_OPTIONS["composeOptions"]}
                placeholder="Select Templates"
                className="react-select"
                classNamePrefix="react-select"
                required={false}
                onChange={(message) => {
                  handleTemplateChange(message.value);
                }}
              />
            </div>
          )}    
        </div>

        <div ref={chatContainerRef} className="mess_main_cont">
          <div className="timestamp">
            {chatList && chatList.length
              ? dateFormat(chatList[0].created_date, "dd mmm yyyy")
              : null}
          </div>

          {chatList && chatList.length
            ? chatList.map((item, key) => {
                let messageElement = <div>{item.message}</div>;
                if (item.chat_type == "IMAGE") {
                  messageElement = (
                    <div>
                      {item.files && (
                        <>
                          <a
                            href={item.files}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={item.files}
                              width="100px"
                              height="100px"
                              alt="chat-img"
                            />
                          </a>
                          <br />
                        </>
                      )}
                      <span>{item.message}</span>
                    </div>
                  );
                } else if (item.chat_type == "DOCUMENT" && item.files) {
                  messageElement = (
                    <div>
                      {item.files && (
                        <>
                          <a
                            href={item.files}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={docImage}
                              width="100px"
                              height="50px"
                              alt="chat-doc"
                            />
                          </a>
                        </>
                      )}
                    </div>
                  );
                }
                return (
                  <div
                    className={`${
                      item.mode_type == "2" ? "recievd_messs" : "send_messs"
                    }`}
                  >
                    {messageElement}
                    {/* <div className="chattime">
                          {dateFormat(
                            item.created_date,
                            "dd mmm yyyy, h:MM TT"
                          )}
                        </div> */}
                  </div>
                );
              })
            : null}
        </div>

        {initiate ? (
          <div className="foot_cont">
            <center>
              <button
                className="btn-primary"
                type="button"
                onClick={() => setModalOpen(true)}
              >
                Initiate Chat
              </button>
            </center>
          </div>
        ) : (
          <div className="foot_cont">
            <div className="send_input_cont_partner">
              <div className="send_input">
                <textarea
                  ref={textareaRef}
                  onChange={handleChange}
                  value={inputField.message || ""}
                ></textarea>
              </div>

              <div className="send_arrow" onClick={() => sendMessage()}>
                <img src={sendarrow} alt="icon" width={20} height={20} />
              </div>
            </div>
            <div className="plus_cont">
              <img
                src={pluscrcle}
                alt="icon"
                width={46}
                height={46}
                onClick={handleIconClick}
              />
              <input
                type="file"
                className="input_file"
                onChange={handleChangeImage}
              />
            </div>
          </div>
        )}

        {isModalOpen && (
          <div className="view-timeline-popup fguifui">
          <Modal
          show={template}
          handleClose={closeTemplateModal}
          modalHeight="350px"           
      >
<div className="headingforSelect">Select a Template</div>
 <div className="select_tempWhatsapp">
            <Select
              name="compose_option"
              id="compose_option"
              styles={{
                control: (base) => ({
                  ...base,
                  borderRadius: "20px",
                }),
              }}
              options={ALL_OPTIONS["composeOptions"]}
              placeholder="Select Templates"
              className="react-select"
              classNamePrefix="react-select"
              required={false}
              onChange={(message) => {
                handleSelectTemplate(message);
              }}
            />
          </div>      
          </Modal>
          </div>
        )}
      </div>
    </>
  );
};

export default PartnerWhatsAppChat;
