import React, { useEffect, useMemo, useState } from "react";
import Formsy from "formsy-react";
import { useDispatch, useSelector } from "react-redux";
import DocumentGallery from "./DocumentGallery";
import FormFields from "./formFields/leadFormField.json";
import FormsyInputField from "../elements/FormsyInputField";
import { useOutletContext } from "react-router-dom";
import LeadRemarkHistory from "../lead-list/LeadRemarkHistory";
import Modal from "../elements/Modal";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { useTranslation } from "react-i18next";
import { SAVE_FOLLOWUP } from "../../services/followup.gql";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE,DISBURSE_REJECTION_ID } from "../../config/constants";
import FormsyDatePicker from "../elements/FormsyDatePicker";
import FormsySelect from "../elements/FormsySelect";
import { GET_FOLLOWUP, LEADS_HISTORY_QUERY } from "../../services/leads.gql";
import { GET_ALL_FOLLOWUP } from "../../services/leads.gql";
import { getLoanDetail } from './../../store/action/allAction';
import FollowupHistory from "../lead-list/followupHistory";
import DateFormate from "dateformat";
import FollowUpHistoryForm from "./bank-details/FollowUpHistory";
import CustomCardSlider from "./components/CustomCardSlider";
import moment from "moment";
import FollowUpService from '../../services/followup';

const now = new Date();
const currhour = Number(now.getHours());
const currmin = now.getMinutes();
const FORM_FIELDS = FormFields["FOLLOW_UPS"];

const FOLLOW_UPS_TYPE = [
  { id: "1", label: "Call", value: "call" },
  { id: "2", label: "Doc Pickup", value: "doc_pickup" }
]

const   FollowUpsForm = (props) => {
  const user_id = props.user_id || 0;
  const { t } = useTranslation('language');
  const [followupStatus, setFollowupStatus] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [followupTimeLine, setFollowupTimeLine] = useState(false);
  const [createFollowup, setCreateFollowup] = useState(false);
  const [followupShow, setfollowupShow] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [showFollowup, setShowFollowup] = useState({})
  const [followUphistoryList, setFollowUphistoryList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [followupHistory, setFolloupHisory] = useState({});
  const [ isReschedule, setIsReschedule ] = useState(false);
  let [maxTime, setMaxTime] = useState(now.setHours(23, 59, 59));
  let [minTime, setMinTime] = useState(now.setHours(currhour, currmin, 0));
  const [statusDone, setStatusDone] = useState(true)

  const { masterdata, lead, remarksHistory, leadDetail } = useSelector(
    ({ masterdata, lead }) => ({
      masterdata,
      lead,
      leadDetail: lead?.leadDetail?.id,
      remarksHistory: lead.remarksHistory || [],
    })
  );
  const client = useApolloClient();
  const dispatch = useDispatch();
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
 

  let accessCondition = !IS_USER_ACCESS_PAGE(
    "followupStatus",
    "followup-status"
  )?.is_edit_access;

  if (accessCondition !== 1) {
    accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
  }

  // const getContext = useOutletContext();
  // const documentCategoryList = getContext.docList || [];

  useEffect(() => {
    let lead_id = lead?.leadDetail?.id || "";
    let user_id = lead?.leadDetail?.customer?.customer_id;

    if (lead_id && user_id) {  // Only call if we have valid IDs
      getFollouoData(lead_id, user_id);
    }

    // setFollowupStatus();
    // getTimeLineListData(lead_id, user_id)
    // setFollowupStatus(followupdata);
  }, [lead?.leadDetail?.id, lead?.leadDetail?.customer?.customer_id]);


  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    options["followup_with"] = FollowUpService.FOLLOW_UPS_WITH();
    options["follow_type"] = FOLLOW_UPS_TYPE;
    return options;
  }, [masterdata]);

  const handleInputChange = (event) => {
    if (event.target.id) {
      setFollowupStatus((currentValue) => ({
        ...currentValue,
        [event.target.id]: event.target.value,
      }));
    }
  };
  const handleDateChange = (date, field_name) => {
    if (DateFormate(now, "yyyy-mm-dd") != DateFormate(date, "yyyy-mm-dd")) {
      setMinTime(now.setHours(0, 0, 0))
    } else {
      setMinTime(now.setHours(currhour, currmin, 0))
    }
    if (date && field_name) {
      setFollowupStatus((currentValue) => ({
        ...currentValue,
        [field_name]: date,
      }));
    }
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setFollowupStatus(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const getFollouoData = async () => {
    let lead_id = lead?.leadDetail?.id || "";
    let user_id = lead?.leadDetail?.customer.customer_id || "";
    try {
      const result = await executeGraphQLQuery(GET_ALL_FOLLOWUP(lead_id, user_id), client);
      if (result?.data?.get_followup && result.data.get_followup.length > 0) {
        // const lastFollowup = result?.data?.get_followup?.length && result?.data?.get_followup[0];
        const lastFollowup = result?.data?.get_followup?.length && result?.data?.get_followup;
       
        // lastFollowup.followup_date =  DateFormate(lastFollowup.followup_date, "yyyy-mm-dd hh:MM TT")
        // if (DateFormate(now, "yyyy-mm-dd") != DateFormate(lastFollowup?.followup_date, "yyyy-mm-dd")) {
        //   setMinTime(now.setHours(0, 0, 0))
        // } else {
        //   setMinTime(now.setHours(currhour, currmin, 0))
        // }
        setHistoryList(result?.data?.get_followup);
        setShowFollowup(lastFollowup);
        // if(lead?.leadDetail?.followup_status === '1' && lead?.leadDetail?.status_id === 1) setFollowupStatus(lastFollowup);

      } 
    } catch (error) {
      console.error('Query error:', error);
    }
  }

  const getTimeLineListData = async (lead_id, user_id) => {
    executeGraphQLQuery(GET_ALL_FOLLOWUP(lead_id, user_id), client).then((result) => {
      setHistoryList(result?.data?.get_followup)
    }).catch((error) => {
      console.error('Query error:', error);
    });
  }

  const handleSubmit = () => {
    let lead_id = lead?.leadDetail?.id || "";
    let reqBody = {
        lead_id: lead_id,
        id: (isReschedule) ? followupStatus.id : 0,
        followup_with: followupStatus.followup_with || "",
        followup_type: followupStatus.followup_type || "call",
        followup_date: followupStatus.followup_date || "",
        comment: followupStatus.comment || ""
    };
    
    let mutation = SAVE_FOLLOWUP(authUser?.id),
      variables = { CreateFollowupInput: reqBody };

    executeGraphQLMutation(mutation, variables, client)
      .then((resp) => {
        let response = resp?.data?.SAVE_FOLLOWUP || null;
        setFollowupStatus({comment: "", followup_date: null});
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        CloseCreateFollowupNew()
        let folloupdata = GET_FOLLOWUP(lead_id, client);
        dispatch(getLoanDetail(lead_id, client));
        setFolloupHisory(folloupdata);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  // All the conditions can be combined here for button enable/disable
  const isSubmitDisabled = useMemo(() => {
    return false;
  }, [allowSubmit, followupStatus]);

  // const showModalViewTimeLine = () => {
  //   setViewTimeLine(true);
  //   setShowremarkform(true);
  //   document.body.classList.add("overflow-hidden");
  // };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const showFollowupHistory = () =>{
    setfollowupShow(true)
    document.body.classList.add("overflow-hidden");
  }
  const hideFollowUpTimeLine = () =>{
    setfollowupShow(false)
    document.body.classList.remove("overflow-hidden");
  }

  const handleSchedule = (data) => {
    setFollowupStatus((current) => ({
      ...current,
      ...data,
    }));
    if (data?.followup_date) {
      setIsReschedule(true);
      CreateFollowupNew();
    } else {
      setIsReschedule(false);
    }
  }

  // const showFollowupViewTimeLine = () => {
  //   setFollowupTimeLine(true);
  //   setShowremarkform(true);
  //   document.body.classList.add("overflow-hidden");
  // };

  const hideFollowupViewTimeLine = () => {
    setFollowupTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const CreateFollowupNew =() =>{
    setCreateFollowup(true)
    document.body.classList.add("overflow-hidden");
  }
  const CloseCreateFollowupNew =() =>{
    setCreateFollowup(false)
    document.body.classList.remove("overflow-hidden");
  }
  const sectionInfo = {
    section: "Followup status",
    sub_section: "Followup status",
  };
  const rejectDisable = (lead.leadDetail.reject_reason_id > 0 && !DISBURSE_REJECTION_ID.includes(lead.leadDetail.reject_reason_id) && [2, 3].includes(lead.leadDetail.status_id)) ? true : false 

  // Log the current date and time
  const currentDateTime = new Date().toString();
  const currentDate = moment(currentDateTime)
  const followUpCalls = historyList
    ?.filter(item => item.followup_status === "0") 
    ?.map(item => {
        const followupDate = moment(new Date(item.followup_date));
        return {
            followup_date: item?.followup_date ? DateFormate(item?.followup_date) : '',
            status: "Done",
            followup_with: item?.followup_with || '',
            comment: item?.comment || "",
            upcomingStatus: (currentDate?.isAfter(followupDate)) ? "Overdue" : "Upcoming",
            id: item.id
        };
    });

    const followupDate = new Date(followupStatus?.followup_date);
    const isMidnight = followupDate.getHours() === 0 && followupDate.getMinutes() === 0 && followupDate.getSeconds() === 0;

  return (
    <div
      // className="image-form-outer"
      key={lead?.leadDetail && lead?.leadDetail?.status_id}
    >
      {/* <div className="image-tab-panel">
        <DocumentGallery
          documentCategoryList={documentCategoryList}
          doc_id="1"
        />
      </div> */}
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
        <div className='followuohistoryflex'>
          <div className='followupwidth'>
              <div className="modal-header">
                  <h2>Follow Ups</h2>
              </div>
              </div>
          <div>
            <div className="sub-value">
                <h3 className="followupleadid">
                ➣  Lead Id:  <span> #{lead?.leadDetail.id}</span>
                </h3>
            </div>
            <div className="sub-value">
                <h3 className="followupleadid">
                ➣  Customer :  <span> {lead?.leadDetail?.customer?.first_name + ' ' + lead?.leadDetail?.customer?.last_name}</span>
                </h3>
            </div>
            </div>
          </div>

          <div className="followup">
            { showFollowup.length > 0 ? <div>Open Follow - Ups ({showFollowup.filter(followUp => followUp.followup_status === "0").length})</div> : <span>Open Follow - Ups (0)</span>}
            <div>
              {/* <div style={{ color: "blue", cursor: "pointer" }} onClick={() => showFollowupViewTimeLine(leadDetail.id)}>Followup History</div> */}
              <div style={{ color: "blue", cursor: "pointer" }} onClick={() => showFollowupHistory(leadDetail.id)}>Followup History</div>
            </div>
          </div>

          {
            !followUpCalls==0 && !followUpCalls.length>0 ?
            <div className="followupnewcard">
              <div style={{fontSize:32}}>⊙</div>
              <div className="mrg-b10">There are no Open Follow - Ups Here</div>
              <div className="mrg-b10">Please create a follow up and mark the pendency
              for this case. </div>
              <div className="mrg-b10">Ensure regular movement on your leads</div>
            </div> : <CustomCardSlider setScheduleData={handleSchedule} followUpCalls={followUpCalls} setStatusDone={setStatusDone}/>
          }

          {/* <div className="followupnewbtn">
            <button className="btn-primary"> + Create New</button>
          </div> */}

          {
          // (lead?.leadDetail?.followup_status === "1") ?
          //   <div className="followupcard">
          //     <div className="followupflex">
          //       <div>
          //         <div><strong>Followup Date</strong> </div>

          //         <div className="date" style={{ fontSize: 13, color: "#000" }}>
          //           {showFollowup && showFollowup.followup_date
          //             ? DateFormate(
          //               showFollowup.followup_date,
          //               "dd mmm yyyy"
          //             )
          //             : ""}
          //         </div>
          //         <div className="time" style={{ fontSize: 9, color: "#bbfbfbf" }}>
          //           {showFollowup && showFollowup.followup_date
          //             ? DateFormate(
          //               showFollowup.followup_date,
          //               "h:MM TT"
          //             )
          //             : ""}
          //         </div>
          //       </div>
          //       {/* <div className="followupcall">{showFollowup.followup_type}</div> */}
          //       {/* <div style={{ display: "flex" }}>
          //         <div>Follow Type : </div>
          //         <div style={{ fontWeight: 500, marginLeft: 5 }}>{(showFollowup.followup_type === 'call') ? 'Call' : 'Doc Pickup'}</div>
          //       </div> */}
          //     </div>
          //     <br></br>
          //     <div><strong>Followup Pending with  </strong></div>
          //     <div className="folloupcomment">
          //       <div>{showFollowup.followup_with}</div>
          //     </div>  
          //     <br></br>
          //     <div><strong>Comments</strong> </div>
          //     <div className="folloupcomment">
          //       <div>{showFollowup.comment}</div>
          //     </div>            
          //   </div> : ""
          }

       
         <div className="view-timeline-popup image-timeline-popup">
          <Modal show={createFollowup} handleClose={CloseCreateFollowupNew} width={500}>
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">
              {FORM_FIELDS.map((field,key) =>
                ["text", "pattern-format"].includes(field.type) ? (
                  <fieldset className="form-filed col-md-6 " key={key}>
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={followupStatus?.[field.name]}
                      placeholder=" "
                      thousandsGroupStyle="lakh"
                      thousandSeparator={","}
                      decimalSeparator={"."}
                      label={field.label}
                      onChange={handleInputChange}
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={field.validations}
                      required={field.required}
                    />
                  </fieldset>
                ) : field.type === "dropdown" ? (
                  <fieldset className="single-select col-md-12" key={key}>
                    <FormsySelect
                      name={field.name}
                      id={field.id}
                      inputProps={{
                        options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                        placeholder: field.label,
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value:
                          ALL_OPTIONS &&
                          ALL_OPTIONS[field.optionsKey] &&
                          ALL_OPTIONS[field.optionsKey].filter(
                            ({ value }) => value == followupStatus?.[field.name]
                          ),
                        isDisabled: field.readOnly,
                      }}
                      required={field.required}
                      value={followupStatus && followupStatus[field.name]}
                      onChange={(data) =>
                        handleSelectChange(data, field.name)
                      }
                    />
                  </fieldset>
                ) : field.type === "date-picker" ? (
                  <fieldset className="form-filed col-md-12 " key={key}>
                    <FormsyDatePicker
                      name="dob"
                      selectedDate={
                        followupStatus &&
                        followupStatus[field.name] &&
                        new Date(followupStatus[field.name])
                      }
                      placeholder={field.label}
                      // maxDate={field.maxDate}
                      minDate={new Date()}

                      value={followupStatus?.[field.name]}
                      onDateSelect={(date) => handleDateChange(date, field.name)}
                      // dateFormat="dd MMM, yyyy h:mm aa"
                      DateFormate
                      validations={field.validations}
                      validationError={
                        followupStatus?.[field.name] ? field.validationError : ""
                      }
                      disabled={field.readOnly}
                      required={field.required}
                      hasError={
                        followupStatus?.hasOwnProperty(field.name) && !followupStatus[field.name]
                          ? true
                          : false
                      }
                      showYearDropdown
                      showMonthDropdown
                      showTimeSelect
                      timeIntervals={15}
                      minTime={minTime}
                      maxTime={maxTime}
                      applyLabelTxtClassNaclassName={false}
                    />
                  </fieldset>
                ) : field.type === "textarea" ? (
                  <div className="col-sm-12" key={key}>
                    <fieldset className="form-filed">
                      <div className="material">
                        <textarea
                          id="comment"
                          name="comment"
                          type="text"
                          value={followupStatus?.comment}
                          placeholder=" "
                          onChange={handleInputChange}
                          className="form-input"
                        ></textarea>
                        <label data-label="Comment" className="form-label"></label>
                      </div>
                    </fieldset>
                  </div>
                ) : null
              )}
            </div>
            <div className="btn-save-remarks" style={{textAlign:"end"}}>
              <button type="submit" className="btn-primary"
               disabled={isSubmitDisabled || !allowSubmit || isMidnight} 
               >
                {isReschedule ? "Reschedule" : "Submit"}
              </button>
              {/* <button
                type="button"
                className="btn-primary-outline"
                onClick={showModalViewTimeLine}
                disabled={rejectDisable}
              >
                Remarks
                <span className="m-xs-l">
                  ({(remarksHistory && remarksHistory.length) || 0})
                </span>
              </button> */}
            </div>
            
          </Formsy>

          </Modal>
          </div>
          {statusDone===true ?
          <div className="btn-save-remarks" onClick={CreateFollowupNew} style={{textAlign:"end"}}>
            <button className="btn-primary">+ Create New</button>
          </div>:""}
          
        </div>
      </div>

      <div className="view-timeline-popup image-timeline-popup">
        {followupTimeLine ? (
          <div className="view-timeline-popup image-timeline-popup">
            <Modal show={followupTimeLine} handleClose={hideFollowupViewTimeLine} >
              <div className="modal-header">
                <h2>Followup History</h2>
              </div>
              <FollowupHistory historyList={historyList} followUphistoryList={followUphistoryList} t={t} />
            </Modal>
          </div>
        ) : ''}
      </div>

      <div className="view-timeline-popup">
            <Modal show={followupShow} handleClose={hideFollowUpTimeLine} width={550} >
              {/* <FollowUpHistoryForm activeTab={"followups"}/> */}
            </Modal>
      </div>
      <div className="view-timeline-popup">
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory
            showremarkform={showremarkform}
            // markAsFreezed={getContext.markAsFreezed}
            accessCondition={accessCondition}
            sectionInfo={sectionInfo}
          />
        </Modal>
      </div>
    </div>
  );
};
export default FollowUpsForm;
