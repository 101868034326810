import React, { useEffect, useMemo, useState } from "react";
import Formsy from "formsy-react";
import { useDispatch, useSelector } from "react-redux";
import FormFields from "../formFields/leadFormField.json";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import { useTranslation } from "react-i18next";
import { SAVE_FOLLOWUP } from "../../../services/followup.gql";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE} from "../../../config/constants";
import FormsyDatePicker from "../../elements/FormsyDatePicker";
import FormsySelect from "../../elements/FormsySelect";
import {GET_FOLLOWUP, GET_FOLLOWUP_ALL_LEADS,} from "../../../services/leads.gql";
import { GET_ALL_FOLLOWUP } from "../../../services/leads.gql";
import { getLoanDetail } from '../../../store/action/allAction';
import DateFormate from "dateformat";
import moment from "moment";
import FollowUpService from '../../../services/followup';
import FormsyInputField from "../../elements/FormsyInputField";

const now = new Date();
const currhour = Number(now.getHours());
const currmin = now.getMinutes();
const FORM_FIELDS = FormFields["FOLLOW_UPS"];

const FOLLOW_UPS_TYPE = [
  { id: "1", label: "Call", value: "call" },
  { id: "2", label: "Doc Pickup", value: "doc_pickup" }
]

const   CreateNewFollowup = (props) => {
  const {handlePopupSubmit} = props
  const user_id = props.user_id || 0;
  const { t } = useTranslation('language');
  const [followupStatus, setFollowupStatus] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [showFollowup, setShowFollowup] = useState({})
  const [followup_data , set_followup_data] = useState(false)
  const [historyList, setHistoryList] = useState([]);
  const [followupHistory, setFolloupHisory] = useState({});
  const [ isReschedule, setIsReschedule ] = useState(false);
  let [maxTime, setMaxTime] = useState(now.setHours(23, 59, 59));
  let [minTime, setMinTime] = useState(now.setHours(currhour, currmin, 0));

  const { masterdata, lead, remarksHistory, leadDetail } = useSelector(
    ({ masterdata, lead }) => ({
      masterdata,
      lead,
      leadDetail: lead?.leadDetail?.id,
      remarksHistory: lead.remarksHistory || [],
    })
  );
  const client = useApolloClient();
  const dispatch = useDispatch();
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
 

  let accessCondition = !IS_USER_ACCESS_PAGE(
    "followupStatus",
    "followup-status"
  )?.is_edit_access;

  if (accessCondition !== 1) {
    accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
  }

  useEffect(() => {
    let lead_id = lead?.leadDetail?.id || "";
    let user_id = lead?.leadDetail?.customer?.customer_id;

    if (lead_id && user_id) { 
      getFolloupData(lead_id, user_id);
    }
  }, [lead?.leadDetail?.id, lead?.leadDetail?.customer?.customer_id]);


  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    options["followup_with"] = FollowUpService.FOLLOW_UPS_WITH();
    options["follow_type"] = FOLLOW_UPS_TYPE;
    return options;
  }, [masterdata]);

  const handleInputChange = (event) => {
    if (event.target.id) {
      setFollowupStatus((currentValue) => ({
        ...currentValue,
        [event.target.id]: event.target.value,
      }));
    }
  };
  const handleDateChange = (date, field_name) => {
    set_followup_data(true);
    if (DateFormate(now, "yyyy-mm-dd") != DateFormate(date, "yyyy-mm-dd")) {
      set_followup_data(true);
      setMinTime(now.setHours(0, 0, 0))
    } else {
      setMinTime(now.setHours(currhour, currmin, 0))
      set_followup_data(true);
    }
    if (date && field_name) {
      setFollowupStatus((currentValue) => ({
        ...currentValue,
        [field_name]: date,
      }));
    }
    getFollowupAllLeads(lead?.leadDetail?.id, lead?.leadDetail?.customer?.customer_id, date);

  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setFollowupStatus(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }



  const getFollowupAllLeads = async (lead_id, user_id, selectedDate) => {
    try {
      const ls = (JSON.parse(localStorage.getItem("user_information"))).id
      const date = new Date(selectedDate);
      date.setHours(date.getHours() + 5);
      date.setMinutes(date.getMinutes() + 30);
      const formattedDate = date.toISOString().slice(0, 19).replace('T', ' ');
      const allFollowsData = await executeGraphQLQuery(GET_FOLLOWUP_ALL_LEADS(ls, formattedDate), client);
      const allFollowups = allFollowsData?.data?.get_followup_for_user || [];
      setShowFollowup(allFollowups);
    } catch (error) {
      console.error("Error fetching activity history data:", error);
      setShowFollowup([]);
    }
  };
  
  
  const renderScheduledFollowups = () => {
    if (showFollowup && showFollowup.length  > 0) {
      return (
        <div className="scheduled-follow-ups">
          <div className="scheduledfontheading">These are Follow-ups scheduled on this day at this time</div>
          <ul className="followupheightfix">
            {showFollowup.map((item, index) => (
              <li key={index}>
                <div className="scheduledfont">
                  <span>{item.created_user_details?.name}</span>
                  <span>({item.id})</span>
                  <span>Follow up with </span>
                  <span>{item.followup_with}</span>
                  <span> at {moment(item.followup_date).format("YYYY-MM-DD HH:mm")}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <div className="no-followups">
          <p className="scheduledfontheading">No follow-ups scheduled at this time. You’re good to go! 👍</p>
        </div>
      );
    }
  };

  const getFolloupData = async () => {
    let lead_id = lead?.leadDetail?.id || "";
    let user_id = lead?.leadDetail?.customer.customer_id || "";
    try {
      const result = await executeGraphQLQuery(GET_ALL_FOLLOWUP(lead_id, user_id), client);
      if (result?.data?.get_followup && result.data.get_followup.length > 0) {
        const lastFollowup = result?.data?.get_followup?.length && result?.data?.get_followup;
        setHistoryList(result?.data?.get_followup);
        setShowFollowup(lastFollowup);
      } 
    } catch (error) {
      console.error('Query error:', error);
    }
  }

 
  const handleSubmit = () => {
    let lead_id = lead?.leadDetail?.id || "";
    let formattedFollowupDate = moment(followupStatus.followup_date).format("YYYY-MM-DD HH:mm") || "";
    let reqBody = {
        lead_id: lead_id,
        id: (isReschedule) ? followupStatus.id : 0,
        followup_with: followupStatus.followup_with || "customer",
        followup_type: followupStatus.followup_type || "call",
        followup_date: formattedFollowupDate,
        comment: followupStatus.comment || ""
    };
    
    let mutation = SAVE_FOLLOWUP(authUser?.id),
      variables = { CreateFollowupInput: reqBody };

    executeGraphQLMutation(mutation, variables, client)
      .then((resp) => {
        let response = resp?.data?.SAVE_FOLLOWUP || null;
        setFollowupStatus({comment: "", followup_date: null});
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        let folloupdata = GET_FOLLOWUP(lead_id, client);
        dispatch(getLoanDetail(lead_id, client));
        setFolloupHisory(folloupdata);
        setShowFollowup([]);
        set_followup_data(false);
        handlePopupSubmit(false)
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  const isSubmitDisabled = useMemo(() => {
    return false;
  }, [allowSubmit, followupStatus]);
  

  return (
    <div
      key={lead?.leadDetail && lead?.leadDetail?.status_id}
    >
      <div>
        <div className="lead-detail-from-outer">
       
                <div className="modal-header">
                  <div className="selctflex">
                  <h2>
                    Schedule Follow-up with - 
                  </h2>
                  <Formsy
                      autoComplete="off"
                      onValid={() => setAllowSubmit(true)}
                      onInvalid={() => setAllowSubmit(false)}
                      onValidSubmit={handleSubmit}
                      style={{width:"40%"}}
                    >
                <div className="row">
                  {FORM_FIELDS.map((field, key) =>
                    ["text", "pattern-format", "dropdown"].includes(field.type) ? (
                      field.type === "dropdown" ? (
                        <fieldset className="single-select col-md-10" key={key}>
                          <div className="bordernone">
                          <FormsySelect
                                  name={field.name}
                                  id={field.id}
                                  inputProps={{
                                    options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                                    placeholder: field.label,
                                    className: "react-select",
                                    classNamePrefix: "react-select",
                                    value:
                                      followupStatus && followupStatus[field.name]
                                        ? ALL_OPTIONS &&
                                          ALL_OPTIONS[field.optionsKey] &&
                                          ALL_OPTIONS[field.optionsKey].find(
                                            ({ value }) => value === followupStatus[field.name]
                                          )
                                        : (ALL_OPTIONS &&
                                            ALL_OPTIONS[field.optionsKey] &&
                                            ALL_OPTIONS[field.optionsKey][0]) || null,
                                    isDisabled: field.readOnly,
                                  }}
                                  required={field.required}
                                  value={followupStatus && followupStatus[field.name]}
                                  onChange={(data) => handleSelectChange(data, field.name)}
                                />

                                </div>
                              </fieldset>
                            ) : null
                          ) : null
                        )}
                      </div>
                    </Formsy>
               
              </div>

          </div>
        
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">
              {FORM_FIELDS.map((field,key) =>
                ["text", "pattern-format"].includes(field.type) ? (
                  <fieldset className="form-filed col-md-6 " key={key}>
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={followupStatus?.[field.name]}
                      placeholder=" "
                      thousandsGroupStyle="lakh"
                      thousandSeparator={","}
                      decimalSeparator={"."}
                      label={field.label}
                      onChange={handleInputChange}
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={field.validations}
                      required={field.required}
                    />
                  </fieldset>
                ) : field.type === "date-picker" ? (
                  <fieldset className="form-filed col-md-6 " key={key} style={{marginBottom:20}}>
                    <FormsyDatePicker
                      name="dob"
                      selectedDate={
                        followupStatus &&
                        followupStatus[field.name] &&
                        new Date(followupStatus[field.name])
                      }
                      placeholder={field.label}
                      // maxDate={field.maxDate}
                      minDate={new Date()}

                      value={followupStatus?.[field.name]}
                      onDateSelect={(date) => handleDateChange(date, field.name)}
                      // dateFormat="dd MMM, yyyy h:mm aa"
                      DateFormate
                      validations={field.validations}
                      validationError={
                        followupStatus?.[field.name] ? field.validationError : ""
                      }
                      disabled={field.readOnly}
                      required={field.required}
                      hasError={
                        followupStatus?.hasOwnProperty(field.name) && !followupStatus[field.name]
                          ? true
                          : false
                      }
                      showYearDropdown
                      showMonthDropdown
                      showTimeSelect
                      timeIntervals={15}
                      minTime={minTime}
                      maxTime={maxTime}
                      applyLabelTxtClassNaclassName={false}
                    />
                  </fieldset>
                ) : field.type === "textarea" ? (
                  <div className="col-sm-12" key={key}>
                    <fieldset className="form-filed">
                      <div className="material">
                        <textarea
                          id="comment"
                          name="comment"
                          type="text"
                          value={followupStatus?.comment}
                          placeholder=" "
                          onChange={handleInputChange}
                          className="form-input"
                        ></textarea>
                        <label data-label="Comment" className="form-label"></label>
                      </div>
                    </fieldset>
                  </div>
                ) : null
              )}
            </div>

           
            { followup_data === true && renderScheduledFollowups()}

            {/* <div>
              <div className="scheduledfont">These are Follow -ups scheduled on this day at this time</div>
              <ul>
                {allFollowupLeads.map((item)=>{
                      <li>
                      <div className="scheduledfont"><span></span> <span>{item.name}</span><span>{item.id}</span> <span>Follow up with </span> <span>{item.followup_with}  </span> <span>at {item.followup_date}  </span></div>
                      </li>
                }
              )}
               
              

                
              
              </ul>
            </div>

            <div className="scheduledfont">No Follow ups at this time. You’re good to go!  👍</div> */}
            <div className="btn-save-remarks" style={{textAlign:"center"}}>
              <button type="submit" className="btn-primary"
               disabled={isSubmitDisabled || !allowSubmit} 
               >
                Submit
              </button>
             
            </div>
            
          </Formsy>
        </div>
      </div>

     

    
    </div>
  );
};
export default CreateNewFollowup;
