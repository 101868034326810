import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DocumentGallery from './DocumentGallery';
import { useOutletContext } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import Loader from '../elements/Loader';
import { Nav } from 'react-bootstrap';
import { executeGraphQLMutation } from '../../common/partnerExecuteGraphQLQuery';
import { PAYOUT__LIST_WITH_FILTERS } from '../../services/payout.gql';
import { toast } from 'react-toastify';
import moment from 'moment';

const PayoutHistory = (props) => {
  const [disburseDetails, setDisbursedDetails] = useState([]);
  const [activeTab, setActiveTab] = useState("tranchtype_0");
  let [loading, setIsLoading] = useState(false);
  const { lead } = useSelector(lead => lead);
  const [payoutListing,setPayoutListing] = useState([])
  const [selectedTranch,setSelectedTranch] = useState(null)







  useEffect(() => {
    if (lead && lead.leadDetail) {
      let disbursedData = lead.leadDetail.splitpayment || [];
      const splitPayment = disbursedData.map(data => {
        return {
          id: data.id,
          pay_in_done: data.pay_in_done || '0',
          is_pay_in_verified: data.is_pay_in_verified || '0',
          pay_in_confirm_mode: data.pay_in_confirm_mode || '0',
          pay_in_confirm_amount: data.pay_in_confirm_amount || '',
          pay_in_confirm_id: data.pay_in_confirm_id || ''
        }
      })
      setDisbursedDetails(splitPayment);      
    }

  }, [lead]);

  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []


  const getPayoutListWithFilters = async (pageNumber=1) => {
      // setpageloading(true);
      if(selectedTranch && lead?.leadDetail?.partner_id){
        const payout_filters = {
          tranche_id:selectedTranch.toString(),
          partner_id: lead.leadDetail.partner_id,
          is_paging: "no",
          source: "web",
        };
      const variables = {
        filter_params: payout_filters,
        page_no: pageNumber,
      };
      try {
        const result = await executeGraphQLMutation(
          PAYOUT__LIST_WITH_FILTERS,
          variables,
          pageNumber
        );
        if (result.data.getDisbursalLeads.length == 0) {
          setPayoutListing([]);
        }
        if (result) {
          if (
            result.data.getDisbursalLeads &&
            result.data.getDisbursalLeads.length > 0
          ) {
            const disbursals = result.data.getDisbursalLeads[0].disbursals[0].payouts;
            setPayoutListing(disbursals);
          }
        } else {
          setPayoutListing([]);
        }
      } catch (error) {
        toast.error("Failed");
        console.error(error);
      }
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: amount % 1 === 0 ? 0 : 2
    }).format(amount);
  }
  useEffect(()=>{
    if(disburseDetails.length && selectedTranch==null){
      setSelectedTranch(disburseDetails[0].id)
      // getPayoutDetails(disburseDetails[0].id)
    }
  },[disburseDetails])

  useEffect(() => {
    if (selectedTranch) {
        getPayoutListWithFilters()
    }
  }, [selectedTranch]);


  return (
    <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
      {loading ? <Loader /> : null}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="44" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="payout-form-heading">
            <h2>Payout Eligible</h2>
          </div>
          <Nav variant="pills" className="flex-column" style={{ overflow: "hidden", overflowX: "auto" }}>
            <div className="tab-list">
              {disburseDetails.length && disburseDetails.map((coApplicant, index) =>                
                  <Nav.Item key={coApplicant.id}>
                    <Nav.Link
                      onClick={() => {
                        setSelectedTranch(coApplicant.id)
                        setActiveTab(`tranchtype_${index}`)
                        }
                      }
                      eventkey={`tranchtype_${index}`}
                      active={activeTab === `tranchtype_${index}`}
                    >
                      <b>{`Transaction ${index + 1} (${coApplicant.id})`}</b>
                    </Nav.Link>
                  </Nav.Item>
              )}
            </div>
          </Nav>
          <div>
          <div className="clearfix">
          {payoutListing.length ? <div className="card">
            <div className="table-responsive" style={{overflowX:"scroll"}}>
              <table className="table table-bordered tablefontsize" style={{width:"max-content"}}>
                <thead>
                  <tr>
                    <th>{"Payout ID"}</th>
                    <th>{"Payout Type"}</th>
                    <th>{"Date"}</th>
                    <th>{"Scheme"}</th>
                    <th>{"Payout %"}</th>
                    <th>{"Paid %"}</th>
                    <th>{"Payout Amount"}</th>
                    <th>{"TDS"}</th>
                    <th>{"TDS %"}</th>
                    <th>{"PAT"}</th>
                    <th>{"Paid Status"}</th>
                  </tr>
                </thead>
                <tbody>
                  {payoutListing.map((payout, index) => (
                    <>
                      <tr key={index}>
                        <td>{payout.id}</td>
                        <td>{payout.payout_type}</td>
                        <td>{payout.created_at ? moment(payout.created_at).format("lll") : null}</td>
                        <td>{payout?.scheme ? payout?.scheme?.scheme_name:""}</td>
                        <td>{payout.payout_per}</td>
                        <td>{payout.paid_per}</td>
                        <td>{payout.payout_amount}</td>
                        <td>{payout.tds}</td>
                        <td>{payout.tds_per}</td>
                        <td>{payout.pat}</td>
                        <td>{payout.paid_status}</td>
                        
                      </tr>
                    </>
                  ))}
                  {/* <tr>
                    <td colSpan={4}>
                      {" "}
                      <Pagination
                        pageCount={
                          pagination && pagination.totalpage
                            ? pagination.totalpage
                            : 0
                        }
                        // activePage={filterData.page_no ? filterData.page_no : 1}
                        onPaginate={handlePagination}
                      />
                    </td>
                    <td>
                      Total:{" "}
                      {pagination && pagination.totalrecords
                        ? pagination.totalrecords
                        : 0}{" "}
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>:<div className='notfoundpayout'>Payout calculation is not found.</div>}
        </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PayoutHistory;