import React, { useEffect, useState } from "react";
import Select from "react-select";
import MultiSelect from "../elements/MultiSelect.js";
import MasterService from "../../services/MasterService";
import { Navigate } from "react-router-dom";
import InputField from "../elements/InputField";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../common/partnerExecuteGraphQLQuery.js";
import { toast } from "react-toastify";
import {
  PAYOUT_LEADS_COUNTS,
  PARTNER_DISBURSAL_LIST,
} from "../../services/payout.gql";
import SearchContent from "./SearchContent.js";
import secureStorage from "./../../config/encrypt";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  SCHEME_LIST,
  FINEX_MASTER_LIST,
  FINEX_BANK_LIST,
  PAYOUT_POINT_DETAILS,
} from "../../services/scheme.gql";

const moment = require("moment");

const SearchFilter = (props) => {
  const [buttonClick, setButtonClick] = useState(0);
  const [filterData, setFilterData] = useState({});
  const [page_no, setPageNo] = useState(1);
  const [partnerListing, setPartnerData] = useState([]);
  const [payoutListing, setPayoutListing] = useState([]);
  const [paginationdata, setPaginations] = useState({});
  const [pageloading, setpageloading] = useState(false);
  const [schemeList, setschemeList] = useState([]);
  const [leads_counts, setLeadsCounts] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [productType, setProductType] = useState([]);
  const [financers, setFinancers] = useState([]);
  const [fulfillmenttypes, setFulfillmentTypes] = useState([]);
  const [user_list, setUserList] = useState([]);
  const [financersBanks, setFinancersPersonal] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [passingData, setPassingData] = useState({});
  const [passingArray, setPassingArray] = useState([]);
  const [payout_points, setPayoutpoints] = useState([])
  const [paymentData, setPaymentData] = useState({
    bank_id: 0,
    rtgs_no: "",
    trans_date: "",
  });
  const [filter_params, setFilterParams] = useState({});

  const filterOption = [
    { value: "lead_id", label: "Lead ID" },
    { value: "partner_code", label: "Partner Code" },
    { value: "tranch_id", label: "Tranche ID" },
    { value: "payout_id", label: "Payout ID" },
    { value: "Customer_Name", label: "Customer Name" },
    { value: "partner_name", label: "Partner Name" },
    { value: "Scheme_ID", label: "Scheme ID" },
    { value: "app_id", label: "Disbursal ID" },
    { value:"account_number" , label: "A/C number"},
    { value:"utr_no", label:"UTR"},
    { value:"invoice_no", label:"Invoice No."},
    { value:"invoice_id", label:"Invoice ID"},
  ];
 

  const updateReduxData = async () => {
    if (secureStorage.getItem("loginUserInfo")) {
      var loginResponse = secureStorage.getItem("loginUserInfo");
      props.updateStateIntoRedux("LOGIN_USER", loginResponse);
      afterLoginSetState(loginResponse);
    } else {
      return (<Navigate to={"/login"} />);
    }
  };

  const afterLoginSetState = (loginResponse) => {
    setpageloading(false);
    let role =
      (loginResponse &&
        loginResponse.data &&
        loginResponse.data.user_data &&
        loginResponse.data.user_data.role) ||
      null;
    if (role != "dealercentral") {
      return (<Navigate to={"/"} />);
    }
  };

  const handleInputarray = (e) => {
    const { id, value } = e.target;
    setFilterParams((current) => ({
      ...current,
      [id]: value,
    }));
  };

  const handleSelectarry = (id, data) => {
    setFilterParams((current) => ({
      ...current,
      [id]: data.value || data.id || data.value,
    }));
  };

  const submitFilterForm = (event) => {
    event.preventDefault();
  };

  const resetFilterForm = (event) => {
    window.location.reload(false);
  };

  const getPartnersDisbursalList = async (
    status = "pending",
    current_page = 1
  ) => {
    setpageloading(true);
    const payout_filters = { ...filterData };
    payout_filters.status = !payout_filters.status
      ? "pending"
      : payout_filters.status;
    const pageNumber = current_page ? current_page : page_no;
    if (
      payout_filters.product_type_ids &&
      payout_filters.product_type_ids.length > 0
    ) {
      payout_filters.product_type = payout_filters.product_type_ids[0];
    }
    if (status && status != "") {
      payout_filters.status = status;
    }
    if (payout_filters.financier_id && payout_filters.financier_id.length > 0) {
      payout_filters.financersids = payout_filters.financier_id;
    }
    delete payout_filters.schemefinancers;
    delete payout_filters.product_type_ids;
    delete payout_filters.financier_id;
    const variables = {
      filter_params: payout_filters,
      page_no: pageNumber,
    };

    try {
      const result = await executeGraphQLMutation(
        PARTNER_DISBURSAL_LIST,
        variables,
        pageNumber
      );
      if (result.data.partner_disbursals.length == 0) {
        setPartnerData([]);
        setPayoutListing([]);
        setPaginations({});
        setpageloading(false);
      }
      if (result) {
        if (
          result.data.partner_disbursals &&
          result.data.partner_disbursals.length > 0
        ) {
          const disbursals = result.data.partner_disbursals[0].disbursals;
          setPartnerData(disbursals);
          setPaginations(result.data.partner_disbursals[0].pagination);
          setpageloading(false);
        }
      } else {
        setPartnerData([]);
        setPaginations({});
        setpageloading(false);
      }
    } catch (error) {
      // toast.error("Failed");
    }
  };
  useEffect(() => {
    getDealerList();
    getSchemeList();
    updateReduxData();
    // getleadsCounts()
    getFinexMasterData();
    getFinexPersonalBankData();
    getPartnersDisbursalList();
    getPayoutDataList()
  }, []);

  const getSchemeList = async () => {
    const partnerArray = [];
    const schemeData = await executeGraphQLQuery(SCHEME_LIST);

    const data = schemeData.data.getAllSchemsList;
    data &&
      data.map((list) => {
        const partnerData = {
          value: list.scheme_name,
          label: list.id,
        };
        partnerArray.push(partnerData);
      });
    setschemeList(partnerArray);
  };

  // const getleadsCounts = async () => {
  //     const leadscounts = await executeGraphQLQuery(PAYOUT_LEADS_COUNTS);
  //     const data = leadscounts.data.leads_count
  //     let total = 0
  //     data && data.map((list) => {
  //         total = total + parseInt(list.counts);
  //     })
  //     if (total > 0) {
  //         setTotalLeads(total)
  //     }
  //     setLeadsCounts(data);filterData

  // }

  const getFinexMasterData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(
      (err) => console.log(err)
    );

    if (masterData && masterData.data && masterData.data.masterdata) {
      const bankData = [];
      const producttypedata = [];
      const fulfillmenttypeData = [];
      const data = masterData.data.masterdata;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      const product_type =
        data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];
      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
          });
        });
        setFinancers(bankData);
      }
      if (product_type && product_type.length > 0) {
        product_type.map((ptype) => {
          producttypedata.push({ id: ptype.id, name: ptype.label });
        });
        setProductType(producttypedata);
      }
      const fulfillmenttype =
        data.fulfillment_type && data.fulfillment_type.length > 0
          ? data.fulfillment_type
          : [];
      if (fulfillmenttype && fulfillmenttype.length > 0) {
        fulfillmenttype.map((ptype) => {
          fulfillmenttypeData.push({ id: ptype.id, name: ptype.label });
        });
        setFulfillmentTypes(fulfillmenttypeData);
      }
    }
  };

  const getFinexPersonalBankData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_BANK_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.bank_pa) {
      const bankData = [];
      const data = masterData.data.bank_pa;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      const product_type =
        data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];
      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
          });
        });
        setFinancersPersonal(bankData);
      }
    }
  };
  
  const filter_params_arry = ["lead_id", "partner_code", "Scheme_ID", "payout_id","tranch_id" ];

  const validProps = () => {
    setPassingData(filter_params);
    setPassingArray(filterData);
    setButtonClick((prevCounter) => prevCounter + 1);
  };

  const getPayoutDataList =  async() => {
    const result = await executeGraphQLQuery(PAYOUT_POINT_DETAILS());
    const PayoutDataList = []
    result.data.points.map(point => {
       PayoutDataList.push({ id: point.id, name: point.booking_point });
   });
   //console.log("...................................." ,PayoutDataList)
   setPayoutpoints(PayoutDataList)
   //console.log("....................................+." ,  bookingpoints )
}


  const handleProps = () => {
    if (filter_params_arry.includes(filter_params.filter_params)) {
      isNaN(+filter_params.app_id)
        ? toast.error(`${filter_params.filter_params} should be a number`)
        : validProps();
    } else {
      validProps();
    }
  };

  const getDealerList = () => {
    setUserList([]);
    // MasterService.get("account/user/user_list")
    //   .then(function (response) {
    //     if (response.data.status == 200) {
    //       if (response.data.data.length) {
    //         setUserList(response.data.data);
    //       } else {
    //         setUserList([]);
    //       }
    //     }
    //   })
    //   .catch(function (response) {});
  };

  let role;
  if (secureStorage.getItem("loginUserInfo")) {
    let loginResponse = secureStorage.getItem("loginUserInfo");
    if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
      role = loginResponse.data.user_data.role;
    }
  }

  const handleChange = async (n, v, isMulti, opt, optl) => {
    switch (n) {
      case "financier_id":
        let financier_ids = v.map((obj) => {
          return obj.id;
        });
        setFilterData((prev) => ({ ...prev, [n]: v.map((obj) => obj[opt]) }));

        break;
      case "partnerName":
        let partner_ids = v.map((obj) => {
          return obj.user_id;
        });
        setFilterData((prev) => ({ ...prev, [n]: v.map((obj) => obj[opt]) }));

        break;
    }
  };
  return (
    <>
      <div className="search-wrap">
        <form onSubmit={submitFilterForm}>
          <div className="row">
            <div className="col-sm-2 col-md-2">
              <label>Search By</label>
              <Select
                id="filter_params"
                options={filterOption}
                name="filter_params"
                onChange={(data) => handleSelectarry("filter_params", data)}
                values={filterOption.value}
              />
            </div>

            <div className="col-sm-2 col-md-2">
              <InputField
                inputProps={{
                  id: "app_id",
                  type: "text",
                  name: "app_id",
                  autocompleate: "off",
                  label: "Search Value",
                  placeholder: "Search By",
                }}
                value={filter_params["app_id"]}
                onChange={handleInputarray}
              />
              {/* <InputField
                type="text"
                name="app_id"
                id="app_id"
                placeholder="app id"
                value={filter_params["app_id"]}
                is_label_required={true}
                onChange={handleInputarray}
              /> */}
            </div>

            {/* <div className="col-sm-2">
              <label>Partner Name</label>
              <MultiSelect
                options={user_list}
                placeholder={"Partner Name"}
                name="partnerName"
                optionLabel="name"
                optionValue="user_id"
                id="partnerName"
                values={filterData.partnerName}
                isMulti
                onChange={handleChange}
              />
            </div> */}

            <div className="col-sm-2">
              <label>Bank Name</label>
              <MultiSelect
                options={financers}
                placeholder={"Banks"}
                name={"financier_id"}
                optionLabel="name"
                optionValue="id"
                values={filterData.financier_id}
                onChange={handleChange}
                isMulti
              />
            </div>

            <div className="col-sm-2">
              <label>Product Type</label>
              <Select
                placeholder={"Product Type"}
                id="product_type"
                onChange={(data) => handleSelectarry("product_type", data)}
                options={productType}
                label={"Select Product Type"}
                name="product_type"
                value={productType.value}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
              />
            </div>

            <div className="col-sm-2">
              <label>Scheme Name</label>
              <Select
                id="Select_Scheme"
                placeholder={"Scheme"}
                onChange={(data) => handleSelectarry("Select_Scheme", data)}
                options={schemeList}
                name="partnerscheme"
                optionLabel="name"
                label="select_Scheme"
                value={schemeList.label}
                getOptionLabel={({ value }) => value}
                getOptionValue={({ label }) => label}
              />
            </div>
            <div className="col-sm-2">
            <label>Point</label>
                    <Select
                        id="point_id"
                        onChange={() => handleInputarray}
                        options={payout_points}
                        label={"Payout Point"}
                        name="point_id"
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                      
                        value={payout_points.id}
                        // isDisabled = {is_disabled}
                        
                    />
           </div>
            <div className="col-sm-2">
              <InputField
                inputProps={{
                  id: "disbursal_date_from",
                  type: "date",
                  name: "disbursal_date_from",
                  autocompleate: "off",
                  label: "Disb. From",
                  placeholder: "date",
                }}
                value={filter_params["disbursal_date_from"]}
                onChange={handleInputarray}
              />
              {/* <InputField
                type="date"
                name="disbursal_date_from"
                id="disbursal_date_from"
                placeholder="date"
                is_label_required={true}
                onChange={handleInputarray}
                value={filter_params["disbursal_date_from"]}
              /> */}
            </div>

            <div className="col-sm-2">
              <InputField
                inputProps={{
                  id: "disbursal_date_to",
                  type: "date",
                  name: "disbursal_date_to",
                  autocompleate: "off",
                  label: "Disb. To",
                  placeholder: "date",
                }}
                min={"2024-04-12"}
                value={filter_params["disbursal_date_to"]}
                onChange={handleInputarray}
              />
            </div>
            
            <div className="col-sm-2">
              <InputField
                inputProps={{
                  id: "invoice_creation_date",
                  type: "date",
                  name: "invoice_creation_date",
                  autocompleate: "off",
                  label: "Generation Date",
                  placeholder: "date",
                }}
                min={"2024-04-12"}
                value={filter_params["invoice_creation_date"]}
                onChange={handleInputarray}
              />
            </div>
            <div className="col-sm-2">
              <InputField
                inputProps={{
                  id: "signing_date",
                  type: "date",
                  name: "signing_date",
                  autocompleate: "off",
                  label: "Signing Date",
                  placeholder: "date",
                }}
                min={"2024-04-12"}
                value={filter_params["disbursal_date_to"]}
                onChange={handleInputarray}
              />
            </div>
            <div className="col-sm-2">
              <InputField
                inputProps={{
                  id: "paid_date",
                  type: "date",
                  name: "paid_date",
                  autocompleate: "off",
                  label: "Paid Date",
                  placeholder: "date",
                }}
                min={"2024-04-12"}
                value={filter_params["disbursal_date_to"]}
                onChange={handleInputarray}
              />
            </div>

            <div className="col-sm-2 col-md-2" style={{ marginTop: 18 }}>
              <div className="btn-submit-reset top-btn-none pull-right">
                <button
                  type="submit"
                  className="btn btn-primary mrg-r10"
                  onClick={handleProps}
                >
                  {"Search"}
                </button>
                <button
                  type="reset"
                  className="btn-reset"
                  onClick={resetFilterForm}
                >
                  {"Reset"}
                </button>
              </div>
            </div>
          </div>
        </form>
        {/* {JSON.stringify(filterData)} */}
      </div>
      <SearchContent
        state={filterData}
        buttonClick={buttonClick}
        filterOptions={filterOptions}
        filter_params={passingData}
        filterData={passingArray}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(SearchFilter)
);
