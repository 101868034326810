import React, { useEffect, useState } from 'react';
import { Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EmailLogin from './EmailLogin';
import OtpLogin from './OtpLogin';
import { toast } from 'react-toastify';

// import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
// import { GET_REDIS_FLAG } from "../../services/login.gql";
import {USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE} from '../../config/constants';

const Login = ({parentCallback}) => {
    const [activeTab, setActiveTab] = useState('by-mobile');
    const [otp, setOtp] = useState('');
    let [LoginData, setLoginData] = useState(null);
    const [errorForm, setErrorForm] = useState({});
    const [otpEnabled, setOtpEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const redisFlag = false;
    const navigate = useNavigate();

    useEffect(() => {
      let lead_dashboard = IS_USER_ACCESS_PAGE("menu", "lead_dashboard")?.is_edit_access;
         let partner_dashboard = IS_USER_ACCESS_PAGE("menu", "partner_dashboard")?.is_edit_access;
         let manage_dashboard =  IS_USER_ACCESS_PAGE("menu", "manage_leads")?.is_edit_access;
         let payouts =  IS_USER_ACCESS_PAGE("menu", "payouts")?.is_edit_access;
         if (lead_dashboard === 1 || lead_dashboard === 2) {
           navigate('/dashboard');
           

         } else if (partner_dashboard === 1 || partner_dashboard === 2) {
           navigate('/partner-dashboard');
         }    
         else if (manage_dashboard === 1 || manage_dashboard === 2) {
          navigate('/lead-list');
        }    
        else if (payouts === 1 || payouts === 2) {
          navigate('/schemes/list');
        } 
               
       else {   
      //    toast.error('Account does not exist, inactive, or has been deleted.');
         navigate('/login');
         setTimeout(function(){
          // window.location.reload();
        },1500)
      }
    }
  , [navigate]);
  


      const handleSelectTab = (eventKey) => { setActiveTab(eventKey); }       
        return (
           
                    <div className="login-outer">
                        
                        <div className="login-right-panel">
                        <div style={{display:"flex",alignItems:"center",justifyContent:"center",marginBottom:"40px"}}>
                        <img src="https://static.ambak.com/saathi/ambak_logo.svg" alt='logo' width={186} height={56} />
                        </div>
                            <Tab.Container id="" defaultActiveKey={activeTab} onSelect={handleSelectTab}>
                            <div className="login-tab">
                                
                                <Tab.Content>
                                    <Tab.Pane active={activeTab === 'by-mobile'} eventKey="by-mobile">
                                        <OtpLogin
                                            otp={otp}
                                            setOtp={setOtp}
                                            LoginData={LoginData}
                                            setLoginData={setLoginData}
                                            errorForm={errorForm}
                                            setErrorForm={setErrorForm}
                                            otpEnabled={otpEnabled}
                                            setOtpEnabled={setOtpEnabled}
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            redisFlag={redisFlag}
                                            parentCallback={parentCallback}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane active={activeTab === 'by-email'} eventKey="by-email">
                                        <EmailLogin
                                            otp={otp}
                                            setOtp={setOtp}
                                            LoginData={LoginData}
                                            setLoginData={setLoginData}
                                            errorForm={errorForm}
                                            setErrorForm={setErrorForm}
                                            otpEnabled={otpEnabled}
                                            setOtpEnabled={setOtpEnabled}
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            redisFlag={redisFlag}
                                            parentCallback={parentCallback}
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                        </div>
                    </div>
              
        );
}


export default Login;
