import React, { useState, useEffect } from "react";
import Select from "react-select";
import InputField from "../elements/InputField";
import { toast } from "react-toastify";
import { executeGraphQLQuery } from "../../common/partnerExecuteGraphQLQuery.js";
import { SCHEME_LEAD_LIST, SAVE_LEAD_SCHEME } from "../../services/payout.gql.js";
import Modal from "../elements/Modal";
import LeadWiseListing from "./LeadWiseListing.js";

const LeadwiseScheme = () => {
  const [filter_params, setFilterParams] = useState({payout_type:"standard"});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [paginationdata, setPaginations] = useState({});
  const [totalPage, setTotalPage] = useState(0);

  const [filterOption] = useState([
    { value: "lead_id", label: "Lead ID" },
    { value: "tranche_id", label: "Tranche ID" },
    { value: "partner_id", label: "Partner ID" },
  ]);

  const [payoutTypeOption] = useState([
    { value: "standard", label: "Absolute Payout" },
    { value: "payin", label: "Payout as % Payin" },
  ]);

  const [payoutCalculatedOnOptions] = useState([
    { value: "disbursal_amount", label: "Disbursal Amount" },
    { value: "santioned_amount", label: "Santioned Amount" },
  ]);

  const [searchFilter, setSearchFilter] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [items, setItems] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const filterKeys = ["lead_id", "partner_code", "Scheme_ID", "payout_id", "tranche_id"];

  const handleSelectArray = (field, data) => {
    setFilterParams(prevState => ({
      ...prevState,
      [field]: data.value
    }));
    //  console.log(`Updated ${field}:`, data.value); // Add log to confirm the updated value
  };

  const fetchItems = async (filterValue = "lead_id", appValue = 0, page_no = 1) => {
    if (!filterValue) {
      toast.error("Please select a valid search value.");
      return;
    }
    try {
      const result = await executeGraphQLQuery(SCHEME_LEAD_LIST(filterValue, +appValue, page_no));

      const schemeLeadList = result?.data?.scheme_lead_list;
      if (Array.isArray(schemeLeadList) && schemeLeadList.length > 0) {
        const schemeList = schemeLeadList[0]?.scheme_list || [];
        setPaginations(schemeLeadList[0].pagination);
        setTotalPage(schemeLeadList[0].pagination.totalpage);

        if (schemeList.length > 0) {
          setItems(schemeList); 
        } else {
          setItems([]);
          toast.error("No results found.");
        }
      } else {
        setItems([]);
        toast.error("No results found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("No Data Found");
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const handleInputArray = (e) => {
    let { id, value } = e.target;
    if (id === "app_id" || id === "lead_id") {
      value = value.replace(/\D/g, "");
    }
    setFilterParams((current) => ({
      ...current,
      [id]: value,
    }));
  
    // Validate the respective field
    if (id === "payout_percentage") {
      validateAbsolutePayout(value, "payout_percentage");
    }
  
    if (id === "payout_per_as_payin") {
      validateAbsolutePayout(value, "payout_per_as_payin");
    }
  };

  const handleFormSubmit = async () => {
    const addSchemeArgs = {
      lead_id: parseInt(filter_params.lead_id, 10),
      payout_type: filter_params.payout_type,
      calculated_on: filter_params.payout_calculation || "disbursal_amount",
      remarks: filter_params.reason || "No remarks",
      payout_per: parseFloat(filter_params.payout_percentage),
    };

    if (isNaN(addSchemeArgs.lead_id) || isNaN(addSchemeArgs.payout_per)) {
      toast.error("Please enter valid numeric values for Lead ID and Payout Percentage.");
      return;
    }

    try {
      const result = await executeGraphQLQuery(SAVE_LEAD_SCHEME, {
        addSchemeArgs,
      });

      const response = result?.data?.save_lead_scheme;

      if (response?.msg) {
        toast.success(response.msg);
        setOpenModal(false);
      } else {
        toast.error("Failed to save the lead scheme.");
      }
    } catch (error) {
      console.error("Error saving lead scheme:", error);
      toast.error("Error saving lead scheme.");
    }
  };

  const resetFilterForm = () => {
    window.location.reload();
  };

  const closeModal = () => {
    window.location.reload();
  }

  const toggleModal = () => {
    setOpenModal((prev) => !prev);
    document.body.classList.toggle("overflow-hidden");
  };

  const validateAbsolutePayout = (value, field) => {
    const errors = { ...validationErrors };
    // Validate payout_percentage
    if (field === "payout_percentage") {
      if (isNaN(value)) {
        errors["payout_percentage"] = "The value must be a number.";
      } else if (  filter_params["payout_type"] =="standard" && parseFloat(value) > 2.5) {
        errors["payout_percentage"] = "The value must be less than or equal to 2.5.";
      }  else if (  filter_params["payout_type"] =="payin" && parseFloat(value) > 95) {
        errors["payout_percentage"] = "The value must be less than or equal to 95.";
      } else {
        delete errors["payout_percentage"];
      }
    }
  
    // Validate payout_per_as_payin
    if (field === "payout_per_as_payin") {
      if (isNaN(value)) {
        errors["payout_per_as_payin"] = "The value must be a number.";
      } else if (parseFloat(value) > 95) {
        errors["payout_per_as_payin"] = "The value must be less than or equal to 95.";
      } else {
        delete errors["payout_per_as_payin"];
      }
    }
  
    // Set the validation state
    if (Object.keys(errors).length) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  
    setValidationErrors(errors);
  };

  const handlePaginationChange = (pageNumber) => {
    fetchItems(filter_params["filter_params"], filter_params["app_id"], pageNumber);
};

  return (
    <div className="container-fluid">
      <h1 className="top-heading" style={{ marginLeft: 10 }}>
        Leadwise Scheme
      </h1>
      <div className="search-wrap">
        <div className="row">
          <div className="col-sm-2 col-md-2">
            <label>Search By</label>
            <Select
              id="filter_params"
              options={filterOption}
              name="filter_params"
              onChange={(data) => handleSelectArray("filter_params", data)}
            />
          </div>
          <div className="col-sm-2 col-md-2">
            <InputField
              inputProps={{
                id: "app_id",
                type: "text",
                name: "app_id",
                autocompleate: "off",
                label: "Search Value",
                placeholder: "Search By",
                value: filter_params["app_id"]
              }}
              onChange={handleInputArray}
            />
          </div>
          <div className="col-sm-2 col-md-2" style={{ marginTop: 18 }}>
            <div className="btn-submit-reset top-btn-none pull-right">
              <button className="btn btn-primary mrg-r10" onClick={() => fetchItems(filter_params["filter_params"], filter_params["app_id"])}>
                Search
              </button>
              <button className="btn-reset" onClick={resetFilterForm}>
                Reset
              </button>
            </div>
          </div>
          <div className='col-sm-4 col-md4'></div>
          <div className="col-sm-2 col-md-2" style={{ marginTop: 18 }}>
            <div className="btn-submit-reset top-btn-none pull-right">
              <button
                className="newleadcreate"
                onClick={toggleModal}
              >
                Create New Lead Scheme
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mark-lost-popup" style={{width:"auto"}}>
        <Modal show={openModal} handleClose={closeModal} width="565px" removeHeight>
          <div className="row">
            <div className="col-sm-10">
              <InputField
                inputProps={{
                  id: "lead_id",
                  type: "text",
                  name: "lead_id",
                  autoComplete: "off",
                  label: "Lead ID",
                  placeholder: "Lead ID",
                  value: filter_params["lead_id"]
                }}
                onChange={handleInputArray}
              />
            </div>
            <div className="col-sm-10" style={{marginBottom:20}}>
              <div>Payout Type</div>
            <Select
              id="payout_type"
              options={payoutTypeOption}
              name="payout_type"
              onChange={(data) => handleSelectArray("payout_type", data)}
            />
            </div>
           
            <div className="col-sm-10">
                <InputField
                  inputProps={{
                    id: "payout_percentage",
                    type: "text",
                    name: "payout_percentage",
                    autoComplete: "off",
                    label: "Payout %",
                    placeholder: "Payout percentage",
                    max: "2.5",
                    step: "0.01",
                  }}
                  value={filter_params["payout_percentage"]}
                  onChange={(e) => {
                    handleInputArray(e);  // Updates filter_params state
                    validateAbsolutePayout(e.target.value, "payout_percentage");  // Validate the specific field
                  }}
                />              
                {validationErrors["payout_percentage"] && (
                  <span className="error-message" style={{ color: "red",position:"absolute",marginTop:"-20px" }}>
                    {validationErrors["payout_percentage"]}
                  </span>
                )}
            </div>
            {filter_params["payout_type"] === "standard" && (
            <div className="col-sm-10" style={{marginBottom:20}}>
            <div>Calculated On</div>
          <Select
            id="payout_calculation"
            options={payoutCalculatedOnOptions}
            name="payout_calculation"
            onChange={(data) => handleSelectArray("payout_calculation", data)}
          />          
            </div>
             )}
            <div className="col-sm-10">
              <InputField
                inputProps={{
                  id: "reason",
                  type: "text",
                  name: "reason",
                  autoComplete: "off",
                  label: "Reason",
                  placeholder: "Reason",
                }}
                value={filter_params["reason"]}
                onChange={handleInputArray}
              />
            </div>
          </div>
          <div>
            <button className="btn-primary" onClick={handleFormSubmit} disabled={isSubmitDisabled}>
              Submit
            </button>
          </div>
        </Modal>
      </div>
      <LeadWiseListing items={items} paginationdata={paginationdata} onPagination = {handlePaginationChange} />
    </div>
  );
};

export default LeadwiseScheme;
