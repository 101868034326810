import { gql } from "@apollo/client";

export const GET_BRE_OFFERS = gql`
    mutation get_bre_offers($breOfferInput:BreOfferInput!){
        get_bre_offers(breOfferInput:$breOfferInput)
    }
`;

export const GET_BRE_MITIGANTS = gql`
    mutation get_bre_mitigants($breMitigantsInput:BreMitigantsInput!){
        get_bre_mitigants(breMitigantsInput:$breMitigantsInput)
    }
`;

export const CREATE_MITIGANT_LOGS = gql`
    mutation create_mitigant_logs($breMitigantsLogsInput:MitigantLogsListInput!){
        create_mitigant_logs(breMitigantsLogsInput:$breMitigantsLogsInput)
    }
`;

export const SAVE_OFFER_ITR_DETAILS = gql`
    mutation save_offer_itr_details($offerItrDetails:OfferITRDetailsListInput!){
        save_offer_itr_details(offerItrDetails:$offerItrDetails)
    }
`;

export const SAVE_OFFER_BANK_DETAILS = gql`
    mutation save_offer_bank_details($offerBankDetails:OfferBankDetailsListInput!){
        save_offer_bank_details(offerBankDetails:$offerBankDetails)
    }
`;

export const GET_BUILDER_LIST = gql`
    mutation get_builder_name($builderParams:BuilderListInput!){
        get_builder_name(builderParams:$builderParams)
    }
`;

export const GET_PROJECT_LIST = gql`
    mutation get_project_name($projectParams:BuilderListInput!){
        get_project_name(projectParams:$projectParams)
    }
`;