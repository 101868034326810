import React, { useEffect, useState } from "react";
import Select from 'react-select';
import MasterService from "../../services/MasterService";
import { NavLink, Link, useParams, Navigate } from 'react-router-dom';
import InputField from "../elements/InputField";
import { executeGraphQLMutation } from "../../common/partnerExecuteGraphQLQuery";
import { toast } from 'react-toastify';
import secureStorage from '../../config/encrypt';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Add_PARTNER_SCHEME, DELETE_SCHEME_PATNER, SCHEME_PARTNER_LISTING } from "../../services/scheme.gql";
import Pagination from "../elements/pagination";
import {IS_USER_ACCESS_PAGE} from '../../config/constants';
import Modal from "../elements/Modal";
import BulkSubscriptionsPartnerList from "./BulkSubscriptionsPartnerList";


const PartnerListing = (props) => {

    const [filterData, setFilterData] = useState({partner_status:0, page_no:1, search_by:"", search_text:"", is_subscribed:0,scheme_id:props.schemedetailsData?.id});
    const [totalPage, setTotalPage] = useState(0);
    const [paginationdata, setPaginations] = useState({});
    const [pageloading, setpageloading] = useState(true)
    const [partnerListing, setpartnerListing] = useState([])
    const [dealerCountStatusDetail, setDealerCountStatusDetail] = useState({});
    const [bulkopen,setBulkOpen] = useState(false)


    const BulkModalOpen = () =>{
        setBulkOpen(true)
        document.body.classList.add('overflow-hidden')
    }
    const BulkModalClose = () =>{
        setBulkOpen(false)
        document.body.classList.remove('overflow-hidden')
        window.location.reload();
    }
    
    let scheme_view =  IS_USER_ACCESS_PAGE("payout", "scheme_view")?.is_edit_access;

    const submitFilterForm = (event) => {
        event.preventDefault();
        getPartnerListWithFilters(null, filterData);
        getDealerCountStatusDetail(filterData);
    };   

    const getDealerCountStatusDetail = (filterData) => {

        MasterService.post('dealer/dealer/count_dealer_status', { ...filterData })
            .then(function (response) {
                if (response.status === 200 && response.data.status === 200) {
                    setDealerCountStatusDetail(response.data.data || {});
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    const getPartnerListWithFilters = async (page_no, filterData) => {
        setpageloading(true)

        try {
            const result = await executeGraphQLMutation(SCHEME_PARTNER_LISTING(filterData.scheme_id , filterData.search_by  , filterData.search_text, filterData.partner_status , page_no ? page_no : 1 , filterData.is_subscribed));
            if (result.data.partners.length == 0) {
                setpartnerListing([]);
                setPaginations({});
                setpageloading(false)

            }
            if (result) {
                if (result.data.partners && result.data.partners.length > 0) {
                    const partners = result.data.partners[0].dealers;
                    setpartnerListing(partners);
                    setPaginations(result.data.partners[0].pagination);
                    setTotalPage(result.data.partners[0].pagination.totalpage);
                    setpageloading(false)
                }
            } else {
                setpartnerListing([]);
                setPaginations({});
                setpageloading(false);
            }
        } catch (error) {
            toast.error("Failed");
        }
    }

    useEffect(() => {
        getPartnerListWithFilters(null, filterData)
    }, [filterData.is_subscribed]);

    const getSubscribedPartners = (e, key = "") => {
         setFilterData({ ...filterData, ["is_subscribed"]: 1 })
    }

    const resetFilterForm = (event) => {
        setFilterData({partner_status:0, page_no:1, search_by:"", search_text:"", is_subscribed:0,scheme_id:props.schemedetailsData?.id});
        getPartnerListWithFilters(null, {partner_status:0, page_no:1, search_by:"", search_text:"", is_subscribed:0,scheme_id:props.schemedetailsData?.id});
        getDealerCountStatusDetail({partner_status:0, page_no:1, search_by:"", search_text:"", is_subscribed:0,scheme_id:props.schemedetailsData?.id});
    };

    const handleInputarray = (e) => {
        const filterDatanew = { ...filterData }
        const name = e.target.name;
        const val = e.target.value;
        filterDatanew[name] = val;
        setFilterData(filterDatanew);
    };

    let role;
    if (secureStorage.getItem('loginUserInfo')) {
        let loginResponse = secureStorage.getItem('loginUserInfo');
        if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
            role = loginResponse.data.user_data.role;
        }
    }


    const PartnerSubscribe = async (partnerId, event) => {
        event.preventDefault();
        const variables = {
            schemePartnerInputArgs: {
                scheme_id: filterData.scheme_id,
                partner_id: partnerId,
                status: 1,
            },
        };
        try {
            const result = await executeGraphQLMutation(Add_PARTNER_SCHEME, variables);
            if (result?.data?.saveSchemePartner?.status == 'true') {
                toast.success(result?.data?.saveSchemePartner?.msg || "Subscribed Successfully ");
                // add slight delay to state updates and component re-renders
                setTimeout(() => {
                    getPartnerListWithFilters(filterData.page_no, filterData);
                }, 100);
            }else{
                toast.error(result?.data?.saveSchemePartner?.msg || "Something went wrong! "); 
            }
        } catch (error) {
            toast.error("Failed");
        }
    }

    const PartnerUnSubscribe = async (partnerId, event) => {
        event.preventDefault();
        if (window.confirm("Do you want to un-subscribe this partner!")) {
            const data = {}
            const variables = { id: partnerId };
            data.deleteSchemePartne = variables;
            try {
                const result = await executeGraphQLMutation(DELETE_SCHEME_PATNER, variables);
                if (result) {
                    toast.success(result?.data?.deleteSchemePartner?.message || "UnSubscribed Successfully");
                    // add slight delay to state updates and component re-renders
                    setTimeout(() => {
                        getPartnerListWithFilters(filterData.page_no, filterData);
                    }, 100);
                }
            } catch (error) {
                toast.error("Failed");
                console.error(error);
            }
        } else {
            return false;
        }


    }


    const searchByList = [
        { value: 'agent_code', label: 'Partner Code' },
        { value: 'partner_name', label: 'Partner Name' },
        { value: 'dealership_contact', label: 'Contact No' },
        { value: 'dealership_email', label: 'Partner Email' },
    ];

    const onPaginate = (pageNumber) => {
        setFilterData({ ...filterData, page_no: pageNumber });
        getPartnerListWithFilters(pageNumber, filterData);
    };

    const handleSearchBy = (name, selectedOptions) => {
        const formDataNew = { ...filterData }
        const search_by_Array = []
        search_by_Array.push({ value: selectedOptions.value })
        formDataNew['search_by'] = selectedOptions.value
        formDataNew['search_by_Array'] = search_by_Array
        setFilterData(formDataNew);
    };

    return (
        <>
            <div>
                <h2 className="top-heading">Partner Listing</h2>
                <div className="search-wrap">
                    <form onSubmit={submitFilterForm}>
                        <div className="row">
                            <div className="col-sm-1 col-md-2">
                            <label>Search By</label>
                            <Select
                                id="search_by"
                                options={searchByList} 
                                onChange={(e) => handleSearchBy('search_by', e)}
                                name="search_by"
                                value={searchByList.find(option => option.value === filterData.search_by) || {}}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                                />
                            </div>
                            <div className="col-sm-1 col-md-2">
                                <InputField
                                    inputProps={{
                                        id: "search_text",
                                        type: "text",
                                        name: "search_text",
                                        autocompleate: "off",
                                        label: 'Enter Keyword',
                                        value: filterData.search_text,
                                    }}
                                    onChange={handleInputarray}
                                />
                            </div>

                            <div className="col-sm-2 col-md-2">
                                <label></label>
                                <div style={{ marginTop: 18 }}>
                                    <div className="btn-submit-reset top-btn-none pull-right">
                                        <button type="submit" className="btn btn-primary mrg-r10">{('Search')}</button>
                                        <button type="reset" className="btn-reset" onClick={resetFilterForm}>{('Reset')}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-4"></div>
                            <div className="col-sm-2 col-md-1">  <div className="btn btn-primary top-btn-none pull-right"> <button type="submit" className="btn btn-primary mrg-t10" onClick={BulkModalOpen}>Bulk Partner Subscribed</button></div>  </div>
                            <div className="col-sm-2 col-md-1">  <div className="btn-submit-reset top-btn-none pull-right"> <button type="submit" className="btn btn-primary mrg-t10" onClick={getSubscribedPartners}>Subscribed</button></div>  </div>

                        </div>
                    </form>
                </div>
                <div className="view-upload-mis-popup">
                <Modal show={bulkopen} handleClose={BulkModalClose} width={950}> 
                    <BulkSubscriptionsPartnerList modalClose={BulkModalClose} scheme_id={filterData.scheme_id} partner_subscribed_id = {partnerListing.partner_subscribed_id}/>
                </Modal>
                </div>
              

                <div className={(pageloading) ? "clearfix container-fluid loading" : "clearfix container-flui"}>
                    <div className="table-responsive" width={100}>
                        <table className="table table-bordered tablefontsize">
                            <thead>
                                <tr>
                                    <th>{('Partner Code')}</th>
                                    <th>{('Partner Name')}</th>
                                    <th>{('Company')}</th>
                                    <th>{('Profession')}</th>
                                    <th>{('City')}</th>
                                    {/* <th>{('Product Type')}</th> */}
                                    <th>{('Partner Status')}</th>
                                    <th>{('Subscribed Status ')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {partnerListing &&
                                    partnerListing.map((partner, index) => (
                                        <React.Fragment key={index}>

                                            <tr key={partner.id}>
                                                <td>{partner.agent_code}</td>
                                                <td>{partner.first_name}</td>
                                                <td>{partner.organization}</td>
                                                <td>{partner.profession_name}</td>
                                                <td>{partner.cityname}</td>
                                                <td>{partner.status == '4' ? "Pending" : partner.status == '1' ? "Active" : partner.status == '2' ? "Blocked" : "Deleted"}</td>
                                                <td>
                                                    {scheme_view === 1 && (
                                                        partner.partner_subscribed_id ? (
                                                            <button
                                                                className="partnerunsubscribed"
                                                                onClick={(event) => PartnerUnSubscribe(partner.partner_subscribed_id, event)}
                                                            >
                                                                Unsubscribe
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="partnersubscribed"
                                                                onClick={(event) => PartnerSubscribe(partner.id, event)}
                                                            >
                                                                Subscribe
                                                            </button>
                                                        )
                                                    )}


                                                </td>

                                            </tr>


                                        </React.Fragment>
                                    ))}
                                {partnerListing.length === 0 && (
                                    <tr>
                                        <td colSpan={7}>No payout found!</td>
                                    </tr>
                                )}

                                {partnerListing.length > 0 && (
                                    <tr>
                                        <td colSpan={6}> <Pagination pageCount={totalPage} activePage={filterData.page_no ?filterData.page_no:1 } onPaginate={onPaginate} /></td>
                                        <td>Total: {paginationdata && paginationdata.totalrecords ? paginationdata.totalrecords:0} </td>
                                    </tr>
                                )}

                            </tbody>


                        </table>

                    </div>

                </div>
            </div>


        </>
    )
};

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('ac_manager')(connect(mapStateToProps, mapDispatchToProps)(PartnerListing));
