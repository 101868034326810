import React from "react";
import Pagination from "../elements/pagination";

const formatText = (text) => {
  if (!text) return '';
  return text
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

const LeadWiseListing = ({ items = [], paginationdata, onPagination }) => {
  const onPaginate = (page_no) => {
    onPagination(page_no);
  };

  return (
    <>
      <div className="table-responsive" width={100}>
        <table className="table table-bordered tablefontsize">
          <thead>
            <tr>
              <th>{'Lead ID'}</th>
              <th>{'Payout Type'}</th>
              <th>{'Payout Percentage'}</th>
              <th>{'Calculated On'}</th>
              <th>{'Reason'}</th>
            </tr>
          </thead>
          <tbody>
            {items.length > 0 ? (
              <>
                {items.map((item, itemIndex) => (
                  item.schemefinacergroup && Array.isArray(item.schemefinacergroup) ? (
                    item.schemefinacergroup.map((financerGroup, index) => (
                      <tr key={`${item.id}-${index}`}>
                        <td>{item.lead_id}</td>
                        <td>{financerGroup.is_on_payin === 0 ? "Absolute Payout" : "Payout as % Payin"}</td>
                        <td>{financerGroup.schemerange[0]?.payout || 'N/A'}</td>
                        <td>{formatText(financerGroup.slab_amount)}</td>
                        <td>{item.remarks}</td>
                      </tr>
                    ))
                  ) : null
                ))}
                <tr>
                  <td colSpan={4}>
                    <Pagination 
                      pageCount={paginationdata.totalpage} 
                      activePage={paginationdata.page_no ? paginationdata.page_no : 1} 
                      onPaginate={onPaginate} 
                    />
                  </td>
                  <td>Total: {paginationdata && paginationdata.totalrecords ? paginationdata.totalrecords : 0} </td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan="5">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LeadWiseListing;
