import { useApolloClient } from "@apollo/client";
import Formsy from "formsy-react";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { executeGraphQLMutation } from "../../../common/executeGraphQLQuery";
import { GET_BUILDER_LIST, GET_PROJECT_LIST } from "../../../services/bre.gql";
import generalService from "../../../services/generalService";
import LeadStatusService from "../../../services/leadStatus";
import style from "../../../style/leadStatus/leadStatus.module.scss";
import FormsyInputField from "../../elements/FormsyInputField";
import FormsySelect from "../../elements/FormsySelect";
import { DDA_PARTNER_IDS } from "../../../config/constants";


const SaveAndQualify = ({ PropertyIdentifiedForm, PropertyIdentifiedFormFirstPart, PropertyIdentifiedFormSecondPart, updateSetFormDetails, formDetails, handleSaveButton, errorMessageList, isLoading }) => {

    const [isPropertyIdentified, setIsPropertyIdentified] = useState(false);
    const [isPropertyQualified, setIsPropertyQualified] = useState(false);
    const [leadPropertyDetails, setLeadPropertyDetails] = useState({});
    const [qualifyError, setQualifyError] = useState(null);
    const [cityList, setCityList] = useState([]);

    const client = useApolloClient();
    const dispatch = useDispatch();
    const location = useLocation();

    const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({ masterdata, lead }));
    const SUB_PROPERTY_MAPPING_OBJ = {
        9: [1, 2, 3],
        11: [1, 4],
        12: [1, 4],
        13: [1, 2, 3, 4],
        14: [1, 2, 3, 4],
    }
    const specialPermissionIDS=DDA_PARTNER_IDS
    // const specialPermissionIDS=[13583, 12164, 15383, 14988]
    const ALL_OPTIONS = useMemo(() => {
        let options = masterdata ? { ...masterdata.data } : {};
        const existingPropertyType = formDetails["property_type"] || null;
        options["property_sub_type"] = options["property_sub_type"]?.map((obj) => ({
            ...obj,
            value: +obj.id,
        }));
        if (existingPropertyType) {
            const filteredSubType = options["property_sub_type"]?.filter((obj) =>
                SUB_PROPERTY_MAPPING_OBJ[existingPropertyType].includes(obj.value)
            );
            options["filtered_property_sub_type"] = filteredSubType;
        }
        if (options?.state_list) {
            options.property_state = options.state_list;
        }
        return options;
    }, []);

    const getBuilderList = async (key_name, value) => {
        try {
            let cityId = null, builderId = null;
            if (key_name === "builder") {
                cityId = +value;
                builderId = null;
            } else if (key_name === "project") {
                cityId = +formDetails["property_city"] || null;
                builderId = +value;
            }
            const variables = {
                [`${key_name}Params`]: {
                    ...(cityId ? { cityId } : {}),
                    ...(builderId ? { builderId } : {}),
                },
            };
            const mutation = key_name === "builder" ? GET_BUILDER_LIST : GET_PROJECT_LIST;
            const resp = await executeGraphQLMutation(mutation, variables, client);
            let optionList = [];
            if (resp.data?.[`get_${key_name}_name`]?.data) {
                const responseData = resp.data?.[`get_${key_name}_name`];
                optionList = responseData.data?.map((obj) => {
                    return {
                        id: obj[`${key_name}_id`],
                        value: obj[`${key_name}_id`],
                        label: obj[`${key_name}_name`],
                    };
                });
                return optionList;
            } else {
                toast.error(resp.data?.[`get_${key_name}_name`]?.message);
            }
        } catch (error) {
            console.log(`🔻 ERROR : ${error.message}`);
            toast.error("Internal server error");
            return [];
        }
    };

    const setBuilderAndProjectList = async (key_name, value) => {
        if (lead?.leadDetail?.lead_details?.is_property_identified === "yes" && location.pathname.indexOf("property-identified") > -1) {
            const list = await getBuilderList(key_name, value);
            ALL_OPTIONS[`${key_name}_name`] = list;
        }
    };

    const handleCheckboxChange = (event) => {
        if (event?.target?.name === 'is_property_identified') {
            setIsPropertyIdentified(event?.target?.checked ? 'yes' : 'no');
            const dataSet = {
                is_property_identified: event?.target?.checked ? 'yes' : 'no'
            }
            if (event?.target?.checked) {

            } else {

            }
            updateSetFormDetails('bulkUpdate', dataSet);
        }
    };

    const handleValueChange = async (data, field_name) => {
        const val = typeof data === 'object' ? (data?.value || data?.target?.value) : null;
        if (field_name && val) {
            updateSetFormDetails(field_name, val);
        }

        if (field_name == "property_pincode") {
            if (parseInt(val) > 100000 && parseInt(val) < 1000000 && val.length === 6) {
                await setCityStateByPin(val);
            } else {
                ALL_OPTIONS.property_state = []
                ALL_OPTIONS.property_city = []
                await updateSetFormDetails('bulkUpdate', { "property_pincode": null, "property_state": null, "property_city": null });
            }
        }

        if (field_name == 'property_pincode' && parseInt(val) > 100000 && parseInt(val) < 1000000 && val.length === 6) {
            setCityStateByPin(val)
        }
    }

    const setCityStateByPin = async (pincode) => {
        try {
            const response = await generalService.stateCityByPincode(pincode);
            if (response?.data?.data?.length) {
                const item = response.data.data[0];
                await handleSelectChange(
                    {
                        id: item.state_id,
                        name: item.state_name,
                        value: item.state_id,
                        label: item.state_name,
                    },
                    "property_state"
                );
                await updateSetFormDetails('bulkUpdate', { "property_pincode": pincode, "property_state": item.state_id, "property_city": item.city_id });
            }
        } catch (error) {
            await updateSetFormDetails('bulkUpdate', { "property_pincode": null, "property_state": null, "property_city": null });
        }
    };

    const handleSelectChange = async (data, field_name) => {
        if (data && field_name) {
            if (field_name === "is_property_identified") {
                setIsPropertyIdentified(data.value == '1' ? 'yes' : 'no');
            }
            if (field_name === "property_state") {
                updateSetFormDetails('property_city', null);
                if (data.value) {
                    const options = masterdata ? { ...masterdata.data } : {};
                    if (options?.city && options?.city?.length) {
                        const record = options.city
                            .filter((ob) => ob.state_id == data.value)
                            .map((data) => {
                                data.value = data.id;
                                data.label = data.label;
                                return data;
                            });
                        ALL_OPTIONS.property_city = record.length ? [...record] : []
                        setCityList(record);
                    }
                }
            }
            if (field_name === "property_type") {
                updateSetFormDetails('property_sub_type', '');
                const filteredSubType = ALL_OPTIONS["property_sub_type"].filter(obj => SUB_PROPERTY_MAPPING_OBJ[data.value].includes(obj.value));
                ALL_OPTIONS["filtered_property_sub_type"] = filteredSubType;
            }
            if (field_name === "property_city") {
                const list = await getBuilderList("builder", data.value);
                ALL_OPTIONS["builder_name"] = list;
                if (data.value) {
                    setBuilderAndProjectList("builder", data.value);
                }
            }
            if (field_name === "builder_name_id") {
                const list = await getBuilderList("project", data.value);
                ALL_OPTIONS["project_name"] = list;
            }
            handleValueChange(data, field_name)
        }
    }

    const setLeadDetailsIntoFormObj = async () => {
        if (lead && lead.leadDetail && lead.leadDetail.lead_details) {
            const property_identified = { ...lead.leadDetail.lead_details };

            const addInFormDetails = {
                is_property_identified: property_identified["is_property_identified"] || 'no',
                property_value: property_identified?.property_value || null,
                property_type: property_identified?.property_type || null,
                usage_type: +property_identified?.usage_type || null,
                agreement_type: +property_identified?.agreement_type || null,
                property_address1: property_identified?.property_address1 || null,
                property_address2: property_identified?.property_address2 || null,
                property_pincode: property_identified?.property_pincode || null,
                property_city: property_identified?.property_city || null,
                property_state: property_identified?.property_state || null,
                expected_property_value: property_identified?.expected_property_value || null,
                property_agreement_value: property_identified?.property_agreement_value || null,
                property_sub_type: property_identified?.property_sub_type || null,
                loan_amount: lead?.leadDetail?.lead_details?.loan_amount ? lead.leadDetail.lead_details.loan_amount : null
            }
            await updateSetFormDetails('bulkUpdate', addInFormDetails)
        }
    }

    useEffect(() => {
        if (lead && lead.leadDetail && lead.leadDetail.lead_details) {
            let property_identified = { ...lead.leadDetail.lead_details };
            // setIsPropertyIdentified(property_identified);
            setIsPropertyIdentified(property_identified["is_property_identified"] === 'yes' ? 'yes' : 'no');
            const filteredSubType = ALL_OPTIONS["property_sub_type"]?.filter(obj => SUB_PROPERTY_MAPPING_OBJ[property_identified["property_type"]]?.includes(obj.value));
            ALL_OPTIONS["filtered_property_sub_type"] = filteredSubType;

            if (property_identified.property_city) {
                setBuilderAndProjectList("builder", property_identified.property_city);
            }
            if (property_identified.builder_name_id) {
                setBuilderAndProjectList("project", property_identified.builder_name_id);
            }
            setLeadDetailsIntoFormObj();
        }
    }, [lead]);

    useEffect(() => {
        const setIsPropertyQualifiedValue = async () => {
            if (formDetails?.is_property_identified === 'yes' && formDetails?.agreement_type && formDetails?.agreement_type != '4' && formDetails?.loan_amount >= 2000000 && [67, 69, 188, 196, 327, 1630, 328, 7, 99].includes(formDetails?.property_city)) {
                setIsPropertyQualified(true);
                updateSetFormDetails("save_button", 'qualify');
            }else if(formDetails?.loan_amount>0 && lead?.leadDetail?.partner_id && specialPermissionIDS.includes(lead?.leadDetail?.partner_id) ){
                setIsPropertyQualified(true);
                updateSetFormDetails("save_button", 'special_permission');
            } else {
                setIsPropertyQualified(false);
                updateSetFormDetails("save_button", null);
                if (!(formDetails?.is_property_identified === 'yes')) {
                    setQualifyError('Property not identified for Qualification')
                } else if (!(formDetails?.loan_amount >= 2000000)) {
                    setQualifyError('Property loan amount should be greater than 20L for Qualification')
                } else if (!([67, 69, 188, 196, 327, 1630, 328, 7, 99].includes(formDetails?.property_city))) {
                    setQualifyError('Property city not allowed for Qualification')
                }
                if (!formDetails?.agreement_type || formDetails?.agreement_type == "") {
                    setQualifyError('Property type is required for Qualification')
                } else if (!(formDetails?.agreement_type != '4')) {
                    setQualifyError('Property type GPA not allowed for Qualification')
                } 
            }
        }
        setIsPropertyQualifiedValue();
    }, [formDetails?.is_property_identified, formDetails?.agreement_type, formDetails?.loan_amount, formDetails?.property_city]);

    const handlePatternFormatChange = (data, field_name) => {
        if (field_name && data.value !== undefined) {
            updateSetFormDetails(field_name, data.value)
        }
    }

    return (
        <Formsy className="lead-form-filed" autoComplete="off">
            <h4>Confirm Details</h4>

            <div className="row">
                {/* <div>{JSON.stringify(formDetails, null, 2)}</div> */}
                <fieldset className="form-filed col-md-4">
                    <FormsyInputField
                        id={"loan_amount"}
                        name={"loan_amount"}
                        type={"number-format"}
                        value={formDetails?.loan_amount || ''}
                        placeholder=" "
                        label={"Required Loan Amount*"}
                        thousandsGroupStyle="lakh"
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        onChange={(data) => handlePatternFormatChange(data, 'loan_amount')}
                    />
                    <div className="error-msg">{errorMessageList?.loan_amount}</div>
                </fieldset>
            </div>

            <h4>Property Details</h4>
            <div className="property-identified">
                <label>Property Identified ?</label>
                <label className="toggle-switch">
                    <input
                        type="checkbox"
                        name="is_property_identified"
                        id="is_property_identified"
                        checked={isPropertyIdentified === "yes"}
                        onChange={handleCheckboxChange}
                    />
                    <span className="slider"></span>
                </label>
            </div>
            {!isPropertyIdentified && (
                <div className="statusqualify">Please fill in the property details to qualify the lead status.</div>
            )}
            <div className="row">
                {PropertyIdentifiedForm.map((field, index) => {
                    // Condition to check if the field is part of the first part of the form
                    if (PropertyIdentifiedFormFirstPart.includes(field.id)) {

                        // Check for conditions where the field should not be rendered
                        const shouldRenderField =
                            (isPropertyIdentified === "" || isPropertyIdentified === null) && field.name !== "is_property_identified" ||
                            (isPropertyIdentified === "no" && field.id === "property_aggrement_value") ||
                            (isPropertyIdentified === "yes" && field.id === "expected_property_value") ||
                            (isPropertyIdentified === "no" && field.id === "property_value") ||
                            (formDetails["property_type"] === 9 && field.name === "project_name") ||
                            (formDetails["property_type"] === 11 && field.name === "project_name") ||
                            (formDetails["property_type"] === null && field.name === "project_name") ||
                            (isPropertyIdentified === "no" && field.id === "project_name") ||
                            (formDetails["property_type"] === 9 && field.name === "builder_name_id") ||
                            (formDetails["property_type"] === 11 && field.name === "builder_name_id") ||
                            (formDetails["property_type"] === null && field.name === "builder_name_id") ||
                            (isPropertyIdentified === "no" && field.id === "builder_name_id") ||
                            (isPropertyIdentified === "no" && field.id === "property_sub_type") ||
                            (isPropertyIdentified === "no" && field.id === "property_address1") ||
                            (isPropertyIdentified === "no" && field.id === "property_address2");

                        // If the field should not be rendered, return null
                        if (shouldRenderField) {
                            return null;
                        }

                        // Render the field based on its type
                        if (["text", "password", "pattern-format", "number-format", "number"].includes(field.type)) {
                            return (
                                <fieldset className="form-filed col-md-4" key={index}>
                                    <FormsyInputField
                                        id={field.id}
                                        name={field.name}
                                        type={field.type}
                                        value={formDetails ? formDetails[field.name] : ''}
                                        placeholder=" "
                                        thousandsGroupStyle="lakh"
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        label={field.label}
                                        maxLength={field.maxLength}
                                        onChange={(data) => handleValueChange(data, field.name)}
                                        format={field.ApplyNumberformat ? field.format : ""}
                                        // validations={formDetails[field.name] ? field.validations : null}
                                        // validationError={formDetails[field.name] || formDetails[field.name] === ''
                                        //     ? formDetails[field.name]
                                        //         ? field.validationError
                                        //         : ""
                                        //     : ""}
                                        required={!!field?.required}
                                    />
                                    {!!(errorMessageList && errorMessageList.hasOwnProperty(`${field.name}`)) && (<div className="error-msg">{errorMessageList[field.name]}</div>)}
                                </fieldset>
                            );
                        } else if (field.type === "dropdown") {
                            return (
                                <fieldset className="single-select col-md-4" key={index}>
                                    <FormsySelect
                                        name={field.name}
                                        id={field.id}
                                        inputProps={{
                                            options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                                            placeholder: field.label,
                                            className: "react-select",
                                            classNamePrefix: "react-select",
                                            value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey]
                                                ? ALL_OPTIONS[field.optionsKey].filter(({ value }) => value === formDetails[field.name])
                                                : [],
                                            isDisabled: field.readOnly,
                                        }}
                                        required={!!field?.required}
                                        value={formDetails[field.name]}
                                        onChange={(data) => handleSelectChange(data, field.name)}
                                    />
                                    {!!(errorMessageList && errorMessageList.hasOwnProperty(`${field.name}`)) && (<div className="error-msg">{errorMessageList[field.name]}</div>)}
                                </fieldset>
                            );
                        }
                    }
                    return null; // Return null if no condition matched for rendering
                })}
            </div>

            <h4>Address Details</h4>
            <div className="row">
                {PropertyIdentifiedForm.map((field, index) => {
                    // Condition to check if the field is part of the first part of the form
                    if (PropertyIdentifiedFormSecondPart.includes(field.id)) {

                        // Check for conditions where the field should not be rendered
                        const shouldRenderField =
                            (isPropertyIdentified === "" || isPropertyIdentified === null) && field.name !== "is_property_identified" ||
                            (isPropertyIdentified === "no" && field.id === "property_aggrement_value") ||
                            (isPropertyIdentified === "yes" && field.id === "expected_property_value") ||
                            (isPropertyIdentified === "no" && field.id === "property_value") ||
                            (formDetails["property_type"] === 9 && field.name === "project_name") ||
                            (formDetails["property_type"] === 11 && field.name === "project_name") ||
                            (formDetails["property_type"] === null && field.name === "project_name") ||
                            (isPropertyIdentified === "no" && field.id === "project_name") ||
                            (formDetails["property_type"] === 9 && field.name === "builder_name_id") ||
                            (formDetails["property_type"] === 11 && field.name === "builder_name_id") ||
                            (formDetails["property_type"] === null && field.name === "builder_name_id") ||
                            (isPropertyIdentified === "no" && field.id === "builder_name_id") ||
                            (isPropertyIdentified === "no" && field.id === "property_sub_type") ||
                            (isPropertyIdentified === "no" && field.id === "property_address1") ||
                            (isPropertyIdentified === "no" && field.id === "property_address2");

                        // If the field should not be rendered, return null
                        if (shouldRenderField) {
                            return null;
                        }

                        // Render the field based on its type
                        if (["text", "password", "pattern-format", "number-format", "number"].includes(field.type)) {
                            return (
                                <fieldset className="form-filed col-md-4" key={index}>
                                    <FormsyInputField
                                        id={field.id}
                                        name={field.name}
                                        type={field.type}
                                        value={formDetails ? formDetails[field.name] : ''}
                                        placeholder=" "
                                        thousandsGroupStyle="lakh"
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        label={field.label}
                                        maxLength={field.maxLength}
                                        onChange={(data) => handleValueChange(data, field.name)}
                                        format={field.ApplyNumberformat ? field.format : ""}
                                        // validations={formDetails[field.name] ? field.validations : null}
                                        // validationError={formDetails[field.name] || formDetails[field.name] === ''
                                        //     ? formDetails[field.name]
                                        //         ? field.validationError
                                        //         : ""
                                        //     : ""}
                                        required={!!field?.required}
                                    />
                                    {!!(errorMessageList && errorMessageList.hasOwnProperty(`${field.name}`)) && (<div className="error-msg">{errorMessageList[field.name]}</div>)}
                                </fieldset>
                            );
                        } else if (field.type === "dropdown") {
                            const dropDownOption = field.name === 'property_city' && cityList.length ? cityList : ALL_OPTIONS && ALL_OPTIONS[field.optionsKey];
                            return (
                                <fieldset className="single-select col-md-4" key={index}>
                                    <FormsySelect
                                        name={field.name}
                                        id={field.id}
                                        inputProps={{
                                            options: dropDownOption,
                                            placeholder: field.label,
                                            className: "react-select",
                                            classNamePrefix: "react-select",
                                            value: dropDownOption && dropDownOption.length
                                                ? dropDownOption.filter(({ value }) => value === formDetails[field.name])
                                                : [],
                                            isDisabled: field.readOnly,
                                        }}
                                        required={!!field?.required}
                                        value={formDetails[field.name]}
                                        onChange={(data) => handleSelectChange(data, field.name)}
                                    />
                                    {!!(errorMessageList && errorMessageList.hasOwnProperty(`${field.name}`)) && (<div className="error-msg">{errorMessageList[field.name]}</div>)}
                                </fieldset>
                            );
                        }
                    }
                    return null; // Return null if no condition matched for rendering
                })}
            </div>
            {!isPropertyQualified ? (
                <>
                    <p>
                        <div className="error-msg">{qualifyError || 'Qualify creteria not matched.'}</div>
                        </p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button
                            type="button"
                            className={`${style.saveButton} ${style.disabledButton}`}
                            disabled={true}
                            onClick={() => { toast.error(qualifyError || 'Qualify creteria not matched.'); }}
                        >
                            Qualify
                        </button>
                    </div>
                </>
            ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                        type="button"
                        className={`${style.saveButton} ${isLoading ? style.disabledButton : ""}`}
                        onClick={() => { handleSaveButton(isPropertyQualified ? 'qualify' : 'Save'); }}
                    >
                        Qualify
                        {/* {isPropertyQualified ? 'Qualify' : 'Save'} */}
                    </button>
                </div>
            )}

        </Formsy>
    );
};

export default SaveAndQualify;
