import React, { useEffect, useState,memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tab, Nav } from 'react-bootstrap';
import ListGridView from "../leads/docs-upload/DocumentViewGallery"
import { useOutletContext } from 'react-router-dom';
import ActivityHistory from './UpdateStatus/ActivityHistory';
import FollowUpActivityHistory from './UpdateStatus/FollowUpActivityHistory';

const DocumentGallery = (props) => {
    const [docCategoryList, setDocCategoryList] = useState(props.documentCategoryList);
    const defaultCategory = { label: "Primary Govt ID", value: +props?.doc_id || 4 };
    const [filterCategoryChildList, setFilterCategoryChildList] = useState([])
    const [selectedTab, setSelectedTab] = useState('first_submit');
    const [activityHistory, setActivityHistory] = useState('all');
    const [followUpactivityHistory, setFollowUpActivityHistory] = useState('all');
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [seletedTabDocs, setSelectedTabDocs] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);

    const [activeProductTab, setActiveProductTab] = useState('activity_history');
    const defaultSubCategory = {};

    const { leadStatusHistory, leadDetail, leadDocs, userInfo } = useSelector(({ lead, user }) => ({
        leadDocs: lead && lead.leadDetail && lead.leadDetail.leaddocs || [],
        userInfo: user.user_information || {},
        leadDetail: lead.leadDetail,
        leadStatusHistory: lead.leadDetail && lead.leadDetail.lead_status_history || []
    }));

    const context = useOutletContext();
    const markAsFreezed = context?.markAsFreezed  ;

    const user_information = localStorage.getItem('user_information');
    let authUser=(user_information && JSON.parse(user_information));
    let subStatusMarkedTillnow = (leadStatusHistory && leadStatusHistory.length) ? leadStatusHistory.map(v=>v.sub_status_id) : [];

    const [docGallery, setDocGallery] = useState({
        selectedCategory: { ...defaultCategory },
        selectedSubCategory: { ...defaultSubCategory },
        filterFiles: [],
        filterCategory: docCategoryList
    });

    useEffect(() => {
        let docCategoryList = props.documentCategoryList || [];
        docCategoryList = docCategoryList.map(data => {
            return {...data, tab_name: 'first_submit'}
        })
        setDocumentList(leadDocs);
        setDefaultImageCategory();
        let category = docCategoryList.filter(f => f.parent_id === 0 && f.tab_name === selectedTab && f.id !== 111).map(e => ({ label: e.doclang.name, value: e.id,is_coapplicant :e.is_coapplicant,is_financial: e.is_financial  }))
        let sub_category = docCategoryList.filter(f => f.id === defaultCategory.value)?.map(v => v.child)[0] || []
        let seletedTabDocIds = sub_category.filter(f => f.tab_name === selectedTab).map(e => e.id);
        sub_category = sub_category && sub_category.length && sub_category.map(e => ({ label: e.doclang.name, value: e.id }));
        let filterCategoryChildLists = [];
        docCategoryList && docCategoryList.filter(f => f.tab_name === selectedTab).map(data => {
            filterCategoryChildLists = [...filterCategoryChildLists, ...data.child]
        });
        setFilterCategoryChildList(filterCategoryChildLists);
        seletedTabDocIds = filterCategoryChildLists.map(e => e.id);
        let selectedTabDocs = leadDocs.reduce((res, data) => {          
            if (seletedTabDocIds.indexOf(data.doc_id) > -1) res.push(data);
            return res;
        }, []);
        setDocCategoryList(docCategoryList);
        setCategoryList(category);
        setSubCategoryList(sub_category);
        setSelectedTabDocs(selectedTabDocs);
    }, [props]);

    const setDefaultImageCategory = () => {
        /*  Set default image on load */
        let filtredFiles = leadDocs.reduce((res, data) => {
            if (data.doc_id === defaultCategory.value) res.push(data);
            return res;
        }, []);
        setDocGallery({ ...docGallery, filterFiles: filtredFiles, selectedCategory: { ...defaultCategory }, selectedSubCategory: { ...defaultSubCategory } });
    };

    const handleSelectTab = (eventKey) => {
        setSelectedTab(eventKey);
        let docCategoryList = props.documentCategoryList || []; 
        let category = docCategoryList.filter(f => f.parent_id === 0 && f.tab_name === 'first_submit' && f.id !== 111).map(e => ({ label: e.doclang.name, value: e.id,is_coapplicant :e.is_coapplicant,is_financial: e.is_financial }));
        setCategoryList(category);
        setSubCategoryList([]);
        let filterCategoryChildLists = [];
        docCategoryList && docCategoryList.filter(f => f.tab_name === 'first_submit').map(data => {
            filterCategoryChildLists = [...filterCategoryChildLists, ...data.child]
        });
        setFilterCategoryChildList(filterCategoryChildLists)

        let seletedTabDocIds = filterCategoryChildLists.map(e => e.id);
        let selectedTabDocs = leadDocs.reduce((res, data) => {
            if (seletedTabDocIds.indexOf(data.doc_id) > -1) res.push(data);
            return res;
        }, []);
        setDocGallery({ ...docGallery, selectedCategory: {}, selectedSubCategory: {} });
        setSelectedTabDocs(selectedTabDocs); 
    } 
    let uType = 'customer';
    let uId = leadDetail?.customer?.customer_id;
    if(selectedTab !='first_submit'){  
        if (selectedTab?.split('-')?.[0] =='co'){ 
            uId = selectedTab?.split('-')?.[1]   
            uType = 'co_applicant'
        }    
    }  
    let categoryListSplit = categoryList;
    if(uType =='co_applicant'){   
        categoryListSplit = categoryList.filter(ob=>ob.is_coapplicant =='1')  
    }  

    const handleActivityTabSelect = (eventKey) => {
        if (activeProductTab === 'activity_history') {
            setActivityHistory(eventKey);
        } else if (activeProductTab === 'followup') {
            setFollowUpActivityHistory(eventKey);
        }
    };
    return (
        <>
            <div className="tab-data">
                <Tab.Container
                    id="left-tabs-example"
                    activeKey={activeProductTab} 
                    onSelect={(tab) => setActiveProductTab(tab)}
                >
                    <Nav variant="pills" className="flex-column nav nav-pills">
                        <div className="tab-list">
                            <Nav.Item>
                                <Nav.Link eventKey="activity_history">Activity History</Nav.Link>
                            </Nav.Item>
                        </div>
                        <div className="tab-list">
                            <Nav.Item>
                                <Nav.Link eventKey="document">Documents</Nav.Link>
                            </Nav.Item>
                        </div>
                        <div className="tab-list">
                            <Nav.Item>
                                <Nav.Link eventKey="followup">Follow Up</Nav.Link>
                            </Nav.Item>
                        </div>
                    </Nav>
                    <Nav >
                        
                    </Nav>
                    
                    
                </Tab.Container>
            </div>
        
            <div className="doument-gallery-tabs" key={selectedTab}>
                <Tab.Container id="left-tabs-example" defaultActiveKey={selectedTab} onSelect={handleSelectTab}>
                    <Nav variant="pills" className="flex-column">
                        <div className="tab-list">
                            {activeProductTab === "activity_history" &&(
                                <>
                                    <Nav.Item className='nav-item'>
                                        <Nav.Link active={activityHistory == 'all'} eventKey={'all'} disabled={false} onClick={() => handleActivityTabSelect('all')}> All</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-item'>
                                        <Nav.Link active={activityHistory == 'status_updates'} eventKey={'status_updates'} disabled={false} onClick={() => handleActivityTabSelect('status_updates')}> Status Updates</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item className='nav-item'>
                                        <Nav.Link active={activityHistory == 'communication'} eventKey={'communication'} disabled={false} onClick={() => handleActivityTabSelect('communication')}> Communication</Nav.Link>
                                    </Nav.Item> */}
                                    {/* <Nav.Item className='nav-item'>
                                        <Nav.Link active={activityHistory == 'audit_log'} eventKey={'audit_log'} disabled={false} onClick={() => handleActivityTabSelect('audit_log')}> Audit Log</Nav.Link>
                                    </Nav.Item> */}
                                    <Nav.Item className='nav-item'>
                                        <Nav.Link active={activityHistory == 'remark_updates'} eventKey={'remark_updates'} disabled={false} onClick={() => handleActivityTabSelect('remark_updates')}>Remark Updates</Nav.Link>
                                    </Nav.Item>
                                </>
                            )}
                            {activeProductTab === 'document' && (
                                <>
                                    <Nav.Item className='nav-item'>
                                        <Nav.Link active={selectedTab == 'first_submit'} eventKey={'first_submit'} disabled={false}> Customer</Nav.Link>
                                    </Nav.Item>
                                    {
                                        leadDetail.co_applicant?.length ? leadDetail.co_applicant.map((ob,key)=>{
                                            return (
                                                <Nav.Item className='nav-item' key={key}>
                                                    <Nav.Link active={selectedTab == 'co-'+ob.id} eventKey={"co-"+ob.id}>{ob.ca_first_name} co applicant</Nav.Link>
                                                </Nav.Item>
                                            )
                                        }) : null
                                    }  
                                </>
                            )}

                            {activeProductTab === "followup" &&(
                                <>
                                    <Nav.Item className='nav-item'>
                                        <Nav.Link active={followUpactivityHistory == 'all'} eventKey={'all'} disabled={false} onClick={() => handleActivityTabSelect('all')}> All</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-item'>
                                        <Nav.Link active={followUpactivityHistory == 'customer'} eventKey={'customer'} disabled={false} onClick={() => handleActivityTabSelect('customer')}> Customer</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-item'>
                                        <Nav.Link active={followUpactivityHistory == 'bank'} eventKey={'bank'} disabled={false} onClick={() => handleActivityTabSelect('bank')}> Bank</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-item'>
                                        <Nav.Link active={followUpactivityHistory == 'saathi'} eventKey={'saathi'} disabled={false} onClick={() => handleActivityTabSelect('saathi')}> Saathi</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-item'>
                                        <Nav.Link active={followUpactivityHistory == 'sourcing'} eventKey={'sourcing'} disabled={false} onClick={() => handleActivityTabSelect('sourcing')}>Sourcing</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-item'>
                                        <Nav.Link active={followUpactivityHistory == 'Builder for Docs'} eventKey={'Builder for Docs'} disabled={false} onClick={() => handleActivityTabSelect('Builder for Docs')}>Builder</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-item'>
                                        <Nav.Link active={followUpactivityHistory == 'fbm'} eventKey={'fbm'} disabled={false} onClick={() => handleActivityTabSelect('fbm')}>FBM</Nav.Link>
                                    </Nav.Item>
                                </>
                            )}

                        </div>
                    </Nav>  

                    <Tab.Content>
                        <Tab.Pane active={activeProductTab === 'activity_history'} eventKey="activity_history">
                            <ActivityHistory activityHistoryTab={activityHistory}/>
                        </Tab.Pane>
                        <Tab.Pane active={activeProductTab === 'document'} eventKey={selectedTab}>
                            <ListGridView
                                docGallery={docGallery}
                                categoryList={categoryListSplit}
                                subCategoryList={subCategoryList}
                                docCategoryList={docCategoryList}
                                filterCategoryChildList={filterCategoryChildList}
                                documentList={documentList}
                                seletedTabDocs={seletedTabDocs}
                                selectedTab={selectedTab}
                                authUser={authUser}
                                setDefaultImageCategory={setDefaultImageCategory}
                                setSubCategoryList={setSubCategoryList}
                                setDocGallery={setDocGallery}
                                key={selectedTab}
                                leadDetail={leadDetail}
                                markAsFreezed={markAsFreezed}
                                leadDocs={leadDocs}
                                uType={uType}
                                uId={uId}
                            />
                        </Tab.Pane>
                        <Tab.Pane active={activeProductTab === 'followup'} eventKey="followup">
                            <FollowUpActivityHistory activityHistoryTab={followUpactivityHistory}/>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </>
    )
}

export default memo(DocumentGallery);
