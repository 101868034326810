import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import DocumentGallery from './DocumentGallery';
import FormFields from "./formFields/leadFormField.json";
import FormsyInputField from '../elements/FormsyInputField';
import FormsyDatePicker from '../elements/FormsyDatePicker';
import FormsySelect from '../elements/FormsySelect';
import { useOutletContext } from 'react-router-dom';
import dateFormat from 'dateformat';
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import Modal from '../elements/Modal';
import { UPDATE_AMBAK_CALCULATION } from '../../services/leads.gql';
import { useApolloClient, gql } from '@apollo/client';
import { toast } from 'react-toastify';
import GeneralService from '../../services/generalService';
import Loader from '../elements/Loader';
import LoggedinPopup from '../elements/LoggedinPopup';
import LoggedinTabs from './LoggedinTabs';
import moment from 'moment';
import { MIS_DETAILS } from '../../services/mis.gql';
import { executeGraphQLQuery } from '../../common/partnerExecuteGraphQLQuery';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { toCamelCase } from '../../helpers/helpers';

const DisbursedDetailsForm = FormFields["COLLECTION"];

const DisbursedLead = (props) => {
 
  const [disburseDetails, setDisbursedDetails] = useState([]);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [misList, setMisList] = useState([]);
  const [misDetail,setMisDetail] = useState({})
  const [showremarkform, setShowremarkform] = useState(false);
  const [selectedTranch,setSelectedTranch] = useState(null)

  let [loading, setIsLoading] = useState(false);
  let [errors, setErrors] = useState({});
  const [activeTab, setActiveTab] = useState("tranchtype_0");
  const { masterdata, lead, remarksHistory } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
    remarksHistory: lead.remarksHistory || []
  }));
  const client = useApolloClient();
  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);


  const getMisDetails = async () => {
    const leadTrancheDetails= lead?.leadDetail?.splitpayment.find(item=>item.id==selectedTranch)
    try {
      const variables = { filter_params:{
        lead_id:leadTrancheDetails.lead_id,tranche_id:selectedTranch,
        callFrom:"payin",
        status:"collection"
      } };
      const response = await executeGraphQLQuery(
        MIS_DETAILS(),
        variables
      );

      if (response.data?.mis_list) {
        const listOfMis = response.data?.mis_list?.list || [];
        if(listOfMis.length){
          const misData = listOfMis.find(item=>item.payin_type=="standard") || {}
          setMisDetail(misData)
        }
        setMisList(listOfMis);
      }
    } catch (error) {
      console.log("Error 🔻 ", error.message);
    }
  };

  useEffect(() => {
    if (lead && lead.leadDetail) {
      let disbursedData = lead.leadDetail.splitpayment || [];
      const splitPayment = disbursedData.map(data => {
        return {
          id: data.id,
          pay_in_done: data.pay_in_done || '0',
          is_pay_in_verified: data.is_pay_in_verified || '0',
          pay_in_confirm_mode: data.pay_in_confirm_mode || '0',
          pay_in_confirm_date: data.pay_in_confirm_date ? dateFormat(data.pay_in_confirm_date, "dd mmm, yyyy") : null,
          pay_in_confirm_amount: data.pay_in_confirm_amount || '',
          pay_in_confirm_id: data.pay_in_confirm_id || '',
          ambak_calculation:data.ambak_calculation || null
        }
      })
      setDisbursedDetails(splitPayment);      
    }

  }, [lead]);

  useEffect(()=>{
    if(disburseDetails.length && misList.length==0 && selectedTranch==null){
      setSelectedTranch(disburseDetails[0].id)
    }
  },[disburseDetails])

  useEffect(() => {
    if (selectedTranch) {
      getMisDetails();
    }
  }, [selectedTranch]);


  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};    
    return options;
  }, [masterdata]);

  const handleInputChange = event => {
    if (event.target.id) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedDisburalDetails = [...disburseDetails];
      updatedDisburalDetails[activetabIndex][event.target.id] = event.target.value;
      setDisbursedDetails(updatedDisburalDetails);
    }
  }

  const handleDisbursalDone = event => {
    let activetabIndex = activeTab.split('_');
    activetabIndex = activetabIndex[1];
    const updatedDisburalDetails = [...disburseDetails];
    if (event.target.checked) {
      // setTransactionDone(event.target.value);      
      updatedDisburalDetails[activetabIndex][event.target.id] = event.target.value;
    } else {
      // setTransactionDone('0');
      updatedDisburalDetails[activetabIndex][event.target.id] = '0';
    }
    setDisbursedDetails(updatedDisburalDetails);

  }

  const handlePatternFormatChange = (data, field_name) => {
    if (field_name && data.value !== undefined) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedDisburalDetails = [...disburseDetails];
      updatedDisburalDetails[activetabIndex][field_name] = data.value;
      setDisbursedDetails(updatedDisburalDetails);
    }
  }

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedDisburalDetails = [...disburseDetails];
      updatedDisburalDetails[activetabIndex][field_name] = date;
      setDisbursedDetails(updatedDisburalDetails);
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedDisburalDetails = [...disburseDetails];
      updatedDisburalDetails[activetabIndex][field_name] = data?.value;
      setDisbursedDetails(updatedDisburalDetails);
    }
  }



  const handleSubmit = async(formValue) => {
    setIsLoading(true);

    const variables = {
      tranche_id: selectedTranch,
      amount: +formValue.ambak_calculation
    };
    try {
      const response = await executeGraphQLMutation(UPDATE_AMBAK_CALCULATION(),variables,client);
      toast.success(response.data.update_ambak_calculation);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
      console.log("Error 🔻 ", error?.message);
    }
  }  

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };


  

  const saveDocument = (lead_id, docPathArray) => {
    let leadDocArr = [];
    for (let imgPath of docPathArray) {
      leadDocArr.push({
        lead_id: Number(lead_id),
        doc_id: 65,
        parent_doc_id: 64,
        doc_path: imgPath,
        doc_source: "web",
        customer_type: "customer",
        customer_id: lead?.leadDetail?.customer?.customer_id || 0,
        user_id: authUser && authUser.id || 1,
      })
    }
    let variables = {
      saveDocInput: {
        lead_id: lead_id,
        lead_docs: leadDocArr
      },
      api_called_by: 'web'
    };

    let mutation = gql`mutation save_doc($saveDocInput: SaveDocInput!, $api_called_by: API_CALL_BY!){
        save_doc(saveDocInput: $saveDocInput, api_called_by: $api_called_by) {
          lead_id
          doc_id
          doc_path
        }
    }`;
    executeGraphQLMutation(mutation, variables, client).then(result => {
      if (result && result.data) {
        toast.success('Document uploaded successfully');
        // dispatch(getLoanDetail(lead_id, client));
      }
    })
  }

  const sectionInfo = { section: "Disbursal Details", sub_section: "Disbursal Details" }
  let active_tab_index = activeTab.split('_');
  active_tab_index = active_tab_index && active_tab_index[1] || '';
  let activeTabDataObject = lead?.leadDetail?.splitpayment[active_tab_index] || {};

  return (
    <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
      {loading ? <Loader /> : null}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="65" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <h2>Collection</h2>
          </div>
          <Nav variant="pills" className="flex-column" style={{ overflow: "hidden", overflowX: "auto" }}>
            <div className="tab-list">
              {disburseDetails.length && disburseDetails.map((coApplicant, index) =>                
                  <Nav.Item key={index}>
                    <Nav.Link
                      onClick={() => {
                        setActiveTab(`tranchtype_${index}`)
                        setMisDetail({})
                        setMisList([])
                        setSelectedTranch(coApplicant.id)
                      }}
                      eventkey={`tranchtype_${index}`}
                      active={activeTab === `tranchtype_${index}`}
                    >
                      <b>{`Transaction ${index + 1}`}</b>
                    </Nav.Link>
                  </Nav.Item>
              )}
            </div>
          </Nav>

          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">
              {DisbursedDetailsForm.map((field) =>
                disburseDetails.map((splitPaymentDetailData, index) => (`tranchtype_${index}` === activeTab) && (
                  ["text", "pattern-format"].includes(field.type) ? (
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={splitPaymentDetailData && splitPaymentDetailData[field.name]}
                        placeholder=" "
                        label={field.label}
                        maxLength={field.maxLength}
                        onChange={
                          field.type === "pattern-format"
                            ? (data) => handlePatternFormatChange(data, field.name)
                            : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                          splitPaymentDetailData[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                      />
                    </fieldset>
                  ) :
                  ["number-format"].includes(field.type) ? (
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={splitPaymentDetailData && splitPaymentDetailData[field.name]}
                        placeholder=" "
                        thousandsGroupStyle="lakh"
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        label={field.label}
                        maxLength={field.maxLength}
                        onChange={
                          field.type === "number-format"
                            ? (data) => handlePatternFormatChange(data, field.name)
                            : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                          splitPaymentDetailData[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                      />
                    </fieldset>
                  ) :
                    field.type === "date-picker" ? (
                      <fieldset class="form-filed col-md-6 ">
                        <FormsyDatePicker
                          name="transaction_date"
                          selectedDate={splitPaymentDetailData && splitPaymentDetailData[field.name] && new Date(splitPaymentDetailData[field.name])}
                          placeholder={field.label}
                          minDate={new Date(activeTabDataObject?.disbursed_date)}
                          maxDate={new Date()}
                          value={splitPaymentDetailData && splitPaymentDetailData[field.name] ? dateFormat(splitPaymentDetailData[field.name], "dd mmm, yyyy") : null}
                          onDateSelect={(date) => handleDateChange(date, field.name)}
                          dateFormat="dd MMM, yyyy"
                          validations={field.validations}
                          validationError={
                            splitPaymentDetailData[field.name] ? field.validationError : ""
                          }
                          required={field.required}
                          hasError={(splitPaymentDetailData.hasOwnProperty(field.name) && !splitPaymentDetailData[field.name]) ? true : false}
                        />
                      </fieldset>
                    ) :
                      field.type === "dropdown" ? (
                        <fieldset class="single-select col-md-6">
                          <FormsySelect
                            name={field.name}
                            id={field.id}
                            inputProps={{
                              options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                              placeholder: field.label,
                              className: "react-select",
                              classNamePrefix: "react-select",
                              value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ id }) => id == splitPaymentDetailData[field.name]),
                              isDisabled: field.readOnly
                            }}
                            required={field.required}
                            value={splitPaymentDetailData && splitPaymentDetailData[field.name]}
                            onChange={(data) => handleSelectChange(data, field.name)}
                          />
                        </fieldset>
                      ) : field.type === "textarea" ? (
                        <fieldset class="col-md-12">
                          <div class="material">
                            <textarea
                              id={field.id}
                              name={field.name}
                              type={field.type}
                              value={splitPaymentDetailData && splitPaymentDetailData[field.name]}
                              placeholder=" "
                              onChange={handleInputChange}
                              class="form-input"
                            >
                            </textarea>
                            <label
                              data-label={field.label}
                              class="form-label"
                            >
                            </label>
                          </div>
                        </fieldset>
                      
                      ) : (field.type === "checkbox") ? (
                        <fieldset class="col-md-12 ">
                          <div className='loggedcheck'>
                            <input type='checkbox' onChange={handleDisbursalDone} id={field.id} name={field.name} value='1' checked={(splitPaymentDetailData && splitPaymentDetailData[field.name] === '1') ? true : false} />
                            <label htmlFor="credit">{field.label}</label>
                          </div>
                        </fieldset>
                      ) : null
                ))
              )
              }
              <button type="submit" className="btn-primary" 
              >Update</button>
            </div>
            
            { Object.values(misList).length ?
            <>
             { Object.values(misDetail).length ?  
               <>
              <div className='collection-details'>
              <div>
                  <div className="collection-details-label">Status</div>
                  <div className='collection-details-value'>{misDetail?.payin_status?toCamelCase(misDetail?.payin_status):"N/A"}</div>
              </div>
              <div>
                  <div className="collection-details-label">Collection Date</div>
                  <div className='collection-details-value'>{misDetail?.payment_date?moment(misDetail.payment_date).format("DD MMM YYYY"):"N/A"}</div>
              </div>
              <div>
                  <div className="collection-details-label">UTR</div>
                  <div className='collection-details-value'>{misDetail?.utr||"N/A"}</div>
              </div>
              <div>
                  <div className="collection-details-label">Invoice No.</div>
                  <div className='collection-details-value'>{misDetail?.Invoice_id||"N/A"}</div>
              </div>
              <div>
                  <div className="collection-details-label">Invoice Date</div>
                  <div className='collection-details-value'>{misDetail?.Invoice_date?moment(misDetail.Invoice_date).format("DD MMM YYYY"):"N/A"}</div>
              </div>
          </div>   </>
           : "" }
        
          <div>
              <div className='collection-amount-header'>Amounts</div>
              <div className="clearfix">
          <div className="card">
            <div className="table-responsive ">
              <table className="table table-bordered tablefontsize">
                <thead>
                  <tr>
                    <th>{"Sr. no."}</th>
                    <th>{"Type"}</th>
                    <th>{"Amount"}</th>
                    <th>{"Date"}</th>
                  </tr>
                </thead>
                <tbody>
                  {misList.map((mis, index) => (
                    <>
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{toCamelCase(mis.payin_type)}</td>
                        <td>{mis.payin_amount}</td>
                        <td>{moment(mis.payment_date).format("DD MMM YYYY")}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
            </div>
            </>
            :<div className='notfoundpayout'>Collection details are not found.</div>}
           


            {/* <div className="btn-save-remarks"> */}
              {/* <button type="button" onClick={() => handleSubmit('tranch_verify')} className="btn-primary" disabled={is_pay_in_verified || !activeTabDataObject.pay_in_confirm_id}>Verify</button> */}
            {/* </div> */}
          </Formsy>
        </div>
      </div>
      <div className="view-timeline-popup">
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezed} sectionInfo={sectionInfo} />
        </Modal>
      </div>
    </div>
  );
}

export default DisbursedLead;