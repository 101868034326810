import { LEAD_DETAIL_QUERY, GET_DOCUMENTS_LIST, GET_DOCUMENTS_LIST_UPDATED } from '../../services/leads.gql';
import { executeGraphQLQuery } from '../../common/executeGraphQLQuery';


const cachedResponses = {};
const ongoingRequests = {};

export const getLeadDetailFetch = async (lead_id, client, dispatch) => {
  if (!lead_id) return null;

  // Check if the response is already cached
  if (cachedResponses[lead_id]) {
    return cachedResponses[lead_id];
  }

  // Prevent duplicate requests for the same lead_id
  if (ongoingRequests[lead_id]) {
    return ongoingRequests[lead_id];
  }

  // Make the API call and cache the result
  const request = executeGraphQLQuery(LEAD_DETAIL_QUERY(lead_id), client)
    .then((response) => {
      if (response && response.data) {
        console.log(response.data.get_lead_detail);
        const leadData = response.data.get_lead_detail[0];
        cachedResponses[lead_id] = leadData; // Cache the response
        if (dispatch) {
          dispatch({ type: "GET_LEAD_DETAIL", payload: leadData });
        }
        return leadData;
      }
      throw new Error("Lead not found");
    })
    .catch((error) => {
      console.error("Failed to fetch lead detail:", error);
      throw error;
    })
    .finally(() => {
      delete ongoingRequests[lead_id]; // Remove from ongoing requests
    });

  ongoingRequests[lead_id] = request;
  return request;
};
