export const getCurrentUserDetails = () => {
  const userInformation = localStorage.getItem("user_information");
  let userInfo = {};
  if (userInformation) {
    try {
      const parsedUserInformation = JSON.parse(userInformation);
      if (parsedUserInformation?.user_data?.user_id) {
        userInfo = {
          userId: parsedUserInformation?.user_data.user_id,
          yoddha_code: parsedUserInformation?.yodha_code,
          dialer_email: parsedUserInformation?.dialer_email,
          access_token: parsedUserInformation?.access_token,
        };
      }
      return userInfo;
    } catch (error) {
      console.error("Error parsing JSON from localStorage", error);
    }
  } else {
    return false;
  }
};