import FormFields from "../formFields/breOfferFormFields.json";
import FormsyInputField from "../../elements/FormsyInputField";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Formsy from "formsy-react";

const OBLIGATIONS_FIELDS = FormFields["OBLIGATIONS"];

const BreObligations = ({
  setObligationDetails,
  customerType = "customer",
  activeCoApplicant,
}) => {
  const [breObligations, setBreObligations] = useState({});
  const [isFormTouch, setTouch] = useState({});
  const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
  }));

  useEffect(() => {
    if (lead && lead.leadDetail) {
      let exEmiAmt = "",
        end6Months = "";
      if (customerType === "customer") {
        exEmiAmt = lead.leadDetail?.customer?.existing_emi_amount;
        end6Months = lead.leadDetail?.customer?.emi_ending_six_month;
      } else {
        exEmiAmt = activeCoApplicant.ca_existing_emi_amount;
        end6Months = activeCoApplicant.ca_emi_ending_six_month;
      }
      const bre_obligations = {
        existing_emi_amount: exEmiAmt,
        emi_ending_six_month: end6Months,
      };
      setBreObligations(bre_obligations);
      setObligationDetails(null, null, bre_obligations);
    }
  }, [lead]);

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setTouch({
        ...isFormTouch,
        [field_name]: true,
      });
      setBreObligations((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
      setObligationDetails(field_name, data.value);
    }
  };

  return (
    <>
      <div>
        <Formsy
          className="lead-form-filed"
          autoComplete="off"
          onValid={() => null}
          onInvalid={() => null}
        >
          <div className="row">
            {OBLIGATIONS_FIELDS.map((field, index) =>
              ["number-format"].includes(field.type) ? (
                <fieldset className={"form-filed col-md-6"} key={index}>
                  <FormsyInputField
                    id={
                      customerType === "customer" ? field.id : `ca_${field.id}`
                    }
                    name={
                      customerType === "customer"
                        ? field.name
                        : `ca_${field.name}`
                    }
                    type={field.type}
                    value={(breObligations && breObligations[field.name]) || ""}
                    placeholder=" "
                    thousandsGroupStyle="lakh"
                    thousandSeparator={","}
                    decimalSeparator={"."}
                    maxLength={field.maxLength}
                    label={`${field.label}  \u2726`}
                    format={field.ApplyNumberformat ? field.format : ""}
                    validations={
                      isFormTouch[field.name] ? field.validations : null
                    }
                    validationError={
                      breObligations[field.name] ||
                      breObligations[field.name] == ""
                        ? isFormTouch[field.name]
                          ? field.validationError
                          : ""
                        : ""
                    }
                    readOnly={field.readOnly}
                    required={field.required}
                    onChange={(data) =>
                      handlePatternFormatChange(
                        data,
                        customerType === "customer"
                          ? field.name
                          : `ca_${field.name}`
                      )
                    }
                  />
                </fieldset>
              ) : null
            )}
          </div>
        </Formsy>
      </div>
    </>
  );
};

export default BreObligations;
