import { useApolloClient } from "@apollo/client";
import { HttpStatusCode } from "axios";
import dateFormat from "dateformat";
import Formsy from "formsy-react";
import * as moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { RotatingLines } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import { CRIF_CONSENT_INITIATE, CRIF_CONSENT_VERIFY, GET_CIBIL_DETAILS, GET_CIBIL_INFO, GET_DETAILS_BY_PANCARD } from "../../../services/customer.gql";
import { getLoanDetail } from "../../../store/action/allAction";
import FormsyDatePicker from "../../elements/FormsyDatePicker";
import FormsyInputField from "../../elements/FormsyInputField";

const DOB_MAX_DATE = new Date().setFullYear(new Date().getFullYear() - 18);
const DOB_MIN_DATE = new Date().setFullYear(new Date().getFullYear() - 76);
const PAN_REGEX = /^[a-zA-Z]{3}[PCHFATBLJGpchfatbljg]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;

const CrifOtpModal = ({ crifActiveDetails, lead, setCrifCibil }) => {
    const client = useApolloClient();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [crifRef, setCrifRef] = useState(null);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [isMobileFetched, setIsMobileFetched] = useState(false);
    const [crifOtpFormData, setCrifOtpFormData] = useState({});
    const [errorMessageList, setErrorMessageList] = useState({});
    //user_type

    const user_information = localStorage.getItem("user_information");
    const authUser = user_information && JSON.parse(user_information);
    const user_id = authUser?.id || 1;

    const [inputOtp, setInputOtp] = useState(["", "", "", ""]);
    const [inputOtpStr, setInputOtpStr] = useState("");
    const buttonRef = useRef(null);

    // Handle input change
    const handleChangeOtp = (e, index) => {
        const value = e.target.value;
        if (/^\d?$/.test(value)) { // Only allow single digit
            const newOtp = [...inputOtp];
            newOtp[index] = value;
            setInputOtp(newOtp);

            // Move focus to the next input
            if (value && index < 3) {
                document.getElementById(`otp-${index + 1}`).focus();
            } else {
                // Move focus to the button after the last input
                buttonRef.current.focus();
            }
        }
    };

    // Handle backspace to move focus to the previous input
    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !inputOtp[index] && index > 0) {
            document.getElementById(`otp-${index - 1}`).focus();
        }
    };

    useEffect(() => {
        setInputOtpStr(inputOtp.join(""))
    }, [inputOtp]);

    const crifFormOnChange = (event) => {
        let { name, value } = event.target;
        if (name === "pan_card" && !PAN_REGEX.test(value)) {
            name = null
            value = null
        };
        if (name && value) {
            setCrifOtpFormData((current) => ({
                ...current,
                [name]: value.toUpperCase(),
            }));
        }
    };

    const handleDateChange = (date, field_name) => {
        if (date && field_name) {
            crifFormOnChange({ target: { name: field_name, value: dateFormat(date, "yyyy-mm-dd") } })
        }
    };

    const handleVerifyOTP = async () => {
        if (inputOtpStr && inputOtpStr.length === 4) {
            setLoading(true)
            setSpinner(true);
            await callVerifyConsignmentOtp(inputOtpStr);
        } else {
            toast.error(`Invalid OTP.`)
        }
    };

    const validateForm = (type) => {
        const validate = { status: true, error: null }
        let errorMsg = null
        if (!crifOtpFormData?.pan_card) {
            errorMsg = 'Please enter PAN number'
            setErrorMessageList({ pan_card: errorMsg })
        }
        if (type != 'mobile') {
            if (!crifOtpFormData?.mobile) {
                errorMsg = 'Please enter Mobile number'
                setErrorMessageList({ mobile: errorMsg })
            }
            if (!crifOtpFormData?.first_name) {
                errorMsg = 'Please enter First name'
                setErrorMessageList({ first_name: errorMsg })
            }
        }
        if (!crifOtpFormData?.type) {
            errorMsg = 'CRIF type not found'
            setErrorMessageList({ generic: errorMsg })
        }
        if (!crifOtpFormData?.id) {
            errorMsg = 'CRIF id not found'
            setErrorMessageList({ generic: errorMsg })
        }

        if (!PAN_REGEX.test(crifOtpFormData.pan_card)) {
            errorMsg = 'Please enter valid PAN number'
            setErrorMessageList({ pan_card: errorMsg })
        }

        if (errorMsg) {
            validate.status = false
            validate.error = errorMsg
        }
        return validate;
    }

    const handleFetchMobile = async () => {
        setErrorMessageList({})
        try {
            const validated = validateForm('mobile');
            if (validated.status) {
                setLoading(true);
                const result = await executeGraphQLQuery(GET_DETAILS_BY_PANCARD(crifOtpFormData.pan_card), client);
                if (result.data && result.data?.get_details_by_pancard) {
                    const jsonResp = JSON.parse(result.data?.get_details_by_pancard);
                    if (jsonResp.data?.response?.code === 200) {
                        const splitDob = jsonResp.data?.response?.dob.split('/');
                        const dobFormat = splitDob[2] + "-" + splitDob[1] + "-" + splitDob[0];
                        
                        const newMobile = jsonResp.data?.response?.mobile_no || crifOtpFormData?.mobile || null
                        setCrifOtpFormData((current) => ({
                            ...current,
                            'dob': dateFormat(new Date(dobFormat), "dd mmm, yyyy"),
                            'first_name': jsonResp.data?.response?.firstName || null,
                            'middle_name': jsonResp.data?.response?.middleName || null,
                            'last_name': jsonResp.data?.response?.lastName || null,
                            'mobile': newMobile,
                            'gender': jsonResp.data?.response?.gender || null,
                        }));
                        setIsMobileFetched(true);
                        if (!newMobile) {
                            setErrorMessageList((current) => ({
                                ...current,
                                mobile: 'Mobile number not found'
                            }));
                        }
                        if (!jsonResp.data?.response?.firstName) {
                            setErrorMessageList((current) => ({
                                ...current,
                                first_name: 'First name can not be empty'
                            }));
                        }
                    } else {
                        setIsMobileFetched(false);
                        toast.error(jsonResp.data?.response?.message);
                    }
                } else {
                    toast.error("Unable to get PAN information");
                }
            }
        } catch (error) {
            console.error("\n\n handleFetchMobile >> error => ", error)
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };


    const getOldMobile = () => {
        let mobile = lead?.leadDetail?.customer?.mobile;
        if (crifOtpFormData.type != 'customer') {
            mobile = lead?.leadDetail?.co_applicant.filter(ob => ob.id == crifOtpFormData.id)?.[0]?.ca_mobile || null;
        }
        return mobile;
    }

    const handleFetchCibilReport = async () => {
        setLoading(true);
        try {
            const validated = validateForm('cibil');
            if (validated.status) {
                const data = {
                    pan_card: crifOtpFormData.pan_card,
                    first_name: crifOtpFormData.first_name,
                    middle_name: crifOtpFormData?.middle_name || '',
                    last_name: crifOtpFormData.last_name,
                    dob: crifOtpFormData?.dob ? dateFormat(crifOtpFormData.dob, "dd/mm/yyyy") : null,
                    gender: crifOtpFormData.gender || lead?.leadDetail?.customer?.gender,
                    mobile: crifOtpFormData.mobile || lead?.leadDetail?.customer?.mobile,
                    partner_id: `${lead?.leadDetail?.partner_id || 1}`,
                    user_id,
                    partner_name: lead?.leadDetail?.partner_name || authUser?.name,
                    is_consigned: 0,
                    lead_id: lead.leadDetail.id,
                    lead_type: lead?.leadDetail?.fulfillment_type == 'ambak' ? 1 : 0,
                    update_concent: 0
                };
                data.update_concent = 1;
                data.old_mobile = await getOldMobile()
                if (!data?.old_mobile) {
                    data.old_mobile = data.mobile
                }
                await cibilProcess(data, crifOtpFormData?.type, crifOtpFormData?.id);
            }
        } catch (error) {
            console.error("\n\n handleFetchCibilReport >> error => ", error)
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const cibilProcess = async (data, type, id) => {
        const variables = {
            cibilDetailsInput: data,
            type,
            id
        };
        const response = await executeGraphQLMutation(GET_CIBIL_DETAILS, variables, client);
        if (response.data?.get_cibil_details) {
            await executeGraphQLQuery(GET_CIBIL_INFO(data.pan_card, 'crif_report', data.mobile, data.partner_id, data.lead_id, data.user_id), client)
                .then(async (gqlRes) => {
                    if (gqlRes?.data?.cibil_info?.crif_report?.cta === 'Success' && gqlRes?.data?.cibil_info?.crif_report?.consent === 1 && gqlRes?.data?.cibil_info?.crif_report?.score && gqlRes?.data?.cibil_info?.crif_report?.report) {
                        setCrifCibil(gqlRes.data.cibil_info.crif_report)
                    } else {
                        return callSendConsignmentOtp()
                    }
                })
                .then((otpRes) => {
                    if (otpRes?.success && otpRes?.data?.refId) {
                        toast.success(`OTP send successfully.`);
                        setCrifRef(otpRes.data.refId);
                        setCrifOtpFormData((current) => ({
                            ...current,
                            'crif_consent': 0,
                            'otp_modal': true,
                        }));
                    }
                })
                .catch((error) => {
                    console.error("\n\n fetchCrifCibilData >> error => ", error)
                    toast.error(error?.message || `Somethig went wrong on sending consent OTP.`)
                });
        } else {
            throw Error("Internal server error");
        }
    };

    const callSendConsignmentOtp = async () => {
        const variables = {
            cibilCrifInput: {
                pan_card: crifOtpFormData.pan_card,
                mobile: Number(crifOtpFormData.mobile),
                first_name: crifOtpFormData.first_name,
                last_name: crifOtpFormData.last_name,
                dob: crifOtpFormData.dob,
                partner_id: `${lead?.leadDetail?.partner_id || 1}`,
                user_id: user_id,
                lead_id: lead.leadDetail.id
            },
        };

        return await executeGraphQLMutation(CRIF_CONSENT_INITIATE(), variables, client)
            .then((resp) => {
                if (resp?.data?.cibil_crif_consent_initiate?.status === HttpStatusCode.Ok && resp?.data?.cibil_crif_consent_initiate?.data?.success) {
                    return resp.data.cibil_crif_consent_initiate.data
                }else if(resp?.data?.cibil_crif_consent_initiate?.errors){
                    throw new Error(resp?.data?.cibil_crif_consent_initiate?.errors?.message) 
                } 
            })
            .catch((error) => {
                console.error("\n\n callSendConsignmentOtp >> error => ", error)
                throw new Error(error?.message || `Unable to send OTP for consent.`)
            });
    };

    const callVerifyConsignmentOtp = async () => {
        setLoading(true)
        const variables = {
            cibilCrifInput: {
                pan_card: crifOtpFormData.pan_card,
                mobile: Number(crifOtpFormData.mobile),
                otp: inputOtpStr,
                crif_ref: crifRef,
                partner_id: `${lead?.leadDetail?.partner_id || 1}`,
                user_id: user_id,
                lead_id: lead.leadDetail.id
            },
        };

        await executeGraphQLMutation(CRIF_CONSENT_VERIFY(), variables, client)
            .then((resp) => {
                if (resp?.data?.cibil_crif_consent_verify?.status === HttpStatusCode.Ok && resp?.data?.cibil_crif_consent_verify?.data?.status) {
                    if (resp?.data?.cibil_crif_consent_verify?.data?.data?.crif_report?.cta === 'Success' && resp?.data?.cibil_crif_consent_verify?.data?.data?.crif_report?.consent === 1 && resp?.data?.cibil_crif_consent_verify?.data?.data?.crif_report?.score && resp?.data?.cibil_crif_consent_verify?.data?.data?.crif_report?.report) {
                        setCrifCibil(resp.data.cibil_crif_consent_verify.data.data.crif_report)
                        toast.success(`CRIF report fetched successfully.`)
                    } else {
                        toast.success(`Consent approved. Please click View CRIF report button to view report.`)
                    }
                } else {
                    const errMsg = resp?.data?.cibil_crif_consent_verify?.data?.errors || resp?.data?.cibil_crif_consent_verify?.errors || `Unable to send OTP for consent.`;
                    if (errMsg === 'Customer already granted the consent.') {
                        toast.success(errMsg)
                    } else {
                        toast.error(errMsg)
                    }
                }
            })
            .catch((error) => {
                console.error("\n\n callVerifyConsignmentOtp >> error => ", error)
                toast.error(`Unable to verify OTP.`)
            })
            .finally(() => {
                dispatch(getLoanDetail(lead.leadDetail.id, client))
                setLoading(false)
                setSpinner(false);
            });
    };

    useEffect(() => {
        setInputOtp(["", "", "", ""])
        setCrifOtpFormData({ ...crifActiveDetails })
    }, [crifActiveDetails]);

    const crifTable = { fontSize: '14px', width: '100%', borderCollapse: 'collapse', backgroundColor: '#ffffff', fontFamily: 'Roboto, "Helvetica Neue", Arial, sans-serif', color: '#595959', border: '1px solid #f0f0f0', "page-break-inside": "avoid" };
    const crifTableHead_TH = { fontSize: '14px', backgroundColor: 'green', color: '#ffffff', textAlign: 'center', fontWeight: '500', padding: '5px 12px', borderBottom: '1px solid #d9d9d9', };
    const crifTableBody_TH = { fontSize: '12px', fontWeight: '700', backgroundColor: '#fafafa', border: '1px solid #f0f0f0', color: '#262626', textAlign: 'left', verticalAlign: 'middle', padding: '8px 12px', };
    const crifTableBody_TD = { fontSize: '12px', color: '#595959', border: '1px solid #f0f0f0', textAlign: 'left', verticalAlign: 'middle', padding: '8px 12px', };

    return (
        <>
            <div style={{ padding: "30px 25px 10px 25px" }} className="lead-otp-verify-container">
                <div className="error-msg">{errorMessageList?.generic}</div>
                <div className="modal-header">
                    <h2>Check CRIF Score</h2>
                </div>
                {!!(crifOtpFormData?.first_name && crifOtpFormData?.dob && crifOtpFormData?.pan_card && crifOtpFormData?.mobile && crifOtpFormData?.otp_modal) ? (
                    <>
                        <div style={{ paddingBottom: '20px' }}>
                            <table style={crifTable}>
                                <colgroup>
                                    <col style={{ width: '40%' }} />
                                    <col style={{ width: '60%' }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th colSpan="2" style={crifTableHead_TH} >
                                            Consumer Information
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th style={crifTableBody_TH}>Name</th>
                                        <td style={crifTableBody_TD}>
                                            {(!!crifOtpFormData?.first_name || !!crifOtpFormData?.last_name) ? (`${crifOtpFormData?.first_name || ''} ${crifOtpFormData?.last_name || ''}`) : ' - '}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={crifTableBody_TH}>Date Of Birth</th>
                                        <td style={crifTableBody_TD}>{crifOtpFormData?.dob ? moment(crifOtpFormData.dob).format('DD-MMM-YYYY') : ' - '}</td>
                                    </tr>
                                    <tr>
                                        <th style={crifTableBody_TH}>PAN Number</th>
                                        <td style={crifTableBody_TD}>{crifOtpFormData?.pan_card || ' - '}</td>
                                    </tr>
                                    <tr>
                                        <th style={crifTableBody_TH}>Mobile Number</th>
                                        <td style={crifTableBody_TD}>{crifOtpFormData?.mobile || ' - '}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {spinner ? (
                            <div style={{ textAlign: "center" }}>
                                <RotatingLines
                                    visible={true}
                                    height="80"
                                    width="80"
                                    strokeColor="grey"
                                    strokeWidth="4"
                                    animationDuration="0.75"
                                    ariaLabel="rotating-lines-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                                <p>Fetching CRIF report.</p>
                                <p>Please don't close or reload or don't go back.</p>
                            </div>
                        ) : (
                            <div className="lead-otp-verify-input-container" style={{ textAlign: "center" }}>
                                <p>{`We have sent an OTP.`}</p>
                                <fieldset className="form-filed col-md-12" style={{ paddingTop: "10px" }}>
                                    <div className="lead-otp-verify-otp-input">
                                        {inputOtp.map((digit, index) => (
                                            <input
                                                key={index}
                                                id={`otp-${index}`}
                                                type="text"
                                                maxLength="1"
                                                value={digit}
                                                onChange={(e) => handleChangeOtp(e, index)}
                                                onKeyDown={(e) => handleKeyDown(e, index)}
                                                style={{
                                                    width: "40px",
                                                    height: "40px",
                                                    textAlign: "center",
                                                    margin: "0 5px",
                                                    fontSize: "18px",
                                                }}
                                            />
                                        ))}
                                    </div>
                                </fieldset>
                                <div className="btn-save-remarks">
                                    <button
                                        ref={buttonRef}
                                        type="button"
                                        className="btn-primary"
                                        onClick={() => handleVerifyOTP(inputOtpStr)}
                                        disabled={(loading || inputOtpStr.length !== 4)}
                                    >
                                        Verify OTP
                                    </button>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <Formsy className="lead-form-filed" autoComplete="off" onValid={() => setAllowSubmit(true)} onInvalid={() => setAllowSubmit(false)} >
                                {crifOtpFormData?.pan_card ? (
                                    <fieldset className="form-field col-md-12">
                                        <FormsyInputField
                                            id="pan_card"
                                            name="pan_card"
                                            type="text"
                                            value={crifOtpFormData?.pan_card || ""}
                                            placeholder=" "
                                            maxLength="10"
                                            label="Pan Card No."
                                            onChange={crifFormOnChange}
                                            format={false}
                                            isFieldRequired={true}
                                            disabled={!!crifOtpFormData?.pan_card} 
                                        />
                                        {errorMessageList?.pan_card && (
                                            <div className="error-msg">{errorMessageList.pan_card}</div>
                                        )}
                                    </fieldset>
                                ) : (
                                    <div className="error-msgg-txt">
                                        Please Update PanCard From Customer Details.
                                    </div>
                                )}

                           
                                {isMobileFetched ? (
                                    <>
                                        <fieldset className="form-filed col-md-12">
                                            <FormsyInputField
                                                id="mobile"
                                                name="mobile"
                                                type="number"
                                                value={crifOtpFormData?.mobile || ""}
                                                placeholder=" "
                                                maxLength="10"
                                                label="Mobile No."
                                                onChange={crifFormOnChange}
                                                validations="isValidPhoneNumber"
                                                isFieldRequired={true}
                                            />
                                            <div className="error-msg">{errorMessageList?.mobile}</div>
                                        </fieldset>
                                        <fieldset className="form-filed col-md-12">
                                            <FormsyInputField
                                                name="first_name"
                                                type="text"
                                                value={crifOtpFormData?.first_name || ""}
                                                placeholder=" "
                                                label="First Name"
                                                onChange={crifFormOnChange}
                                                format={true}
                                                validationError="First Name is required"
                                                isFieldRequired={true}
                                            />
                                            <div className="error-msg">{errorMessageList?.first_name}</div>
                                        </fieldset>
                                        <fieldset className="form-filed col-md-12">
                                            <FormsyInputField
                                                name="last_name"
                                                type="text"
                                                value={crifOtpFormData?.last_name || ""}
                                                placeholder=" "
                                                label="Last Name"
                                                onChange={crifFormOnChange}
                                                format={true}
                                                validationError=""
                                                required={false}
                                            />
                                            <div className="error-msg">{errorMessageList?.last_name}</div>
                                        </fieldset>
                                        <fieldset className="form-filed col-md-12">
                                            <FormsyDatePicker
                                                name="dob"
                                                id="dob"
                                                selectedDate={crifOtpFormData.dob && new Date(crifOtpFormData.dob)}
                                                placeholder="DOB"
                                                fieldTitle="DOB"
                                                minDate={new Date(new Date(DOB_MIN_DATE).setHours(0, 0, 0, 0))}
                                                maxDate={new Date(new Date(DOB_MAX_DATE).setHours(0, 0, 0, 0))}
                                                onDateSelect={(date) => handleDateChange(date, "dob")}
                                                showYearDropdown
                                                showMonthDropdown
                                                isFieldRequired={true}
                                                style={{ border: "1px solid #cccccc" }}
                                                dateFormat="dd MMM, yyyy"
                                            />
                                            <div className="error-msg">{errorMessageList?.dob}</div>
                                        </fieldset>
                                        <div className="btn-save-remarks">
                                            <button
                                                type="button"
                                                className="btn-primary"
                                                onClick={() => handleFetchCibilReport(false)}
                                                disabled={!allowSubmit || loading}
                                            >
                                                {loading ? "Sending OTP" : "Fetch Report"}
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    crifOtpFormData?.pan_card && (
                                        <div className="btn-save-remarks">
                                            <button
                                                type="button"
                                                className="btn-primary"
                                                onClick={handleFetchMobile}
                                                disabled={!!loading}
                                            >
                                                Continue
                                            </button>
                                        </div>
                                    )
                                )}

                        </Formsy>
                    </>
                )}
            </div>
        </>
    )
}

export default CrifOtpModal;
