import React, { useState } from "react";
import DateFormate from "dateformat";
import Pagination from "../elements/pagination";
import Modal from "../elements/Modal";
import MismatchDataDetail from "./MismatchDataDetail";
import { toast } from "react-toastify";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../common/partnerExecuteGraphQLQuery";
import { UPDATE_MATCHED_STATUS, FETCH_GST_CLAIMED_INVOICE_NUMBERS, UPLOAD_GST_CLAIMED_INVOICE_PDF, FILE_JSON_URL_UPLOAD, TRUNCATE_JSON_DATA, GST_JSON_URL } from "../../services/payout.gql.js";
import InventoryService from "../../services/InventoryService.js";
import Select from "react-select";

const GstTableContent = ({
  gstStatusData,
  gstStatusDataPagination,
  gstStatusDataPageNo = 1,
  setGstDataPageNo,
  gstStatus
}) => {
  const [modalVisiblity, setModalVisiblity] = useState(false);
  const [showDetailData, setShowDetailData] = useState({});
  const [showDetailDataKeys, setShowDetailDataKeys] = useState([]);
  const [invoiceNumbers, setInvoiceNumbers] = useState({}); 
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [selectedInvoiceId, setSelectedInvoiceId] = useState({});
  const [selectedApprovedInvoiceUrl, setSelectedApprovedInvoiceUrl] = useState({});
  const [showDateMismatchPopup, setShowDateMismatchPopup] = useState(false);
  const [showAmountMismatchPopup, setShowAmountMismatchPopup] = useState(false); 
  const [showUploadInvoicePopup, setShowUploadInvoicePopup] = useState(false); 
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [showModal, setShowModal] = useState(false);
  
  const onPaginate = (pageNo) => {
    setGstDataPageNo(pageNo);
  };

  const convertToDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return new Date(`${year}-${month}-${day}`);
  };

  const handleShowDetail = (event) => {
    const id = event.target.getAttribute("dataid");
    const filteredData = gstStatusData.filter((obj) => obj.id == id)[0] || {};
    setShowDetailData(filteredData);
    setShowDetailDataKeys(Object.keys(filteredData));
    setModalVisiblity(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedPdf(file); 
    } else {
      toast.error("Please upload a valid PDF file.");
    }
  };
  const handleClose1 = () => { setShowModal(false); setShowDateMismatchPopup(false);
    setShowAmountMismatchPopup(false); setShowUploadInvoicePopup(false); }
  
  const handleUploadInvoice = async () => {
    console.log("Starting invoice upload...", selectedPdf);
  
    if (!selectedPdf) {
      console.error("No file selected");
      toast.error("No file selected. Please choose a file to upload.");
      return;
    }

    let file_url = "";

    if (selectedPdf) {
      let formData = new FormData();
      formData.append("dealer_id", selectedPartnerId);
      formData.append("upload_type", "dealer_docs");
      formData.append("images", selectedPdf);
      const response = await InventoryService.uploadFilesToS3(formData, {
        "Content-Type": "multipart/form-data",
      }); console.log("responsePDFUrl", response)
      if (response.data.status === 200) {
        file_url = response?.data?.data[0]?.file_url;
        console.log("file_urll", file_url)
    }
  }
  
    try {
      const uploadedPdfUrl = file_url; 
  
      if (!uploadedPdfUrl) {
        console.error("Uploaded file URL is missing");
        toast.error("Failed to retrieve the uploaded file URL.");
        return;
      }
  
      // console.log("Updating the database with the file URL...");
      const selectedInvoiceIdValue = selectedInvoiceId[selectedPartnerId]; 
      const selectedInvoiceNo = selectedInvoice[selectedPartnerId]; 
      const selectedApprovedInvoiceUrlValue = selectedApprovedInvoiceUrl[selectedPartnerId]; 
      const gstForceMatch = 1; 
  
      if (!selectedInvoiceNo || !selectedInvoiceIdValue) {
        console.error("Invoice number or ID not selected");
        toast.error("Please select an invoice number and ID.");
        return;
      }

    const selectedPartnerData = gstStatusData.find(
      (data) => data.partner_id === selectedPartnerId
    );

    const gstClaimedInvoiceNo = selectedPartnerData ? selectedPartnerData.inum : null;

    if (!gstClaimedInvoiceNo) {
      console.error("Claimed invoice number (inum) not found");
      toast.error("Failed to retrieve the claimed invoice number.");
      return;
    }

    const selectedMonth = convertToDate(mismatchDetails.selectedDate).getMonth();
    const apiMonth = convertToDate(mismatchDetails.apiDate).getMonth();

    const dateToUpdate = selectedMonth === apiMonth 
      ? mismatchDetails.selectedDate 
      : mismatchDetails.apiDate;
    
    const reformattedDate = dateToUpdate.split('-').reverse().join('-');
    // console.log(dateToUpdate, "dateToUpdate=>", reformattedDate)

    const variables = {
      id: selectedInvoiceIdValue[selectedInvoiceNo],
      invoice_no: selectedInvoiceNo, 
      invoice_date: reformattedDate,
      approved_invoice_url: selectedApprovedInvoiceUrlValue[selectedInvoiceNo],
      gst_claimed_pdf: uploadedPdfUrl,
      gst_force_match: gstForceMatch,
      gst_claimed_inv_no: gstClaimedInvoiceNo,
    };
  
      console.log("Variables==>:", variables);
      const result = await executeGraphQLMutation(UPLOAD_GST_CLAIMED_INVOICE_PDF, variables);
      // console.log("GraphQL Result:", result);
  
      if (result?.data?.updateGstClaimedInvoicePdfUrl) {
        setShowUploadInvoicePopup(false);
        setShowModal(false);
        toast.success("Invoice Matching successfully.");

      const truncate_result = await executeGraphQLMutation(TRUNCATE_JSON_DATA);
        console.log("truncate_result", truncate_result)
      const gstJsonUrlResult = await executeGraphQLMutation(GST_JSON_URL);
      const jsonFileUrl = gstJsonUrlResult?.data?.gst_json__url;
      console.log("jsonFileUrl.data", jsonFileUrl)
      if (!jsonFileUrl) {
        console.error("Failed to retrieve JSON file URL from GST_JSON_URL mutation");
        toast.error("Failed to retrieve JSON file URL.");
        return;
      }

        const variables_json = {
          uploadGstArgs: {
            json_file: jsonFileUrl,
            update_for: "payment",
          },
        };
        try { 
          await executeGraphQLMutation(FILE_JSON_URL_UPLOAD, variables_json).then((result) => {
            const csvData = result?.data?.upload_gst;
            console.log("csvData==>", csvData)
            if (!csvData) return;
            const blob = new Blob([csvData], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "gst_pending_data.csv";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
          });
        } catch (error) {
          // toast.error("Something went wrong!");
        };

      } else {
        console.warn("Failed to update the invoice PDF URL:", result?.errors);
        toast.error("Failed to update the invoice PDF URL.");
      }
    } catch (error) {
      console.error("Error uploading invoice:", error);
      toast.error("An error occurred while uploading the invoice. Please try again.");
    }
  };
  

  const handleUploadInvoicePopupClose = () => {
    setShowUploadInvoicePopup(false);
    setShowModal(false);
  };

  const handleUpdateStatus = async (partner_id, id) => {
    try {
      const responseData = await executeGraphQLQuery(
        UPDATE_MATCHED_STATUS(partner_id, id)
      );
      if (
        responseData?.data.update_matched_status &&
        responseData?.data.update_matched_status !== ""
      ) {
        toast.success(responseData?.data?.update_matched_status || "Success");
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      toast.error("Failed");
      console.error("error", error);
    }
  };

  const fetchInvoices = async (partner_id) => {
    console.log("Fetching invoices...");

    if (!partner_id) {
      console.error("Partner ID is missing");
      toast.error("Failed to fetch invoices: Missing Partner ID");
      return;
    }

    const variables = { partner_id };

    try {
      const result = await executeGraphQLMutation(FETCH_GST_CLAIMED_INVOICE_NUMBERS, variables);
      // console.log("GraphQL Result:", result);
      if (result?.data?.fetch_gst_claimed_invoice_number) {
        const invoices = result.data.fetch_gst_claimed_invoice_number; 
        console.log("Fetched Invoices neww:", invoices);

        setInvoiceNumbers((prev) => ({
          ...prev,
          [partner_id]: invoices,
        }));
        setSelectedInvoiceId((prev) => ({
          ...prev,
          [partner_id]: invoices.reduce((map, invoice) => {
            map[invoice.invoice_no] = invoice.id;
            return map;
          }, {}),
        }));
        setSelectedApprovedInvoiceUrl((prev) => ({
          ...prev,
          [partner_id]: invoices.reduce((map, invoice) => {
            map[invoice.invoice_no] = invoice.approved_invoice_url;
            return map;
          }, {}),
        }));
      } else {
        console.warn("No invoice numbers found for partner_id:", partner_id);
      }
    } catch (error) {
      console.error("Failed to fetch invoices:", error);
      toast.error("Failed to fetch invoices");
    }
  };

  const handleDropdownFocus = (partner_id) => {
    if (!invoiceNumbers[partner_id]) {
      fetchInvoices(partner_id);
    }
  };

  const handleChange = (partner_id, selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : null;
    console.log("selectedValue", selectedValue)
    setShowModal(true);
    setSelectedPartnerId(partner_id);
  
    const selectedData = gstStatusData.find((data) => data.partner_id === partner_id);
  
    if (!selectedData) {
      toast.error("Partner data not found!");
      return;
    }
  
    const selectedInvoiceDate = selectedData.inv_date;
    const selectedAmount = selectedData.invoice_amt;
    const apiInvoiceData = invoiceNumbers[partner_id]?.find(
      (invoice) => invoice.invoice_no === selectedValue
    );
  
    if (!apiInvoiceData) {
      toast.error("Invoice data not found for the selected invoice!");
      return;
    }
  
    const apiInvoiceDate = apiInvoiceData.invoice_date;
    const apiAmount = apiInvoiceData.payout_amount;
  
    const formattedSelectedDate = new Date(selectedInvoiceDate).toLocaleDateString();
    const formattedApiDate = convertToDate(apiInvoiceDate).toLocaleDateString();
    const datesMatch = formattedSelectedDate === formattedApiDate;
  
    setMismatchDetails({
      partner_id,
      selectedDate: selectedInvoiceDate,
      apiDate: apiInvoiceDate,
      datesMatch,
      selectedAmount,
      apiAmount: apiAmount || 'Amount not available',
      amountsMatch: selectedAmount === apiInvoiceData?.invoice_amt, 
    });
  
    setSelectedInvoice((prev) => ({ ...prev, [partner_id]: selectedValue }));
    setShowDateMismatchPopup(true);
  };
  

  const [mismatchDetails, setMismatchDetails] = useState({
    partner_id: null,
    selectedDate: "",
    apiDate: "",
    datesMatch: true,
    selectedAmount: "",
    apiAmount: "",
  });

  const handlePopupConfirm = () => {
      setShowDateMismatchPopup(false);
      setShowAmountMismatchPopup(true);
  };

  const handlePopupCancel = () => {
    setSelectedInvoice((prev) => {
      const newState = { ...prev };
      delete newState[mismatchDetails.partner_id];
      return newState;
    });
    setShowDateMismatchPopup(false);
    setShowModal(false); 
  };

  const handleAmountPopupConfirm = () => {
    setShowAmountMismatchPopup(false); 
    setShowUploadInvoicePopup(true); 
  };

  const handleAmountPopupCancel = () => {
    setShowAmountMismatchPopup(false);
    setShowModal(false);
  };

  const options = (data) =>
    invoiceNumbers[data.partner_id] && invoiceNumbers[data.partner_id].length > 0
      ? invoiceNumbers[data.partner_id].map((invoice) => ({
          value: invoice.invoice_no,
          label: invoice.invoice_no,
        }))
      : [];
    
      const customStyles = {
        control: (provided) => ({
          ...provided,
          width: 190, // Set your desired width here
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding: 4, // Adjust indicator padding if necessary
        }),
        menu: (provided) => ({
          ...provided,
          width: 190, // Ensure the dropdown matches the width of the control
        }),
      };

  return (
    <>
      {modalVisiblity && (
        <>
          <div className="view-timeline-popup">
            <Modal
              show={modalVisiblity}
              removeHeight={true}
              handleClose={() => setModalVisiblity(false)}
            >
              <MismatchDataDetail keyArry={showDetailDataKeys} data={showDetailData} />
            </Modal>
          </div>
        </>
      )}

      <div className="clearfix">
        <div className="table-responsive">
          <table className="table table-bordered tablefontsize">
            <thead>
              <tr>
                {gstStatus === "not matched" ? <th>Partner Details</th> : null}
                {gstStatus === "other vendors" ? <th>Vendor Details</th> : null}
                <th>GST Claimed</th>
                <th>Claimed Invoice Details</th>
                <th>Claimed Invoice Number</th>
                <th>Action</th>
                {gstStatus === "not matched" ? <th>Force Match</th> : null}
              </tr>
            </thead>
            <tbody>
              {gstStatusData &&
                gstStatusData.map((data, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      {gstStatus === "not matched" && (
                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            Partner Code : <span> AMK{data.partner_id}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            Partner Name : <span>{data.partner_name}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            GSTIN : <span>{data.ctin}</span>
                          </div>
                          {data?.parent_partner_id > 0 && (
                            <div style={{ opacity: 0.6 }}>
                              Parent Partner :{" "}
                              <span>
                                {data?.parent_partner_name} ({data?.parent_partner_id})
                              </span>
                            </div>
                          )}
                        </td>
                      )}

                      {gstStatus === "other vendors" && (
                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            Vendor Name : <span> {data.trdnm}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            Vendor GSTIN : <span>{data.ctin}</span>
                          </div>
                        </td>
                      )}

                      <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          Total : <span>{+data.igst + +data.cgst + +data.sgst}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          IGST : <span>{data.igst}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          CGST : <span>{data.cgst}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          SGST : <span>{data.sgst}</span>
                        </div>
                      </td>
                      <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          Taxable Amt : <span>{data.txval}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          Invoice Date : <span>{data.inv_date}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          Invoice Amt : <span>{data.invoice_amt}</span>
                        </div>
                      </td>
                      <td>{data.inum}</td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center", 
                          }}
                        >
                          <div>
                            <button onClick={handleShowDetail} dataid={data.id}>
                              Show Detail
                            </button>
                          </div>
                          </div>
                          </td>
                          {gstStatus === "not matched" ? 
                          <td>
                          <div>
                            <Select
                              id=""
                              onFocus={() => {
                                handleDropdownFocus(data.partner_id);
                              }}
                              options={options(data)}
                              onChange={(e) => handleChange(data.partner_id, e)}
                              placeholder="Select Invoice"
                              isClearable
                              noOptionsMessage={() => "No Invoice Found"}
                              styles={customStyles} 
                            />
                          </div>                                             
                          {/* <div>
                            <select
                              onFocus={() => handleDropdownFocus(data.partner_id)}
                              onChange={(e) => handleChange(data.partner_id, e)}
                              value={selectedInvoice[data.partner_id] || ""}
                            >
                              <option value="">Select Invoice</option>
                              {invoiceNumbers[data.partner_id] && invoiceNumbers[data.partner_id].length > 0 ? (
                                invoiceNumbers[data.partner_id].map((invoice, index) => (
                                  <option key={index} value={invoice.invoice_no}>
                                    {invoice.invoice_no}
                                  </option>
                                ))
                              ) : (
                                <option disabled>No Invoice Found</option>
                              )}
                            </select>
                          </div> */}
                          </td>
                          : null}                      
                    </tr>
                  </React.Fragment>
                ))}
              <tr>
                <td colSpan={3}>
                  <Pagination
                    pageCount={gstStatusDataPagination?.totalpage || 0}
                    activePage={gstStatusDataPageNo}
                    onPaginate={onPaginate}
                  />
                </td>
                <td>
                  Total: {gstStatusDataPagination?.totalrecords || 0}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Modal
       show={showModal} 
       handleClose={handleClose1} 
       width="400px" 
       modalHeight="300px"
      >

        {showDateMismatchPopup && (
          <div className="modal-content-container">
            <div className="content-question">
              <h3>Are you sure you want to proceed?</h3>
              {!mismatchDetails.datesMatch && (
                <>
                  <p style={{ color: "red" }}>The invoice dates do not match.</p>
                  <p>
                    <strong>Claimed:</strong> {convertToDate(mismatchDetails.selectedDate).toLocaleDateString()} <br />
                    <strong>Paid:</strong> {convertToDate(mismatchDetails.apiDate).toLocaleDateString()}
                  </p>
                </>
              )}
              <div style={{ marginTop: "20px" }}>
                <button className="content-button" onClick={handlePopupConfirm}>Yes</button>
                <button className="content-button" onClick={handlePopupCancel}>No</button>
              </div>
            </div>
          </div>
        )}
        {showAmountMismatchPopup && (
          <div className="modal-content-container">
            <div className="content-question">
              <h3>Are you sure you want to proceed?</h3>
              {!mismatchDetails.amountsMatch && (
                <>
                  <p style={{ color: "red" }}>The invoice Amounts do not match.</p>
                  <p>
                    <strong>Claimed:</strong> {mismatchDetails.selectedAmount} <br />
                    <strong>Paid:</strong> {mismatchDetails.apiAmount}
                  </p>
                </>
              )}
              <div style={{ marginTop: "20px" }}>
                <button className="content-button" onClick={handleAmountPopupConfirm}>Yes</button>
                <button className="content-button" onClick={handleAmountPopupCancel}>No</button>
              </div>
            </div>
          </div>
        )}
        {showUploadInvoicePopup && (
          <div className="modal-content-container">
            <div className="content-question">
              <h3>
                Okay! You will need to upload the claimed invoice PDF to match this claim.
                You'll be redirected to your browser automatically!
              </h3>

              {/* PDF File Upload */}
              <div>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={handleFileChange}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <button className="content-button" onClick={handleUploadInvoice}>Upload PDF</button>
                <button className="content-button" onClick={handleUploadInvoicePopupClose}>Close</button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default GstTableContent;
