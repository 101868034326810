import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import DocumentGallery from './DocumentGallery';
import { useOutletContext, useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import Modal from '../elements/Modal';
import Loader from '../elements/Loader';
import { MIS_LIST } from '../../services/mis.gql';
import Pagination from '../elements/pagination';
import { executeGraphQLQuery } from '../../common/partnerExecuteGraphQLQuery';
import moment from 'moment';
import { executeGraphQLQueryFinex } from '../../common/executeGraphQLQueryFinex';
import { GET_USER_LIST_PAYOUT } from '../../services/payout.gql';


const PayInMIS = (props) => {
 
  const [disburseDetails, setDisbursedDetails] = useState([]);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [misList, setMisList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [userList, setUserList] = useState([])
  const [selectedTranch,setSelectedTranch] = useState(null)

  let [loading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("tranchtype_0");
  const { lead } = useSelector(({lead }) => ({lead}));
  let { lead_id } = useParams();
  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;
  let updatedStatusList = lead?.leadDetail?.lead_status_history?.map(data => data.status_id) || [];



  useEffect(() => {
    if (lead && lead.leadDetail) {
      let disbursedData = lead.leadDetail.splitpayment || [];
      const splitPayment = disbursedData.map(data => {
        return {
          id: data.id,
          pay_in_done: data.pay_in_done || '0',
          is_pay_in_verified: data.is_pay_in_verified || '0',
          pay_in_confirm_mode: data.pay_in_confirm_mode || '0',
          pay_in_confirm_date: data.pay_in_confirm_date ? dateFormat(data.pay_in_confirm_date, "dd mmm, yyyy") : null,
          pay_in_confirm_amount: data.pay_in_confirm_amount || '',
          pay_in_confirm_id: data.pay_in_confirm_id || ''
        }
      })
      setDisbursedDetails(splitPayment);      
    }
    
  }, [lead]);

  const handlePagination = (pageNumber) => {
    getMisList(pageNumber);
  };

  const getMisList = async (page_number = 1) => {
    const leadTrancheDetails= lead?.leadDetail?.splitpayment.find(item=>item.id==selectedTranch)
    try {
      const variables = { filter_params:{
        lead_id:leadTrancheDetails.lead_id,
        tranche_id:selectedTranch,
        callFrom:"payin"
      } };
      variables.page_no = page_number
      const response = await executeGraphQLQuery(
        MIS_LIST(page_number),
        variables
      );

      if (response.data?.mis_file_list) {
        const listOfMis = response.data?.mis_file_list?.list || [];
        setMisList(listOfMis);
        setPagination(response.data?.mis_file_list?.pagination || {});
      }
      // else{
      //   setMisList([]);
      //   setPagination({});
      // }
    } catch (error) {
      console.log("Error 🔻 ", error.message);
    }
  };

  const getUsersList = async () => {
    let user_list = [], userlist = [];
    setUserList([])
    const response = await executeGraphQLQueryFinex(GET_USER_LIST_PAYOUT).catch(
      (err) => console.log(err)
    );
        if (response && response.data && response.data.get_user_list) {
            user_list = response.data.get_user_list;
            user_list && user_list.map((v) => {
                userlist.push({ value: v.user_id, label: v.name })
                return v;
            })
            setUserList(userlist);
        }
  
};

  useEffect(() => {
    getUsersList()
  }, []);

  useEffect(()=>{
    if(disburseDetails.length && misList.length==0){
      setSelectedTranch(disburseDetails[0].id)
    }
  },[disburseDetails])

  useEffect(() => {
    if (selectedTranch) {
      getMisList();
    }
  }, [selectedTranch]);



  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };




  const sectionInfo = { section: "Disbursal Details", sub_section: "Disbursal Details" }
  let active_tab_index = activeTab.split('_');
  active_tab_index = active_tab_index && active_tab_index[1] || '';
  let activeTabDataObject = lead?.leadDetail?.splitpayment[active_tab_index] || {};

  return (
    <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
      {loading ? <Loader /> : null}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="65" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <h2>Pay-In MIS</h2>
          </div>
          <Nav variant="pills" className="flex-column" style={{ overflow: "hidden", overflowX: "auto" }}>
            <div className="tab-list">
              {disburseDetails.length && disburseDetails.map((coApplicant, index) =>                
                  <Nav.Item key={index}>
                    <Nav.Link
                      onClick={() => {
                        setActiveTab(`tranchtype_${index}`)
                        setSelectedTranch(coApplicant.id)
                      }}
                      eventkey={`tranchtype_${index}`}
                      active={activeTab === `tranchtype_${index}`}
                    >
                      <b>{`Transaction ${index + 1}`}</b>
                    </Nav.Link>
                  </Nav.Item>
              )}
            </div>
          </Nav>
          <div className="clearfix">
          {misList.length ? <div className="card">
            <div className="table-responsive ">
              <table className="table table-bordered tablefontsize">
                <thead>
                  <tr>
                    <th>{"MIS ID"}</th>
                    <th>{"MIS Uploaded Date"}</th>
                    <th>{"Uploaded By"}</th>
                    <th>{"Uploaded file"}</th>
                    <th>{"Response file"}</th>
                  </tr>
                </thead>
                <tbody>
                  {misList.map((files, index) => (
                    <>
                      <tr key={index}>
                        <td>{files.id}</td>
                        <td>{files.created_at ? moment(files.created_at).format("lll") : null}</td>
                        <td>{userList.find(item=>item.value==files.created_by)?.label||null}</td>
                        <td><div>{files?.csv_file && <a href={files.csv_file} target="_blank">
                          <div className="btn-group btn-group-small " style={{width:"30px"}}>
                            <div
                              className="btn btn-default"
                              title="View"
                            >
                              <i
                                className="ic-pdf icons-normal"
                                style={{
                                  fontSize: 16,
                                  color: "gray",
                                }}
                              ></i>
                            </div>
                          </div>
                        </a>}
                        </div>
                        </td>
                        <td><div>{files?.processed_file && <a href={files.processed_file} target="_blank">
                          <div className="btn-group btn-group-small " style={{width:"30px"}}>
                            <div
                              className="btn btn-default"
                              title="View"
                            >
                              <i
                                className="ic-pdf icons-normal"
                                style={{
                                  fontSize: 16,
                                  color: "gray",
                                }}
                              ></i>
                            </div>
                          </div>
                        </a>}
                        </div>
                        </td>
                      </tr>
                    </>
                  ))}
                  <tr>
                    <td colSpan={4}>
                      {" "}
                      <Pagination
                        pageCount={
                          pagination && pagination.totalpage
                            ? pagination.totalpage
                            : 0
                        }
                        // activePage={filterData.page_no ? filterData.page_no : 1}
                        onPaginate={handlePagination}
                      />
                    </td>
                    <td>
                      Total:{" "}
                      {pagination && pagination.totalrecords
                        ? pagination.totalrecords
                        : 0}{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>:<div className='notfoundpayout'>Pay-In MIS details are not found.</div>}
        </div>
        </div>
      </div>
      <div className="view-timeline-popup">
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezed} sectionInfo={sectionInfo} />
        </Modal>
      </div>
    </div>
  );
}

export default PayInMIS;