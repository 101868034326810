import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import crypto from "../../config/crypto";
import { Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from './DocumentGallery';
import FormFields from "./formFields/leadFormField.json";
import FormsyInputField from './../elements/FormsyInputField';
import FormsyDatePicker from './../elements/FormsyDatePicker';
import FormsySelect from './../elements/FormsySelect';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import LeadRemarkHistory from './../lead-list/LeadRemarkHistory';
import Modal from './../elements/Modal';
import { executeGraphQLMutation, executeGraphQLQuery } from './../../common/executeGraphQLQuery';
import { UPDATE_LEAD_STATUS } from './../../services/leads.gql';
import { useApolloClient, gql } from '@apollo/client';
import { toast } from 'react-toastify';
import { getLoanDetail, getLeadRemarks } from './../../store/action/allAction';
import GeneralService from '../../services/generalService';
import Loader from '../elements/Loader';
import LostModal from './LostModal';
import LoggedinPopup from '../elements/LoggedinPopup';
import LoggedinTabs from './LoggedinTabs';
import right from "../../webroot/images/angle-right.svg";
import { IS_USER_ACCESS_PAGE } from '../../config/constants';
import MultiSelect from '../elements/MultiSelect';
import { SAVE_CUST_DETAILS } from '../../services/customer.gql';
import {CLOSED_REPORTING} from '../../services/mis.gql';
import LeadStatusService from "../../services/leadStatus";


const DisbursedDetailsForm = FormFields["DISBURSED"];
const DisbursalDocsJson = FormFields['DISBURSED_DOCS'];
const EXPECTED_DISBURSAL_DATE = FormFields['EXPECTED_DISBURSAL_DATE'];

const DisbursedLead = (props) => {
  const [disburseDetails, setDisbursedDetails] = useState([]);
  const [expectedDate, setExpectedDisburseDate] = useState({expected_disbursed_date: "" });
  const [leadDocCount, setLeadDocCount] = useState(0);
  const [disbursalDocuments, setDisbursalDocuments] = useState([]);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [disburseMentDone, setdisburseMentDone] = useState('0');
  const [newPayment, setNewPayment] = useState(true);
  const [MarkAsLost, setMarkAsLost] = useState(false);
  const [closedReporting, setClosedReportMonth] = useState("")
  const [closedReportingYear, setClosedReportingYear] = useState(2024);
  const [singleTranchId, setSingleTranchId] = useState();

  const [isOpen, setIsOpen] = useState(false);
  let [loading, setIsLoading] = useState(false);
  let [errors, setErrors] = useState({});
  const [activeTab, setActiveTab] = useState("tranchtype_0");
  const { masterdata, lead, remarksHistory } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
    remarksHistory: lead.remarksHistory || []
  }));
  const client = useApolloClient();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { lead_id } = useParams();
  let verifyAccess = IS_USER_ACCESS_PAGE("verify_disbursal", "verify_disbursal")?.is_edit_access;
  let reporting_month = IS_USER_ACCESS_PAGE("reporting_month", "reporting_month")?.is_edit_access; 

  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;
  let gross_code_bank = lead?.leadDetail?.lead_details?.bank?.is_gross_code;

  useEffect(() => {
    if (lead && lead.leadDetail) {
      let disbursedData = lead.leadDetail.splitpayment || [];
      // const exDisDate = lead?.leadDetail?.lead_details?.expected_disbursed_date;
      // const dateObj = {
      //   ...(exDisDate ? { expected_disbursed_date: dateFormat(exDisDate, "yyyy-mm-dd") } : { expected_disbursed_date: "" })
      // }
      // setExpectedDisburseDate(dateObj)
      const splitPayment = disbursedData.map(data => {
        return {
          id: data.id,
          loan_type: lead?.leadDetail?.loan_type || "0",
          tranche_type: lead?.leadDetail?.tranche_type || "0",
          loan_sub_type: lead?.leadDetail?.loan_sub_type ? lead?.leadDetail?.loan_sub_type.split(',') : [],
          product_sub_type: data?.product_sub_type || 0,
          disbursed_id: data.disbursed_id || "",
          disbursed_date: data.disbursed_date || "",
          disbursed_amount: data.disbursed_amount || 0,
          disbursed_tenure: data.disbursed_tenure || 0,
          disbursal_remark: "",
          disbursed_date: data.disbursed_date || "",
          is_tranch_verified: data.is_tranch_verified || "0",
          is_transaction_verified: data.is_transaction_verified || "0",
          disbursed_roi:data.disbursed_roi || 0,
          reporting_month: data.reporting_month || 0,
          reporting_year: data.reporting_year || 2024,
        }
      })
      setDisbursedDetails(splitPayment);
      setdisburseMentDone(lead?.leadDetail?.disbursement_done);
      if (disbursedData.length === 0) disbursalEmptyKey()
      else setNewPayment(false);
    }
    let currentDate = new Date()
    getReportingSttaus(currentDate.getFullYear());
  }, [lead]);

  const first_reporting_month = disburseDetails?.[0]?.reporting_month;
  const first_reporting_year = disburseDetails?.[0]?.reporting_year;

  const getReportingSttaus = async (year) => {
    
      try {  
        let response = await executeGraphQLQuery(CLOSED_REPORTING(1,year),client); 
        if (response.data?.get_closed_reporting_month) { 
          if(response.data?.get_closed_reporting_month.year == null){
            response = await executeGraphQLQuery(CLOSED_REPORTING(1,year-1),client); 
            if(response.data?.get_closed_reporting_month){
              setClosedReportMonth(response.data?.get_closed_reporting_month.months || ""); 
              setClosedReportingYear(response.data?.get_closed_reporting_month.year || 2024)
              return;
            }
          }
          setClosedReportMonth(response.data?.get_closed_reporting_month.months || ""); 
          setClosedReportingYear(response.data?.get_closed_reporting_month.year || 2024)
        }
      } catch (error) { 
        console.log("Error 🔻 ", error.message);
      }
    
  }

  const disbursalEmptyKey = () => {
    const newDisburasalData = [{
      loan_type: lead?.leadDetail?.loan_type || "",
      loan_sub_type: lead?.leadDetail?.loan_sub_type ? lead?.leadDetail?.loan_sub_type.split(',') : [],
      tranche_type: lead?.leadDetail?.tranche_type || "0",
      disbursed_id: "",
      disbursed_date: "",
      disbursed_amount: "",
      disbursed_tenure: 0,
      disbursal_remark: "",
      disbursed_date: "",
      disbursed_roi: 0,
      reporting_month: 0,
      reporting_year:0,
    }];
    setNewPayment(true);
    setDisbursedDetails(newDisburasalData);
  };

  const addMoreFields = () => {
    const newDisburasalData = [...disburseDetails, {
      loan_type: lead?.leadDetail?.loan_type || "",
      loan_sub_type: lead?.leadDetail?.loan_sub_type ? lead?.leadDetail?.loan_sub_type.split(',') : [],
      product_sub_type: "",
      tranche_type: lead?.leadDetail?.tranche_type || "0",
      disbursed_id: "",
      disbursed_date: "",
      disbursed_amount: "",
      disbursed_tenure: 0,
      disbursal_remark: "",
      disbursed_date: "",
      disbursed_roi:0,
      reporting_month: 0,
      reporting_year:0,
    }];
    setNewPayment(true);
    setDisbursedDetails(newDisburasalData);
    setActiveTab(`tranchtype_${newDisburasalData.length - 1}`);
  };

  useEffect(() => {
    let doc_list = [{
      "id": 44,
      "max_upload": 5,
      "min_upload": 1,
      "name": "Disbursed Image"
    }];
    let doc_array = [];
    DisbursalDocsJson && DisbursalDocsJson.map((field) => {
      doc_list?.map(doc => { return (doc.id === field.doc_id) ? doc_array.push(doc) : doc_array.push({}) })
    })
    doc_array = doc_array.filter(obj => Object.keys(obj).length !== 0);
    setDisbursalDocuments(doc_array);
  }, []);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};  
    return options;
  }, [masterdata]);

  const handleInputChange = event => {
    if (event.target.id) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedDisburalDetails = [...disburseDetails];
      updatedDisburalDetails[activetabIndex][event.target.id] = event.target.value;
      setDisbursedDetails(updatedDisburalDetails);
      //setExpectedDisburseDate({ ...disburseDetails, expected_disbursed_date:'' })
    }
  }

  const handleDisbursalDone = event => {
    if (event.target.checked) {
      setdisburseMentDone(event.target.value);
    } else {
      setdisburseMentDone('0');
    }
  }

  const closedMonths = closedReporting.split(",").map(Number);
  const lastClosedMonth = Math.max(...closedMonths);
  const startMonth = lastClosedMonth == 12 ? 1 : lastClosedMonth + 1;
  const startYear = lastClosedMonth === 12 ? closedReportingYear + 1 : closedReportingYear;

  const handlePatternFormatChange = (data, field_name) => {
    if (field_name && data.value !== undefined) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedDisburalDetails = [...disburseDetails];
      updatedDisburalDetails[activetabIndex][field_name] = data.value;
      setDisbursedDetails(updatedDisburalDetails);
    }
  }

  const handleMultiSelectChange = async (n, v, isMulti, opt) => {
    let activetabIndex = activeTab.split('_');
    activetabIndex = activetabIndex[1];
    const updatedDisburalDetails = [...disburseDetails];
    updatedDisburalDetails[activetabIndex][n] = v.map(obj => obj[opt]);
    if(updatedDisburalDetails[activetabIndex][n].length !== 2) updatedDisburalDetails[activetabIndex]['product_sub_type'] = 0;
    setDisbursedDetails(updatedDisburalDetails);
  }

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedDisburalDetails = [...disburseDetails];
      updatedDisburalDetails[activetabIndex][field_name] = date;
      date = new Date(date);
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      // if(month <= lastClosedMonth){
      //   month = lastClosedMonth == 12 ? 1 : lastClosedMonth + 1;
      //   year = lastClosedMonth == 12 ? year + 1 : year;
      // }
      // updatedDisburalDetails[activetabIndex]["reporting_month"] = month;
      // updatedDisburalDetails[activetabIndex]["reporting_year"] = year;
      setDisbursedDetails(updatedDisburalDetails);
    }
  }


  const handleExpectedDisburseDate = (date, field_name) => {
    if (date && field_name) {
      setExpectedDisburseDate(currentValue => ({
        [field_name]: date
      }))
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedDisburalDetails = [...disburseDetails];
      updatedDisburalDetails[activetabIndex][field_name] = +data?.id;
      if(field_name == "reporting_month"){
        if(data.value == "remove-month"){
          updatedDisburalDetails[activetabIndex]["reporting_month"] = 13;
          updatedDisburalDetails[activetabIndex]["reporting_year"] = 13;
        }else{
        const [month, year] = data.value.split("-");
        updatedDisburalDetails[activetabIndex]["reporting_month"] = +month;
        updatedDisburalDetails[activetabIndex]["reporting_year"] = +year;
        }
      }
      setDisbursedDetails(updatedDisburalDetails);
      if(field_name == 'reporting_month') handleSubmit();
    }
  }
  const showModalMarkAsLost = () => {
    setMarkAsLost(true);
    document.body.classList.add("overflow-hidden");
    let activetabIndex = activeTab.split('_');
    activetabIndex = activetabIndex[1];
    let singleTranch = disburseDetails[activetabIndex];
    setSingleTranchId(singleTranch.id);
   // console.log("singleTranch",singleTranch.id);
  };
  const hideModalMarkAsLost = () => {
    setMarkAsLost(false);
    document.body.classList.remove("overflow-hidden");
  };

  const is_disbursal_verified = useMemo(() => {
    let activetabIndex = activeTab.split('_');
    activetabIndex = activetabIndex[1];

   // console.log("disburseDetails",disburseDetails);

    let singleTranch = disburseDetails[activetabIndex];
    let is_tranch_verified = singleTranch?.is_tranch_verified || 0;
    let is_transaction_verified = singleTranch?.is_transaction_verified || 0;
    let split_id = singleTranch?.id || 0;
    let failed_disbursal_status = lead.leadDetail.is_disbursal_verified;

    //console.log("",failed_disbursal_status);
    return (!split_id || [1, 2, 3].includes(lead.leadDetail.status_id) || is_tranch_verified === '1' || is_transaction_verified === "2")  ? true : false;
  }, [activeTab, getContext, activeTab]);

  const isSubmitDisabled = useMemo(() => {
    let activetabIndex = activeTab.split('_');
    activetabIndex = activetabIndex[1];
    let singleTranch = disburseDetails[activetabIndex];
    let is_tranch_verified = singleTranch?.is_tranch_verified || 0;
    return is_tranch_verified === '1' ? true : false
  }, [allowSubmit, getContext, activeTab]);

  const addSplitDisabled = useMemo(() => {
    return lead.leadDetail.tranche_type === '1' || lead.leadDetail.disbursement_done === '1' ? true : false
  }, [allowSubmit, getContext, activeTab]);
  
  const handleReportingMonthSubmit = () => {

  };

  const handleSubmit = () => {
    //  if(expectedDate.expected_disbursed_date && !disbursedAmount ){
    //      handleSubmitExpected()
    // }else{
    let lead_id = lead?.leadDetail?.id || "";
    let disbursedAmount = +(disburseDetails.length && disburseDetails?.map(v => v.disbursed_amount)?.reduce((a, b) => +a + +b) || 0);
    let approved_amount = +(lead?.leadDetail?.lead_details?.approved_amount) || 0;
    if (disbursedAmount > approved_amount) {
      toast.error('Disburse an amount greater than the sanctioned amount, please correct the amount before proceeding.')
      return false;
    }
    // setIsLoading(true);
    let tranche_type = disburseDetails?.[0]?.tranche_type || "";
    let loan_type = disburseDetails?.[0]?.loan_type || "";
    let loan_sub_type = disburseDetails?.[0]?.loan_sub_type?.length ? disburseDetails?.[0]?.loan_sub_type?.join(',') : "";
    let disbursement_done = (tranche_type === 1) ? '1' : disburseMentDone;
    let activetabIndex = activeTab.split('_');
    activetabIndex = activetabIndex[1];
    let saveDisbursalData = disburseDetails[activetabIndex] ? [disburseDetails[activetabIndex]] : [];
    let disbursedRoi=saveDisbursalData.map(data=>data.disbursed_roi)
    let disbursed_roi = disbursedRoi.every(disbursedRoi => disbursedRoi >= 0 && disbursedRoi <= 99);

    if(disbursed_roi){
      setIsLoading(true);
      let reqBody = saveDisbursalData.map((disbural) => {
        return {
          ...(disbural?.id && { id: disbural?.id }),
          lead_id: lead_id,
          product_sub_type: +disbural?.product_sub_type || 0,
          disbursed_amount: disbural?.disbursed_amount,
          disbursed_tenure: disbural?.disbursed_tenure,
          disbursal_remark: disbural?.disbursal_remark,
          disbursed_id: disbural?.disbursed_id,
          disbursed_date: disbural.disbursed_date ? dateFormat(disbural.disbursed_date, "yyyy-mm-dd") : null,
          disbursed_roi: disbural.disbursed_roi || "0",
          is_transaction_verified:disbural.is_transaction_verified === "2" ? "0" : (disbural.is_transaction_verified ||  "0"),
          reporting_month: disbural?.reporting_month ,
          reporting_year: disbural?.reporting_year,
        }
      });

      let mutation = UPDATE_LEAD_STATUS,
        variables = {
          api_called_by: 'web',
          UpdateLeadStatus: {
            tranche_type: tranche_type,
            loan_type: loan_type,
            loan_sub_type: loan_sub_type,
            disbursement_done: disbursement_done,
            update_type: "status",
            status_id: [4],
            lead_id: lead_id,
            user_id: user_id,
            disbursal_form: reqBody,
            callFrom:"updateSideForm"
          }
        };
  
      executeGraphQLMutation(mutation, variables, client).then(resp => {
        let response = resp?.data?.UpdateLeadStatus || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        dispatch(getLoanDetail(lead_id, client))
        setNewPayment(false);
        if (reqBody?.[0]?.disbursal_remark) dispatch((getLeadRemarks({ lead_id: lead_id, source: 'WEB' }, client)))
        setIsLoading(false);
      })
        .catch(err => {
          setIsLoading(false);
          toast.error(err.message);
        })
    } else{
      toast.error('Disbursed roi between 0 and 99.')
      return false;
    }  
  //}
  }

  const handleSubmitExpected = () => {
    let lead_id = lead?.leadDetail?.id || "";
    let mutation = SAVE_CUST_DETAILS(user_id),
      variables = {
        api_called_by: 'web',
        LeadAdditionalInput: {
          lead_id: lead_id,
          lead_details: {
            expected_disbursed_date: expectedDate.expected_disbursed_date? dateFormat(expectedDate.expected_disbursed_date, "yyyy-mm-dd") : null
          }
        }
      };
  
      executeGraphQLMutation(mutation, variables, client).then(resp => {
        let response = resp?.data?.LeadAdditionalInput || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        } 
  
        toast.success("Success");
        // setTouch({})
        dispatch(getLoanDetail(lead_id, client))
  
      })
        .catch(err => {
          toast.error(err.message);
        })
   }

  const MardkDisburedApproved = () => {
    setIsLoading(true);
    let lead_id = lead?.leadDetail?.id || "";
    let activetabIndex = activeTab.split('_');
    activetabIndex = activetabIndex[1];
    let singleTranch = disburseDetails[activetabIndex];
    let split_id = singleTranch?.id || 0;
    let variables = ''
    let mutation = gql`mutation{
      approve_lead(action_type:"disbursal_verify", split_id:${split_id}, lead_id:${lead_id},user_id:${user_id}){
        message
      }
    }`;
    executeGraphQLMutation(mutation, variables, client).then(result => {
      if (result && result.data) {
        setIsLoading(false);
        toast.success('Disbursal Approved');
        dispatch(getLoanDetail(lead_id, client))
      } else {
        setIsLoading(false);
      }
    }).catch((error) => {
      setIsLoading(false);
    });
  }

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const navigateToDocSection = () => {
    let lead_id = lead?.leadDetail?.id || "";
    navigate(`/lead-detail/docs-upload/${crypto.encode(lead_id)}`);
  }

  const handleFileChange = async (event, max_upload) => {
    let lead_id = lead?.leadDetail?.id || "";
    let selectedImage = event && event.target && event.target.files && event.target.files.length && event.target.files[0].name || "";
    let publicUrl = '', docPathArray = '';
    if (!selectedImage) {
      toast.error("Please upload an image.");
      return;
    }
    if (selectedImage) {
      if (errors["disbural_doc_count"]) delete errors.disbural_doc_count;
      if ((max_upload - leadDocCount) > 0) {
        const formData = new FormData();
        formData.append('upload_type', 'dealer_docs');
        formData.append('dealer_id', lead_id);
        for (let imgFile of event.target.files) {
          formData.append("images", imgFile);
        }
        try {
          publicUrl = await GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' });
          if (publicUrl && publicUrl.data && publicUrl.data.data && publicUrl.data.data.length) {
            docPathArray = publicUrl.data.data;
            docPathArray = docPathArray.map(ob => ob.file_url);
            saveDocument(lead_id, docPathArray);
          }
        } catch (error) {
          console.error('Error uploading file:', error);
        }
        setLeadDocCount(leadDocCount + 1);

      }
    }
  }

  const saveDocument = (lead_id, docPathArray) => {
    let leadDocArr = [];
    for (let imgPath of docPathArray) {
      leadDocArr.push({
        lead_id: Number(lead_id),
        doc_id: 44,
        parent_doc_id: 9,
        doc_path: imgPath,
        doc_source: "web",
        customer_type: "customer",
        customer_id: lead?.leadDetail?.customer?.customer_id || 0,
        user_id: authUser && authUser.id || 1,
      })
    }
    let variables = {
      saveDocInput: {
        lead_id: lead_id,
        lead_docs: leadDocArr
      },
      api_called_by: 'web'
    };

    let mutation = gql`mutation save_doc($saveDocInput: SaveDocInput!, $api_called_by: API_CALL_BY!){
        save_doc(saveDocInput: $saveDocInput, api_called_by: $api_called_by) {
          lead_id
          doc_id
          doc_path
        }
    }`;
    executeGraphQLMutation(mutation, variables, client).then(result => {
      if (result && result.data) {
        toast.success('Document uploaded successfully');
        // dispatch(getLoanDetail(lead_id, client));
      }
    })
  }
  const togglePopup = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }
  const sectionInfo = { section: "Disbursal Details", sub_section: "Disbursal Details" }
  let updatedStatusList = lead?.leadDetail?.lead_status_history?.map(data => data.status_id) || [];

  let pageSubmit = lead?.leadDetail?.lead_status_history?.filter(ob=>ob.status_id==4) || [];  
  
  let isDisbursed = lead?.leadDetail?.splitpayment?.filter(ob=>ob.is_tranch_verified === "1");
  const rejectDisable = (lead.leadDetail.is_disbursal_verified === '1' || !pageSubmit.length || lead.leadDetail.status_id == 5 ) ? true : false  

  //console.log("pageSubmit.length",!pageSubmit.length);

  //console.log("rejectDisable",rejectDisable);
  const  IS_LOST = (lead?.leadDetail?.status_id ==5)? true: false   
  const disbursedAmount = +(disburseDetails.length && disburseDetails?.map(v => v.disbursed_amount)?.reduce((a, b) => +a + +b) || 0);
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 90);
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const MONTH_LIST = [
    { "id": 1, "value": "January", "label": "January" },
    { "id": 2, "value": "February", "label": "February" },
    { "id": 3, "value": "March", "label": "March" },
    { "id": 4, "value": "April", "label": "April" },
    { "id": 5, "value": "May", "label": "May" },
    { "id": 6, "value": "June", "label": "June" },
    { "id": 7, "value": "July", "label": "July" },
    { "id": 8, "value": "August", "label": "August" },
    { "id": 9, "value": "September", "label": "September" },
    { "id": 10, "value": "October", "label": "October" },
    { "id": 11, "value": "November", "label": "November" },
    { "id": 12, "value": "December", "label": "December" }
  ];
  const generateMonthYearOptions = (additionalMonth, additionalYear,disbursed_date) => {
    let options = [];
    let month = startMonth;
    let year = startYear;

    while (year < currentYear || (year === currentYear && month <= currentMonth)) {
      options.push({
        label: `${MONTH_LIST.find(m => m.id === month).label} ${year}`,
        value: `${month}-${year}`,
      });
      month++;
      if (month > 12) {
        month = 1;
        year++;
      }
    }
    if (additionalMonth && additionalYear) {
      const additionalOption = {
        value: `${additionalMonth}-${additionalYear}`,
        label: `${MONTH_LIST.find(m => m.id === additionalMonth)?.label} ${additionalYear}`,
      };   
      if (!options.some((item) => item.value === additionalOption.value)) {
        options.unshift(additionalOption);
      }
    }
    options.unshift({
      label: "Default",
      value: "remove-month",
    });
    
    return options;
  };


  return (
    <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
      {loading ? <Loader /> : null}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="44" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
          <div className='loggedinflex'>
          <h2>Disbursed</h2>     
        
         
            {isSubmitDisabled || IS_LOST ? null :<div type="submit" className="updatedbtn" onClick={handleSubmit}>Update</div>}
          </div>
          </div>
          {([4].filter(status_id => updatedStatusList.includes(status_id)).length >= 1) ?
            <div className='loggedinbox' onClick={togglePopup}>
              <div className='loggedinflex'>
                <div style={{ fontSize: 20, fontWeight: "bold" }}>
                  <i class="ic-pending-application"><i class="path1"></i><i class="path2"></i><i class="path3"></i><i class="path4"></i><i class="path5"></i></i>
                </div>
                <div style={{ fontSize: 13, marginLeft: 10 }}>Disbursed checklist</div>
              </div>
              <img src={right} alt='icon' width={10} height={10} />
            </div> : ""}
           
            {/* <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
            >
            <div className="row">
              {EXPECTED_DISBURSAL_DATE.map((field) => (
                field.type === "date-picker" ? (
                  <fieldset class="form-filed col-md-12 ">
                    <FormsyDatePicker
                      name={field.name}
                      selectedDate={expectedDate && expectedDate[field.name] && new Date(expectedDate[field.name])}
                      placeholder={field.label}
                      minDate={new Date()}
                      maxDate={maxDate}
                      value={expectedDate && expectedDate[field.name]}
                      onDateSelect={(date) => handleExpectedDisburseDate(date, field.name)}
                      dateFormat="dd MMM, yyyy"
                      validations={field.validations}
                      validationError={
                        expectedDate[field.name] ? field.validationError : ""
                      }
                      disabled={disbursedAmount}
                      required={field.required}
                      hasError={(expectedDate.hasOwnProperty(field.name) && !expectedDate[field.name]) ? true : false}
                    />
                  </fieldset>
                ) : null
              ))}
            </div>
          </Formsy>  */}

          {/* <h2>Disbursed Details</h2>      */}
          <Nav variant="pills" className="flex-column" style={{ overflow: "hidden", overflowX: "auto" }}>
            <div className="tab-list">
              {disburseDetails.length && disburseDetails.map((splitPaymentDetailData, index) =>
                (disburseDetails[index].tranche_type) ?
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => setActiveTab(`tranchtype_${index}`)}
                      eventkey={`tranchtype_${index}`}
                      active={activeTab === `tranchtype_${index}`}
                    >
                      <b>{`Disbursal-${index + 1}`} {splitPaymentDetailData['disbursed_id']?`(${splitPaymentDetailData['id']})`:""}</b>
                    </Nav.Link>
                  </Nav.Item> :
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => setActiveTab(`tranchtype_${index}`)}
                      eventkey={`tranchtype_${index}`}
                      active={activeTab === `tranchtype_${index}`}
                    >
                      New Tranche
                    </Nav.Link>
                  </Nav.Item>
                 
              )}                
              {/* {addSplitDisabled || newPayment || IS_LOST ? "" : <div className='plusiconstyle' onClick={addMoreFields}>
                <i className='ic-add'></i>
              </div>
              } */}

              { lead?.leadDetail?.disbursement_done==="1" || lead?.leadDetail?.tranche_type=="1" || IS_LOST ? "" : <div className='plusiconstyle' onClick={addMoreFields}>
                <i className='ic-add'></i>
              </div>
              }
            </div>
            
          </Nav>
          

          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">
              {DisbursedDetailsForm.map((field) =>
                disburseDetails.map((splitPaymentDetailData, index) => (`tranchtype_${index}` === activeTab) && (
                  ["text", "pattern-format"].includes(field.type) ? (
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={splitPaymentDetailData && splitPaymentDetailData[field.name]}
                        placeholder=" "
                        label={field.label}
                        maxLength={field.maxLength}
                        onChange={
                          field.type === "pattern-format"
                            ? (data) => handlePatternFormatChange(data, field.name)
                            : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                          splitPaymentDetailData[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                      />
                    </fieldset>
                  ) : ["number-format"].includes(field.type) ? (
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={splitPaymentDetailData && splitPaymentDetailData[field.name]}
                        placeholder=" "
                        thousandsGroupStyle="lakh"
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        label={field.label}
                        maxLength={field.maxLength}
                        onChange={
                          field.type === "number-format"
                            ? (data) => handlePatternFormatChange(data, field.name)
                            : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                          splitPaymentDetailData[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                      />
                    </fieldset>
                  ) :
                    field.type === "date-picker" ? (
                      <fieldset class="form-filed col-md-6 ">
                        <FormsyDatePicker
                          name={field.name}
                          selectedDate={splitPaymentDetailData && splitPaymentDetailData[field.name] && new Date(splitPaymentDetailData[field.name])}
                          placeholder={field.label}
                          minDate={new Date(lead?.leadDetail?.lead_details?.approval_date)}
                          maxDate={new Date()}
                          value={splitPaymentDetailData && splitPaymentDetailData[field.name] ? dateFormat(splitPaymentDetailData[field.name], "dd mmm, yyyy") : null}
                          onDateSelect={(date) => handleDateChange(date, field.name)}
                          dateFormat="dd MMM, yyyy"
                          validations={field.validations}
                          validationError={
                            splitPaymentDetailData[field.name] ? field.validationError : ""
                          }
                          required={field.required}
                          hasError={(splitPaymentDetailData.hasOwnProperty(field.name) && !splitPaymentDetailData[field.name]) ? true : false}
                        />
                      </fieldset>
                    ) :
                      field.type === "dropdown" ? (
                        <fieldset class="single-select col-md-6">
                          <FormsySelect
                            name={field.name}
                            id={field.id}
                            inputProps={{
                              options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                              placeholder: field.label,
                              className: "react-select",
                              classNamePrefix: "react-select",
                              value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ id }) => id == splitPaymentDetailData[field.name]),
                              isDisabled: field.readOnly || (['loan_type','loan_sub_type'].includes(field.name) && activeTab != 'tranchtype_0')
                            }}
                            required={field.required}
                            value={splitPaymentDetailData && splitPaymentDetailData[field.name]}
                            onChange={(data) => handleSelectChange(data, field.name)}
                          />
                        </fieldset>
                      ) : field.type === "multiselect" ? (
                        <fieldset class="single-select col-md-6">
                          <MultiSelect
                            options={ALL_OPTIONS && ALL_OPTIONS[field.optionsKey]}
                            placeholder={field.label}
                            name={field.name}
                            id={field.id}
                            optionLabel="label"
                            optionValue="id"
                            values={splitPaymentDetailData[field.name]}
                            onChange={handleMultiSelectChange}
                            isDisabled={field.readOnly}
                          />
                        </fieldset>
                      ) : field.type === "textarea" ? (
                        <fieldset class="col-md-12">
                          <div class="material">
                            <textarea
                              id={field.id}
                              name={field.name}
                              type={field.type}
                              value={splitPaymentDetailData && splitPaymentDetailData[field.name]}
                              placeholder=" "
                              onChange={handleInputChange}
                              class="form-input"
                            >
                            </textarea>
                            <label
                              data-label={field.label}
                              class="form-label"
                            >
                            </label>
                          </div>
                        </fieldset>
                      ) : (field.type === "radio" && field.name === "tranche_type" && splitPaymentDetailData['loan_sub_type']?.length !== 3)? (
                        <fieldset class="form-filed col-md-12 ">
                          <div style={{ marginBottom: 10 }}>Disbursal Type*</div>
                          <div className='followupradio' style={{ marginBottom: 10 }}>
                            <div className='followupcall'>
                              <input type="radio" required disabled={lead.leadDetail.is_disbursal_verified === '1'} id="tranche_type" name="tranchtype" value="1" checked={(splitPaymentDetailData && splitPaymentDetailData[field.name] == "1") ? true : false} onChange={handleInputChange} />
                              <label htmlFor="single">Single disbursal</label>
                            </div>
                              <div className='followupcall'>
                                <input type="radio" required disabled={lead.leadDetail.is_disbursal_verified === '1'} id="tranche_type" name="tranchtype" value="2" checked={(splitPaymentDetailData && splitPaymentDetailData[field.name] == "2") ? true : false} onChange={handleInputChange} />
                                <label htmlFor="Tranche">Tranche disbursal</label>
                              </div>
                          </div>
                        </fieldset>
                      ) : (field.type === "radio" && field.name === "product_sub_type" && splitPaymentDetailData['loan_sub_type']?.length === 3) ? (
                        <fieldset class="form-filed col-md-12 ">
                          <div style={{ marginBottom: 10 }}>Product Sub Type*</div>

                          <div className='followupradio' style={{ marginBottom: 10 }}>
                            {(splitPaymentDetailData['loan_sub_type'].includes("1")) ? <div className='followupcall'>
                              <input type="radio" required id="product_sub_type" name="product_sub_type" value="1" checked={(splitPaymentDetailData && splitPaymentDetailData[field.name] == "1") ? true : false} onChange={handleInputChange} />
                              <label htmlFor="single">Balance Transfer</label>
                            </div> : ""}
                            {(splitPaymentDetailData['loan_sub_type'].includes("2")) ? <div className='followupcall'>
                              <input type="radio" required id="product_sub_type" name="product_sub_type" value="2" checked={(splitPaymentDetailData && splitPaymentDetailData[field.name] == "2") ? true : false} onChange={handleInputChange} />
                              <label htmlFor="Tranche">Top up</label>
                            </div> : ""}
                          </div>

                        </fieldset>
                      ) : (field.type === "checkbox" && (activeTab === `tranchtype_${disburseDetails.length - 1}`) && splitPaymentDetailData?.tranche_type === "2") ? (
                        <fieldset class="col-md-12 ">
                          <div className='loggedcheck'>
                            {/* <input type='checkbox' onChange={handleDisbursalDone} id={field.id} name={field.name} value='1' disabled={lead?.leadDetail?.disbursement_done === '1'} checked={disburseMentDone === '1' ? true : false} /> */}
                            <input type='checkbox' onChange={handleDisbursalDone} id={field.id} name={field.name} value='1' checked={disburseMentDone === '1' ? true : false} />
                            <label htmlFor="credit">{field.label}</label>
                          </div>
                        </fieldset>
                      ) : field.type === "month-picker" && [1].includes(reporting_month) && splitPaymentDetailData["id"]  && splitPaymentDetailData['disbursed_id']?
                      <fieldset class="single-select col-md-6">
                      <FormsySelect
                            name={field.name}
                            id={field.id}
                            inputProps={{
                              options: generateMonthYearOptions(splitPaymentDetailData[field.name],splitPaymentDetailData["reporting_year"],splitPaymentDetailData["disbursed_date"]),
                              placeholder: field.label,
                              className: "react-select",
                              classNamePrefix: "react-select",
                              value:generateMonthYearOptions(splitPaymentDetailData[field.name],splitPaymentDetailData["reporting_year"]).filter((item) => {
                                let selectedMonthLabel;
                                let selectedYear;
                                if (gross_code_bank === "1" && index !== 0 && false) {
                                  selectedMonthLabel = MONTH_LIST.find(m => m.id === first_reporting_month)?.label;
                                  selectedYear = first_reporting_year;
                                } else {
                                  selectedMonthLabel = MONTH_LIST.find(m => m.id === splitPaymentDetailData[field.name])?.label;
                                  selectedYear = splitPaymentDetailData["reporting_year"];
                                }
                                const formattedValue = `${selectedMonthLabel} ${selectedYear}`;
                                return item.label === formattedValue;
                              }),
                              isDisabled: gross_code_bank === "1" && splitPaymentDetailData["loan_type"] == 1 && index != 0 ? true : ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].some(({ id }) => (id == splitPaymentDetailData[field.name] && closedReporting.split(",").map(Number).includes(splitPaymentDetailData[field.name]) ))
                            }}
                            required={field.required}
                            onChange={(data) => handleSelectChange(data, field.name)}
                          />
                      </fieldset>  : null
                ))
              )
              }
            </div>

            <div className="acco-doc-list">
              {/* <ul>{disbursalDocuments.map((fields) => (
                <li>
                  <div className="img-bx-select-opt">
                    <div className="image-bx more-file-upload">
                      <input type="file" name="" onChange={e => handleFileChange(e, fields.max_upload)} onClick={e => (e.target.value = null)} required={fields.is_required} multiple />
                      <a href='#' onClick={navigateToDocSection}>Verify</a>
                    </div>
                  </div>
                </li>
              ))}
              </ul> */}
              <span style={{ 'display': `${errors["disbursal_doc_count"] ? "block" : "none"}` }}>
                Please Upload Disbursal Docs
              </span>
            </div>

            <div className="btn-save-remarks">
              {/* <button type="submit" className="btn-primary" disabled={isSubmitDisabled || !allowSubmit || blockSubmit}>Update</button> */}
              {/* <button className="btn-primary-outline" disabled={addSplitDisabled || newPayment || IS_LOST} type="button" onClick={addMoreFields}>Add Split Payment</button> */}
              <button type="button" onClick={MardkDisburedApproved} className="btn-primary" disabled={is_disbursal_verified || IS_LOST || !pageSubmit.length || verifyAccess !== 1}>{is_disbursal_verified ? "Verified" : "Verify"}</button>
              { (!is_disbursal_verified && verifyAccess === 1) && (
                <button type="button" onClick={showModalMarkAsLost} className="btn-primary" disabled={rejectDisable}>
                  Verification Failed
                </button>
              )}
              {/* <button type="button" className="btn-primary-outline" onClick={showModalViewTimeLine}>Remarks
                <span className="m-xs-l">({remarksHistory && remarksHistory.length || 0})</span>
              </button> */}
            </div>
          </Formsy>
        </div>
      </div>
      <div className="mark-lost-popup">
        <LostModal MarkAsLost={MarkAsLost} hideModalMarkAsLost={hideModalMarkAsLost} rejection_type ={"ops_reject"}  statusId={LeadStatusService.LEAD_STATUS_MASTER.DISBURSED} bucketId={LeadStatusService.LEAD_STATUS_BUCKET_MASTER.DISBURSED} singleTranchId={singleTranchId}/>
      </div>
      <div className="view-timeline-popup">
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezed} sectionInfo={sectionInfo} />
        </Modal>
      </div>

      <LoggedinPopup isOpen={isOpen} togglePopup={togglePopup}>
        <LoggedinTabs status_id={4} sub_status_id={5} togglePopup={togglePopup} />
      </LoggedinPopup>
    </div>
  );
}

export default DisbursedLead;
