import { gql } from "@apollo/client"

export const Login_User = gql`mutation login_user($username: String!, $password: String!) {
    login_user(username: $username, password: $password) {
      name
      id
      username
      email
      mobile
      message
      report_access
      role_id
      role_names
    }
  }`

  export const VALIDATE_EMAIL = (email) => { 
    return gql`query {
      login_with_email(email : "${email}") {
      access_token
      message
      name
      id
      username
      email
      mobile
      yodha_code
      report_access
      role_id
      role_names
      dialer_email
    }
  }`
  }

  export const VALIDATE_OTP = (mobile,otp,captchaVal) => { 
    return gql`query {
    validate_otp(mobile : "${mobile}", otp: "${otp}" , captcha_val : "${captchaVal}") {
      message
      access_token
      report_access
    }
  }`
}

  export const GET_REDIS_FLAG = () => { 
    return gql`query{
      get_redis_flag{
        isRedis
      }
    }`
  }

  export const GET_CAPTCHA_IMG = () => { 
    return gql`query{
      get_captcha_img{
        message
        image
      }
    }`
  }
  