import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { LEAD_DETAIL_QUERY } from "../../services/leads.gql";
import { executeGraphQLMutation } from "../../common/executeGraphQLQuery";
import { SAVE_REMARKS } from "../../services/leadremarks.gql";
import { GET_ACCESS_FEATURES } from "../../services/role.gql";
import {
  GET_MASTER_DATA,
  GET_MASTER_DATA_MMV,
} from "../../services/masterdata.gql";
import GeneralService from "../../services/generalService";
import {
  LEADS_REMARK_QUERY,
  STATUS_QUERY,
  CHECK_LIST,
} from "../../services/leads.gql";
import { Slide } from "react-toastify";

export const getLoanDetail = (postData, client) => {
  return async (dispatch) => {
    try {
      executeGraphQLQuery(LEAD_DETAIL_QUERY(postData), client)
        .then((response) => {
          if (response && response.data) {
            let data = response.data.get_lead_detail[0];
            if (data) {
              dispatch(success(data));
            }
          } else dispatch(success({}));
        })
        .catch((error) => {
          dispatch(success({}));
        });
    } catch (err) {
      dispatch(success({}));
    }
  };
  function success(payload) {
    return { type: "GET_LEAD_DETAIL", payload };
  }
};

export const getPropertyIdentified = (postData, client) => {
  return async (dispatch) => {
    try {
      executeGraphQLQuery(LEAD_DETAIL_QUERY(postData), client)
        .then((response) => {
          if (response && response.data) {
            let data = response.data.get_lead_detail[0];
            if (data) {
              dispatch(success(data));
            }
          } else dispatch(success({}));
        })
        .catch((error) => {
          dispatch(success({}));
        });
    } catch (err) {
      dispatch(success({}));
    }
  };
  function success(payload) {
    return { type: "GET_LEAD_DETAIL", payload };
  }
};

let activeRequests = {}; // Tracks ongoing requests per lead_id
let cachedRemarks = {};  // Caches responses to avoid redundant API calls

export const getLeadRemarks = (postData, client) => {
  return async (dispatch) => {
    const { lead_id } = postData;

    // If the data is cached, return it immediately without making an API call
    if (cachedRemarks[lead_id]) {
      return dispatch(success(cachedRemarks[lead_id]));
    }

    // If a request for this lead_id is already in progress, skip this call
    if (activeRequests[lead_id]) {
      return;
    }

    // Mark this lead_id as fetching
    activeRequests[lead_id] = true;

    try {
      // Execute the API call
      const response = await executeGraphQLQuery(LEADS_REMARK_QUERY(lead_id), client);
      const data = response?.data?.get_remarks || [];

      // Cache the response for this lead_id
      cachedRemarks[lead_id] = data;

      // Dispatch the successful data to Redux
      dispatch(success(data));
    } catch (error) {
      // Handle errors gracefully
      dispatch(success([]));
    } finally {
      // Remove from activeRequests to allow future calls
      delete activeRequests[lead_id];
    }
  };

  // Action creator for success
  function success(payload) {
    return { type: "GET_LEAD_REMARKS_HISTORY", payload };
  }
};

export const saveLeadRemarks = (postData, client) => {
  return async (dispatch) => {
    try {
      const response = await executeGraphQLMutation(
        SAVE_REMARKS,
        postData,
        client
      );
      if (
        response &&
        response.data.save_remark &&
        response.data.save_remark.lead_id
      ) {
        return {
          status: true,
        };
      } else
        return {
          status: false,
        };
    } catch (err) {
      return {
        status: false,
        response: err,
      };
    }
  };
};
export const getMasterData = (client, vehicle_type = 0) => {
  return async (dispatch) => {
    try {
      executeGraphQLQuery(GET_MASTER_DATA(vehicle_type), client)
        .then((response) => {
          if (response && response.data && response.data.masterdata) {
            let result = response.data.masterdata;
            let keyList = [
              "tenure",
              "agreement_type",
              "profession",
              "salary_credit_mode",
              "property_type",
              "usage_type",
              "loan_type",
              "loan_sub_type",
            ];

            for (let item of keyList) {
              if (result?.[item]?.length) {
                result[item] = result[item]?.map((data) => {
                  data.value = +data.id;
                  data.label = data.label;
                  return data;
                });
              }
            }
            if (result?.bank_list?.length) {
              result.bank_list = result?.bank_list?.map((data) => {
                data.value = data.id;
                data.label = data?.banklang?.bank_name || "";
                return data;
              });
            }
            dispatch(success(result));
          } else dispatch(success({}));
        })
        .catch((error) => {
          dispatch(success({}));
        });
    } catch (err) {
      dispatch(success({}));
    }
  };
  function success(payload) {
    return { type: "GET_MASTER_DATA", payload };
  }
};

export const getUserAccess = (postData, client) => {
  return async (dispatch) => {
    try {
      let data;
      await executeGraphQLQuery(GET_ACCESS_FEATURES(postData), client)
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.get_user_access_features
          ) {
            data = response.data.get_user_access_features;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      if (data && data.length) {
        let formattedUserAccess = [];
        // if feature exist in multiple role for same user (formatting the features array)
        data &&
          data.forEach((item) => {
            let exist_index = formattedUserAccess.findIndex(
              (v) =>
                v.product_type === item.product_type && v.action === item.action
            );
            if (exist_index > -1) {
              // To save '1' if both 1 and 0 are present
              let newAccessType = Math.max(
                Number(item.access_type),
                Number(formattedUserAccess[exist_index]["access_type"])
              );
              formattedUserAccess[exist_index]["access_type"] = newAccessType
                ? newAccessType.toString()
                : "0";
            } else {
              formattedUserAccess.push(item);
            }
          });
        localStorage.setItem("userAccess", JSON.stringify(formattedUserAccess));
        return {
          status: true,
          response: "success",
          data: data,
        };
      } else
        return {
          status: false,
          response: "success",
        };
    } catch (err) {
      return {
        status: false,
        response: err,
      };
    }
  };
};

export const getMasterDataMMV = (client, vehicle_type = 0) => {
  return async (dispatch) => {
    try {
      executeGraphQLQuery(GET_MASTER_DATA_MMV(vehicle_type), client)
        .then((response) => {
          if (response && response.data && response.data.masterdata) {
            dispatch(success(response.data.masterdata));
          } else dispatch(success({}));
        })
        .catch((error) => {
          dispatch(success({}));
        });
    } catch (err) {
      dispatch(success({}));
    }
  };
  function success(payload) {
    return { type: "GET_MASTER_DATA_MMV", payload };
  }
};

export const getMasterCityState = () => {
  return async (dispatch) => {
    try {
      let response = await GeneralService.stateCityList();
      if (response && response.status == "200") {
        let record = response.data.data;
        let results = {
          state: [],
          city: [],
        };
        if (record?.state?.length) {
          results.state = record?.state?.map((data) => {
            data.value = data.id;
            data.label = data.name;
            return data;
          });
        }
        if (record?.city?.length) {
          results.city = record?.city?.map((data) => {
            data.value = data.id;
            data.label = data.name;
            return data;
          });
        }
        dispatch(success(results));
      }
    } catch (err) {
      dispatch(success({}));
    }
  };
  function success(payload) {
    return { type: "GET_STATE_CITY", payload };
  }
};

export const setCityList = (payload) => {
  return async (dispatch) => {
    dispatch({ type: "SET_CITY_LIST", payload });
  };
};

export const getStatusList = (client) => {
  return async (dispatch) => {
    try {
      executeGraphQLQuery(STATUS_QUERY(), client)
        .then((result) => {
          let reasonList = result?.data?.status_list || [];

          dispatch(success(reasonList));
        })
        .catch((error) => {
          dispatch(success({}));
        });
    } catch (err) {
      dispatch(success({}));
    }
  };
  function success(payload) {
    return { type: "GET_STATUS_LIST", payload };
  }
};

export const apiCallCheckList = async (client) => {
  return await executeGraphQLQuery(CHECK_LIST, client)
    .then((response) => {
      // console.log(
      //   "AMB-66 >> allAction.js::apiCallCheckList>>executeGraphQLQuery -> response => ",
      //   response
      // );
      if (response && response.data) {
        if (response.data?.check_list) {
          response.data.check_list = response.data.check_list.map(
            ({ checkListStatus, ...rest }) => ({
              ...rest,
              checklist: checkListStatus, // Change key name
            })
          );
          return response.data.check_list;
        }
      } else {
        throw new Error("Checklist data not found");
      }
    })
    .catch((error) => {
      return null;
    });
};

export const setDispatchData = (type, payload) => {
  return { type, payload };
};
