import { useApolloClient, useMutation } from "@apollo/client";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { SETTING } from "../../config/constants";
import { Login_User, VALIDATE_OTP } from "../../services/login.gql";
import { loggedIn } from '../../store/reducers/userReducer';
import Loader from "../elements/Loader";
import { getUserAccess } from "./../../store/action/allAction";
import Captcha from "./Captcha";
import {USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE} from '../../config/constants';
const EmailLogin = (props) => {
  let {otp, setOtp, LoginData, setLoginData, errorForm, setErrorForm, otpEnabled, setOtpEnabled,isLoading,setIsLoading,parentCallback} = props;
  const client = useApolloClient();
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [captchaVal , setCaptchaVal] = useState('');
  const [reloadCaptcha, setReloadCaptcha] = useState(1);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [timer, setTimer] = useState('00:30');
  const [isAccountBlocked , setIsAccountBlocked] = useState(false)
  const Ref = useRef(null);

  const [loginUser] = useMutation(Login_User);

  const validateInputFields = () => {
    if (email === '') {
      setErrorForm({ ...errorForm, Email: 'error' });
      toast.error('Please enter your email');
      return false;
    }
    if (password === '') {
      setErrorForm({ ...errorForm, Password: 'error' });
      toast.error('Please enter your password');
      return false;
    }
    return true;
  };

  const inputChangeHandler = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const emailRule = /\S+@\S+\.\S+/;
    let updatedErrorForm = {};

    if (name === 'Email') {
      updatedErrorForm = {
        ...errorForm,
        [name]: value === '' || !emailRule.test(value) ? 'error' : '',
      };
      setEmail(value);
    } else if (name === 'Password') {
      updatedErrorForm = {
        ...errorForm,
        [name]: value === '' ? 'error' : '',
      };
      setPassword(value);
    } else {
      updatedErrorForm = {
        ...errorForm,
        [name]: value === '' ? 'error' : '',
      };
    }

    setErrorForm(updatedErrorForm);
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / 1000 * 60 * 60) % 24);
      return {
          total, hours, minutes, seconds
      };
  }

  const startTimer = (e) => {
      let { total, minutes, seconds } = getTimeRemaining(e);
      if (total >= 0) {
          setTimer((minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds))
      }
  }

  const clearTimer = (e) => {
      setTimer('00:01');
      if (Ref.current) clearInterval(Ref.current);
      const id = setInterval(() => {
          startTimer(e);
      }, 100)
      Ref.current = id;
  }

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + SETTING.RESEND_OTP_TIME_IN_SECOND);
    return deadline;
  }

  const submitLogin = () => {
    setIsLoading(true);
    if (validateInputFields()) {
      loginUser({
        variables: {
          username: email,
          password: password
        }
      })
        .then((result) => {
          const loginData = result?.data?.login_user;
          if (loginData && loginData.message === 'Success') {
            setLoginData(loginData);
            dispatch(loggedIn(loginData));
            setOtpEnabled(true);
            clearTimer(getDeadTime());
            toast.success('OTP sent successfully');
          } else {
            toast.error(loginData.message);
            setReloadCaptcha(1 - reloadCaptcha)
          }
        })
        .catch((error) => {
          toast.error("Invalid Username and Password");
          setReloadCaptcha(1 - reloadCaptcha)
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const otpChangeHandler = (event) => {
    setOtp(event.target.value);
    setErrorForm({ ...errorForm, OTP: '' });
  };

  const captchaFiledHandler = (val) => {
    setCaptchaVal(val)
  }

  const handleOTPValidation = () => {
    setIsLoading(true);
    setTimeout(() => {
      executeGraphQLQuery(VALIDATE_OTP(LoginData && LoginData.mobile, otp, captchaVal), client)
        .then(async (res) => {
          if (res.data.validate_otp.message === 'success') {
            let user_data = {
              user_id: LoginData.id,
              name: LoginData.name,
              email: LoginData.email,
              mobile: LoginData.mobile,
              dailer_email:LoginData?.dailer_email
            };
            LoginData = { ...LoginData, access_token: res.data.validate_otp.access_token, user_data: user_data };
            localStorage.setItem('loggedIn', '1');
            localStorage.setItem('user_information', JSON.stringify(LoginData));
            await dispatch(getUserAccess(LoginData.id, client));
            toast.success('OTP Validation Successful');
            setErrorForm({});
            setOtp('');
            setIsLoggedIn(true);
            dispatch(loggedIn());
            parentCallback();
            let lead_dashboard = IS_USER_ACCESS_PAGE("menu", "lead_dashboard")?.is_edit_access;
            let partner_dashboard = IS_USER_ACCESS_PAGE("menu", "partner_dashboard")?.is_edit_access;
            let manage_dashboard =  IS_USER_ACCESS_PAGE("menu", "manage_leads")?.is_edit_access;
            let payouts =  IS_USER_ACCESS_PAGE("menu", "payouts")?.is_edit_access;
            if (lead_dashboard === 1 || lead_dashboard === 2) {
              navigate('/dashboard');
              
 
            } else if (partner_dashboard === 1 || partner_dashboard === 2) {
              navigate('/partner-dashboard');
            }    
            else if (manage_dashboard === 1 || manage_dashboard === 2) {
             navigate('/lead-list');
           }    
           else if (payouts === 1 || payouts === 2) {
             navigate('/schemes/list');
           }    
           else {
            navigate("/dashboard")
           }    
         } else {   
            // toast.error('Account does not exist, inactive, or has been deleted.');
            navigate('/login');
          //   setTimeout(function(){
          //    window.location.reload();
          //  },1500)1
         }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 1000);
  };
  

  return (
    <div className="login-form">
      {!isLoggedIn && (
        <>
          {!otpEnabled && (
            <fieldset className={errorForm.Email ? "error" : ""}>
              <div className="material">
                <input
                  id="email"
                  type="text"
                  placeholder=" "
                  name="Email"
                  className="form-input"
                  onChange={inputChangeHandler}
                />
                <i className="ic-email1 icn-login"></i>
                <label data-label="Email" className="form-label"></label>
              </div>
            </fieldset>
          )}
          {!otpEnabled && (
            <fieldset className={errorForm.Password ? "error" : ""}>
              <div className="material">
                <input
                  id="password"
                  type="password"
                  placeholder=" "
                  name="Password"
                  className="form-input"
                  onChange={inputChangeHandler}
                />
                <i className="ic-password icn-login"></i>
                <label data-label="Password" className="form-label"></label>
              </div>
            </fieldset>
          )}
          {otpEnabled && (
            <fieldset className={errorForm.OTP ? "error" : ""}>
              <div className="material">
                <input
                  id="otp"
                  type="text"
                  placeholder=" "
                  name="OTP"
                  className="form-input"
                  value={otp}
                  onChange={otpChangeHandler}
                />
                <i className="ic-otp icn-login">
                <i className="path1"></i>
              <i className="path2"></i>
              <i className="path3"></i>
              <i className="path4"></i>
                </i>
                <span className="otp-time">{timer}</span>
                <label data-label="OTP" className="form-label"></label>
              </div>
            </fieldset>
          )}
          { isLoading ? <Loader/> : ''}
           {(props.redisFlag && otpEnabled) ?
              <fieldset className="">
                  <Captcha onKeyPressCaptcha={captchaFiledHandler} reloadCaptcha={reloadCaptcha} />
              </fieldset> : ''
            }
          {!otpEnabled ? (
            <button
              type="button"
              disabled={password === '' || email === '' || errorForm.Email || errorForm.Password || isLoading}
              onClick={submitLogin}
              className="btn-primary"
            >
              Log In
            </button>
          ) : null}
          {(otpEnabled) ? (
            <button
              type="button"
              disabled={otp === '' || isLoading }
              onClick={handleOTPValidation}
              className="btn-primary"
            >
              Verify OTP
            </button>):""}
            &nbsp; &nbsp;
            {timer === "00:00" && !isAccountBlocked ?
                <button
                type="button"
                disabled={isLoading}
                onClick={submitLogin}
                className="btn-line"
                >
                Resend OTP
              </button> : null
            }
        </>
      )}
    </div>
  );
};

export default EmailLogin;
