import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../common/partnerExecuteGraphQLQuery";
import secureStorage from "../../config/encrypt";
import { Navigate } from "react-router-dom";
import MultiSelect from "../../view/elements/MultiSelect";
import { FINEX_MASTER_LIST } from "../../services/scheme.gql";
import InputField from "../elements/InputField";
import { MIS_LIST, MIS_UPDATE_DATA, UPLOAD_MIS_LIST } from "../../services/mis.gql";
import Pagination from "../elements/pagination";
import DateFormate from "dateformat";
// import ModalPopup from "../elements/Popup";
import Select from "react-select";
import { InventoryService } from "../../services/index";
import { toast } from "react-toastify";
import Modal from "../elements/Modal";
import { executeGraphQLQueryFinex } from "../../common/executeGraphQLQueryFinex";
import { GET_USER_LIST_PAYOUT } from "../../services/payout.gql";
import { convertJsonToCsv, downloadFile } from "../../helpers/helpers";

const MisList = (props) => {
  const [financers, setFinancers] = useState([]);
  const [misList, setMisList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [bankMappingObj, setBankMappingObj] = useState({});
  const [modalVisiblity, setModalVisiblity] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [uploadedFile, setUploadedFile] = useState(null);
  const [bankId, setBankId] = useState(null);
  const [misUploadList, setMisUploadList] = useState([])
  const [userList, setUserList] = useState([])
  const [selectedRows,setSelectedRows] = useState([])
  const [currentMisId,setCurrentMisId] = useState(null)
  const [uploadLoading,setUploadLoading] = useState(false)
  const [updateMisLoading,setUpdateMisLoading] = useState(false)
  const fileInputRef = useRef(null);

  const afterLoginSetState = (loginResponse) => {
    let role =
      (loginResponse &&
        loginResponse.data &&
        loginResponse.data.user_data &&
        loginResponse.data.user_data.role) ||
      null;
    if (role != "dealercentral") {
      return <Navigate to={"/"} />;
    }
  };

  const updateReduxData = async () => {
    if (secureStorage.getItem("loginUserInfo")) {
      var loginResponse = secureStorage.getItem("loginUserInfo");
      props.updateStateIntoRedux("LOGIN_USER", loginResponse);
      afterLoginSetState(loginResponse);
    } else {
      return <Navigate to={"/login"} />;
    }
  };

  const getFinexMasterData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.masterdata) {
      const bankData = [],
        bankObj = {};
      const data = masterData.data.masterdata;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
          });
          bankObj[banks.banklang.bank_id] = banks.banklang.bank_name;
        });
        setFinancers(bankData);
        setBankMappingObj(bankObj);
      }
    }
  };

  const getMisList = async (filter_params, page_number = 1) => {
    try {
      const variables = { filter_params };
      variables.page_no = page_number
      const response = await executeGraphQLMutation(
        MIS_LIST(page_number),
        variables
      );

      if (response.data?.mis_file_list) {
        const listOfMis = response.data?.mis_file_list?.list || [];
        setMisList(listOfMis);
        setPagination(response.data?.mis_file_list?.pagination || {});
      }
    } catch (error) {
      console.log("Error 🔻 ", error.message);
    }
  };

  const getUsersList = async () => {
    let user_list = [], userlist = [];
    setUserList([])
    const response = await executeGraphQLQueryFinex(GET_USER_LIST_PAYOUT).catch(
      (err) => console.log(err)
    );
        if (response && response.data && response.data.get_user_list) {
            user_list = response.data.get_user_list;
            user_list && user_list.map((v) => {
                userlist.push({ value: v.user_id, label: v.name })
                return v;
            })
            setUserList(userlist);
        }
  
};

  useEffect(() => {
    updateReduxData();
    getFinexMasterData();
    getUsersList()
    getMisList(filterParams);
  }, []);

  const resetFilter = () => {
    window.location.reload();
  };

  const handlePagination = (pageNumber) => {
    getMisList(filterParams, pageNumber);
  };

  const handleModalOpen = () => {
    setModalVisiblity(true);
  };

  const handleModalClose = () => {
    setMisUploadList([])
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset file input
    }
    setModalVisiblity(false);
  };

  const handleMultiSelectChange = (
    fieldName,
    selectedOptArr,
    isMultiSel,
    OptVal,
    OptLabel
  ) => {
    const selectedBanks = selectedOptArr.map((obj) => obj[OptVal]);
    setFilterParams((current) => ({
      ...current,
      bank_id: selectedBanks,
    }));
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFilterParams((current) => ({
      ...current,
      [id]: id == "lead_id" || id == "tranche_id" ? +value : value,
    }));
  };

  const handleFilterApply = () => {
    getMisList(filterParams);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.name.split('.').pop().toLowerCase();
      if (fileType !== 'csv') {
        toast.error('Please upload a valid CSV file.');
        event.target.value = ''; // Reset the input
        return;
      }
    }
    setUploadedFile(file);
  };

  const handleSelectFinancer = (event) => {
    const { id } = event;
    //console.log("id", id)
    setBankId(id);
  };

  const handleUploadFile = async () => {
    try {
      // console.log("uploadedFile",uploadedFile)
      setUploadLoading(true)
      if (uploadedFile) {
        let formData = new FormData();
        formData.append("bank_id", bankId);
        formData.append("upload_type", "finance_docs");
        formData.append("images", uploadedFile);
        formData.append("lead_id", bankId);
        const response = await InventoryService.uploadFilesToS3(formData, {
          "Content-Type": "multipart/form-data",
        });

        if (response.data.status === 200) {
          const fileUrl = response?.data?.data[0]?.file_url;
          const variables = {
            uploadPaymentArgs: {
              csv_file: fileUrl,
              update_for: "mis",
            },
          };

          const resp = await executeGraphQLMutation(UPLOAD_MIS_LIST, variables);

          const csvData = resp.data?.mis_upload?.totalRows
          if(csvData==0){
            setUploadLoading(false)
            return toast.error("No data found in uploaded file")
          }
          const jsonArray = resp.data?.mis_upload?.data || [];
          const dataToDownload = convertJsonToCsv(jsonArray);
          setCurrentMisId(resp.data?.mis_upload?.mis_id)
          if (!csvData) return;
          setMisUploadList(jsonArray);
          setUploadLoading(false)
          toast.success("File uploaded successfully");
          downloadFile(dataToDownload, 'text/csv', 'uploaded_mis_data.csv');

        } else {
          setUploadLoading(false)
          toast.error("Failed to upload");
        }
      } else {
        setUploadLoading(false)
        toast.error("Please select file");
      }
    } catch (error) {
      setUploadLoading(false)
      console.log("Error 🔻 ", error.message);
      toast.error("Internal server error");
    }
  };

  const handleCheckBoxUpdate = (e,id) => {
   const updatedSelectedRows = [...selectedRows]
    if (e.target.checked == true) {
      updatedSelectedRows.push(id);
    } else {
      const index = updatedSelectedRows.indexOf(id);
      if (index !== -1) {
        updatedSelectedRows.splice(index, 1);
      }
    }
    setSelectedRows(updatedSelectedRows);
  };


  const handleSaveMisFile = async() =>{
    try {
      setUpdateMisLoading(true)
      let dataArrayToUpdate = [...misUploadList.filter(item=>selectedRows.includes(item.id)),...misUploadList.filter(item=>item.id==null && item.is_valid==true)]
  
      dataArrayToUpdate = dataArrayToUpdate.flat().map(item => {
        const { __typename, ...rest } = item; 
        return rest;
      });
  
      const variables = {
        updatePaymentArgs: {
          rows: dataArrayToUpdate,
          id: currentMisId,
        },
      };
      const resp = await executeGraphQLMutation(MIS_UPDATE_DATA, variables);
      toast.success(resp.data?.mis_update_data)
      setUpdateMisLoading(false)
      handleModalClose()
      window.location.reload();
    } catch (error) {
      setUpdateMisLoading(false) 
      toast.error("Something went wrong")
    }

  }


  return (
    <>
      <div className="container-fluid">
        {/* <div className="search-wrap">
          <div className="row">
            <div className="col-sm-2 col-md-2">
              <label>Select Bank</label>
              <MultiSelect
                options={financers}
                placeholder={"Banks"}
                name={"financier_id"}
                label="Bank"
                optionLabel="name"
                optionValue="id"
                id="multiSelect"
                values={filterParams["bank_id"] || []}
                onChange={handleMultiSelectChange}
                isMulti
              />
            </div>
            <div className="col sm-2 col-md-2">
              <InputField
                inputProps={{
                  id: "created_at_from",
                  type: "date",
                  name: "created_at_from",
                  label: "Created From",
                }}
                onChange={handleInputChange}
                value={filterParams["created_at_from"] || ""}
              />
            </div>
            <div className="col-sm-2 col-md-2">
              <InputField
                inputProps={{
                  id: "created_at_to",
                  type: "date",
                  name: "created_at_to",
                  autocompleate: "off",
                  label: "Created To",
                }}
                onChange={handleInputChange}
                value={filterParams["created_at_to"] || ""}
              />
            </div>
            <div className="col-sm-2">
            <InputField
                inputProps={{
                  id: "loan_id",
                  type: "text",
                  name: "loan_id",
                  placeholder:"Loan Id",
                  autocompleate: "off",
                  label: "Enter Loan Id",
                }}
                //onChange={handleInputChange}
                value={''}
              />
              <InputField
                type="text"
                name="loan_id"
                id="loan_id"
                label={"Enter Loan Id"}
                placeholder="Loan Id"
                value={""}
                // onChange={handleFilterByDSA}
              /> 
            </div>
            <div className="col-sm-2">
            <InputField
                inputProps={{
                  id: "lead_id",
                  type: "text",
                  name: "lead_id",
                  placeholder:"Lead Id",
                  autocompleate: "off",
                  label: "Enter Lead Id",
                }}
                onChange={handleInputChange}
                value={filterParams["lead_id"] || ""}
              />
              <InputField
                type="text"
                name="lead_id"
                id="lead_id"
                label={"Enter Lead Id"}
                placeholder="Lead Id"
                value={filterParams["lead_id"] || ""}
                onChange={handleInputChange}
              /> 
            </div>
            <div className="col-sm-2 col-md-2">
            <InputField
                inputProps={{
                  id: "tranche_id",
                  type: "text",
                  name: "tranche_id",
                  placeholder:"Tranche Id",
                  autocompleate: "off",
                  label: "Enter Tranche Id",
                }}
                onChange={handleInputChange}
                value={filterParams["tranche_id"] || ""}
              />
               <InputField
                type="text"
                name="tranche_id"
                id="tranche_id"
                label={"Enter Tranche Id"}
                placeholder="Tranche Id"
                value={filterParams["tranche_id"] || ""}
                onChange={handleInputChange}
              /> 
            </div>

            <div className="col-sm-2 col-md-2" style={{ marginTop: 18 }}>
              <div className="btn-submit-reset top-btn-none pull-right">
                <button
                  type="submit"
                  className="btn btn-primary mrg-r10"
                  onClick={handleFilterApply}
                >
                  {"Search"}
                </button>
                <button
                  type="reset"
                  className="btn-reset"
                  onClick={resetFilter}
                >
                  {"Reset"}
                </button>
              </div>
            </div>
          </div>
        </div>*/}


        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems:"center",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <div><h2 className="top-heading">MIS List</h2></div>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
            marginBottom: "5px",
          }}>
            <a style={{ marginRight: "10px" }} className="add-schemebtn" href="https://static.ambak.com/payout/mis_sample_upload_file.csv" target="_blank" >Sample MIS</a>

            <button
              className="add-schemebtn"
              type="button"
              onClick={handleModalOpen}
            >
              Upload MIS
            </button>
          </div>

        </div>

        <div className="view-upload-mis-popup">
          <Modal show={modalVisiblity} handleClose={handleModalClose} width={1400}>
            <>
              <div className="row">
                <div className="col-sm-6 form-group">
                  <label>Upload MIS</label>
                    <input
                      type="file"
                      name="uploadMis"
                      id="uploadMis"
                      accept=".csv"
                      placeholder="Upload MIS"
                      label="Upload CSV"
                      ref={fileInputRef} 
                      onChange={handleFileChange}
                    />
                    <p className="text-muted" style={{ fontSize: "10px", marginTop: "5px",color:"red" }}>
                    * Please upload a CSV file. Ensure the Date format is DD-MM-YYYY.
                    </p>
                </div>
              </div>
              <div className="text-center" style={{ marginTop: 20 }}>
                {uploadedFile && <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleUploadFile}
                >
                  {uploadLoading?"Uploading...":"Upload"}
                </button>}
              </div>
              <div className="clearfix">
                {misUploadList.length ? <div className="card">
                  <div className="table-responsive ">
                  <table className="table table-bordered tablefontsize">
                      <thead>
                        <tr>
                        <th>{"Lead Id"}</th>
                        <th>{"Tranche Id"}</th>
                        <th>{"Bank Name"}</th>
                        <th>{"Partner Id"}</th>
                        <th>{"Payin %"}</th>
                        <th>{"Payin Amount"}</th>
                        <th>{"Payin Type"}</th>
                        <th>{"Payin Status"}</th>
                        <th>{"MIS upload status"}</th>
                        <th>{"Valid/Invalid"}</th>
                        <th>{"Reason Info"}</th>
                        <th>{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {misUploadList.map((point, index) => (
                          <>
                            <tr key={index}>
                              <td>{point.lead_id}</td>
                              <td>{point.tranche_id}</td>
                              <td>{point.bank_name}</td>
                              <td>{point.partner_id}</td>
                              <td>{point.payin_per}</td>
                              <td>{point.payin_amount}</td>
                              <td>{point.payin_type}</td>
                              <td>{point.payin_status}</td>
                              <td>{point.is_duplicate}</td>
                              <td>{point?.is_valid?"Valid":"Invalid"}</td>
                              <td>
                              <div class="tooltip">
                              <div style={{display:"flex"}} class="btn btn-default" title="Mark as Verified"><i className="ic-info-icon1 icons-normal"></i></div>
                                              {point.reason && <span class="tooltiptext">{point.reason}</span>}
                              </div>
                              </td>
                              <td>{point.id ?<input type="checkbox" name="id" label="check" id="id" key="id" checked={selectedRows && selectedRows.length >0 && selectedRows.includes(point.id)} onClick={(e) =>handleCheckBoxUpdate(e,point.id)}/>:null}</td>
                            </tr>
                          </>
                        ))}
                         {(selectedRows.length || misUploadList.filter(item=>item.id==null && item.is_valid==true).length) ? <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleSaveMisFile}
                >
                  {updateMisLoading ? "Please Wait..." : (
                          <>
                            {misUploadList.filter(item => item.id == null && item.is_valid == true).length 
                              ? `Insert ${misUploadList.filter(item => item.id == null && item.is_valid == true).length} ` 
                              : null
                            }
                            {selectedRows.length 
                              ? `${misUploadList.filter(item => item.id == null && item.is_valid == true).length ? " & " : ""} Update ${selectedRows.length} ` 
                              : null
                            } 
                            row
                          </>
                        )
                    }
                </button>:null}
                        {/* <tr>
                    <td colSpan={14}>
                      {" "}
                      <Pagination
                        pageCount={
                          pagination && pagination.totalpage
                            ? pagination.totalpage
                            : 0
                        }
                        // activePage={filterData.page_no ? filterData.page_no : 1}
                        onPaginate={handlePagination}
                      />
                    </td>
                    <td>
                      Total:{" "}
                      {pagination && pagination.totalrecords
                        ? pagination.totalrecords
                        : 0}{" "}
                    </td>
                  </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div> : null}
              </div>
            </>
          </Modal>
        </div>

        <div className="clearfix">
          <div className="card">
            <div className="table-responsive ">
              <table className="table table-bordered tablefontsize">
                <thead>
                  <tr>
                    <th>{"MIS ID"}</th>
                    <th>{"Uploaded Date & Time"}</th>
                    <th>{"Uploaded By"}</th>
                    <th>{"No. of leads & tranches in upload"}</th>
                    <th>{"No. of leads & tranches updated"}</th>
                    <th>{"Uploaded file"}</th>
                    <th>{"Response file"}</th>
                  </tr>
                </thead>
                <tbody>
                  {misList.map((files, index) => (
                    <>
                      <tr key={index}>
                        <td>{files.id}</td>
                        <td>{files.created_at ? DateFormate(files.created_at, "dS mmm yyyy h:MM TT") : null}</td>
                        <td>{userList.find(item=>item.value==files.created_by)?.label||null}</td>
                        <td>Lead : {files.lead_id_count}, Tranche:{files.tranche_id_count}</td>
                        <td>Lead : {files.lead_id_update_count}, Tranche:{files.tranche_id_update_count}</td>
                        <td><div>{files?.csv_file && <a href={files.csv_file} target="_blank">
                          <div className="btn-group btn-group-small " style={{width:"30px"}}>
                            <div
                              className="btn btn-default"
                              title="View"
                            >
                              <i
                                className="ic-pdf icons-normal"
                                style={{
                                  fontSize: 16,
                                  color: "gray",
                                }}
                              ></i>
                            </div>
                          </div>
                        </a>}
                        </div>
                        </td>
                        <td><div>{files?.processed_file && <a href={files.processed_file} target="_blank">
                          <div className="btn-group btn-group-small " style={{width:"30px"}}>
                            <div
                              className="btn btn-default"
                              title="View"
                            >
                              <i
                                className="ic-pdf icons-normal"
                                style={{
                                  fontSize: 16,
                                  color: "gray",
                                }}
                              ></i>
                            </div>
                          </div>
                        </a>}
                        </div>
                        </td>
                      </tr>
                    </>
                  ))}
                  <tr>
                    <td colSpan={5}>
                      {" "}
                      <Pagination
                        pageCount={
                          pagination && pagination.totalpage
                            ? pagination.totalpage
                            : 0
                        }
                        // activePage={filterData.page_no ? filterData.page_no : 1}
                        onPaginate={handlePagination}
                      />
                    </td>
                    <td>
                      Total:{" "}
                      {pagination && pagination.totalrecords
                        ? pagination.totalrecords
                        : 0}{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(MisList)
);
