import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { NavLink, Link, useNavigate } from "react-router-dom";
import {
  USERS_FOR_VIEW_AMBAK_LEADS,
  IS_USER_ACCESS_PAGE,
} from "../config/constants";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import WhatsAppUnreadComponent from "../view/chat/WhatsAppUnread.component";
import { executeGraphQLMutation, executeGraphQLQuery } from "./executeGraphQLQuery";
import { SAVE_REPORT_ACCESS } from "../services/customer.gql";
import Modal from "../view/elements/Modal";
import crypto from "../config/crypto";
import { START_CALLING_QUEUE } from "../services/leadLogic.gql";
import { Button } from "react-bootstrap";
import { getLeadRemarks, setDispatchData } from "../store/action/allAction";
import { useDispatch } from "react-redux";
import { LEAD_DETAIL_QUERY } from "../services/leads.gql";

const Navigation = () => {
  const Ref = useRef(null);
  const dispatch = useDispatch()
  const playQueue = window.location.search.indexOf("_queue") > -1;
  const [isCallingQueVisible, setIsCallingQueVisible] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [queueLeads, setQueueLeads] = useState([]);
  const [activeLeadId, setActiveLeadId] = useState(0);
  const client = useApolloClient();
  const navigate = useNavigate();
  const [saveReportAccess] = useMutation(SAVE_REPORT_ACCESS);
  const handleLogout = () => {
    const user_information = JSON.parse(
      localStorage.getItem("user_information")
    );
    const mutation = gql`
          mutation {
            logout_user(user_id: ${user_information.id})
          }
        `;

    client
      .mutate({
        mutation,
      })
      .then(() => {
        localStorage.clear();
        window.location.href = "/login";
        toast.success("Logged Out");
      })
      .catch((error) => {
        localStorage.clear();
        window.location.href = "/login";
        console.error(error);
      });
  };
  localStorage.setItem("isQueueVisible", isCallingQueVisible);

  //const user_information = useSelector((state) => state.user.user_information);
  //const UserInfo = JSON.parse(user_information)
  let user_info = JSON.parse(localStorage.getItem("user_information"));
  let login_status = user_info?.user_data?.login_status || "available";
  let away_time = user_info?.user_data?.away_time || 0;
  const [setLoginStatus] = useState({
    login_status: login_status,
    away_time: away_time,
  });
  const UserInfo = user_info;
  let userReportAccess = user_info.report_access
    ? JSON.parse(user_info.report_access)
    : {};

  const handleReportClick = async (userId, reportId, reportName) => {
    try {
      if (
        userId === undefined ||
        reportId === undefined ||
        reportName === undefined
      ) {
        toast.error("User ID, Report ID, and Report Name must be provided.");
        return;
      }
      const userIdFloat = parseFloat(userId);
      const reportIdFloat = parseFloat(reportId);

      const mutation = SAVE_REPORT_ACCESS;
      const variables = {
        userId: userIdFloat,
        reportId: reportIdFloat,
        reportName: reportName,
      };
      const response = await executeGraphQLMutation(
        mutation,
        variables,
        client
      );

      const data = response?.data?.save_report_access;

      if (data && data.success) {
        toast.success("Report access saved successfully");
        window.location.href = `/report-view/${reportIdFloat}`;
      } else {
        throw new Error(data?.error || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const changeUserLoginStatus = async (login_status = {}) => {
    if (Object.keys(login_status).length === 0) {
      login_status = { login_status: "available", away_time: 0 };
    }
    let user_info = JSON.parse(localStorage.getItem("user_information"));
    const mutation = gql`mutation{
            update_login_status(user_id:${user_info.user_data.user_id},login_status:"${login_status.login_status}",away_minutes:${login_status.away_time}){
            login_status
            away_minutes
            }
        }`;
    document.dispatchEvent(new MouseEvent("click"));
    if (
      user_info.user_data?.login_status != login_status.login_status ||
      (user_info.user_data?.login_status == login_status.login_status &&
        user_info.user_data?.away_time != login_status.away_time)
    ) {
      client
        .mutate({ mutation })
        .then((res) => {
          if (res?.data) {
            toast.success("Status Changes Successfully");
            if (user_info) {
              user_info.user_data.login_status = login_status.login_status;
              user_info.user_data.away_time = login_status.away_time;
              user_info.user_data.away_start_time = new Date();
              localStorage.setItem(
                "user_information",
                JSON.stringify(user_info)
              );
            }
            setLoginStatus({
              login_status: login_status.login_status,
              away_time: login_status.away_time,
            });
          }
        })
        .catch((err) => {
          toast.error(err.toString());
        });
    }
  };

  useEffect(
    function () {
      if (UserInfo?.user_data?.away_time) {
        let endtime =
          new Date(UserInfo.user_data.away_start_time).getTime() +
          UserInfo.user_data.away_time * 60000;
        let starttime = new Date().getTime();
        if (endtime < starttime) {
          changeUserLoginStatus();
        }
      }
    },
    [UserInfo]
  );

  let lead_dashboard = IS_USER_ACCESS_PAGE(
    "menu",
    "lead_dashboard"
  )?.is_edit_access;
  let partner_dashboard = IS_USER_ACCESS_PAGE(
    "menu",
    "partner_dashboard"
  )?.is_edit_access;
  let manage_dashboard = IS_USER_ACCESS_PAGE(
    "menu",
    "manage_leads"
  )?.is_edit_access;
  let scheme_list = IS_USER_ACCESS_PAGE("menu", "scheme_list")?.is_edit_access;
  let payout_list = IS_USER_ACCESS_PAGE("menu", "payout_list")?.is_edit_access;
  let payout_point = IS_USER_ACCESS_PAGE(
    "menu",
    "payout_point"
  )?.is_edit_access;
  let bank_onboarding = IS_USER_ACCESS_PAGE(
    "menu",
    "bank_onboarding"
  )?.is_edit_access;
  let mis_list = IS_USER_ACCESS_PAGE("menu", "mis_list")?.is_edit_access;
  let payouts = IS_USER_ACCESS_PAGE("menu", "payouts")?.is_edit_access;
  let add_apf_record = IS_USER_ACCESS_PAGE(
    "menu",
    "add_apf_record"
  )?.is_edit_access;
  let start_queue =  IS_USER_ACCESS_PAGE("queue_for_fbm", "queue_for_fbm")?.is_edit_access;

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return [
        hours.toString().padStart(2, "0"),
        minutes.toString().padStart(2, "0"),
        seconds.toString().padStart(2, "0"),
    ].join(":");
};

  const SingleValue = ({ data }) => (
    <div
      style={{
        display: "flex",
        position: "absolute",
        left: 10,
        fontSize: "16px",
        border: "none",
        gap: "10px",
      }}
    >
      {formatTime(timer)}

      <div style={{ marginTop: "1px" }}>
        {/* <img
          src="https://static.ambak.com/saathi/1732603288713.png"
          alt="img"
        /> */}
       <img src={ isTimerRunning ? "https://static.ambak.com/saathi/1732603308604.png" : "https://static.ambak.com/saathi/1732603288713.png"}
        alt={isTimerRunning ? "Pause" : "Play"}
        onMouseDown={(e) => {
          e.stopPropagation();
          handlePlayPause();
        }}
        style={{ cursor: "pointer" }}
        />
      </div>
    </div>
  );

const handlePlayPause = () => {
  if(isTimerRunning){
    setIsTimerRunning(false);
    if (Ref.current) clearInterval(Ref.current);
  }else{
    setIsTimerRunning(true);
    let interval = setInterval(() => {
      setTimer((prev) => prev + 1);
  }, 1000);
  Ref.current = interval;
  }
};

  const queOptions = queueLeads.map((item) => {
    return {
      value: item.lead_id,
      lead_priority: item.priority_id,
      label: (
        <div>
          <div style={{ fontWeight: 500 }}>{`${item.customer_name} (${item.lead_id})`}</div>
          {/* <p>{item.overdue !== null ? `Follow Up Overdue by ${item.overdue} days` : ""}</p> */}
        </div>
      ),
      lead_url: item.lead_url,
    };
  });

  const getLeadDetail =(lead_id) => { 
    if(!lead_id) lead_id = 0;
executeGraphQLQuery(LEAD_DETAIL_QUERY(lead_id),client).then((response) => {
  if (response && response.data) {
    let data = response.data.get_lead_detail[0];
    if (data) {
                let payload = data
      dispatch({ type: "GET_LEAD_DETAIL", payload }); 
                dispatch((getLeadRemarks({ lead_id: lead_id, source: 'WEB' }, client)))
    }
            else{
                let payload = {}
      dispatch({ type: "GET_LEAD_DETAIL", payload }); 
                toast.error("Lead not found")
            }   
  }
        else{
            if(response?.errors?.length)
            toast.error(response?.errors?.[0].message,{
                toastId: 'timeout',
            });  
        }
        
    })
    .catch((error) => { 
        toast.error(error.toString().replace('ApolloError:',''));
    });
}

  const handleCallingQueue = async() => {
    try{
      let user_id = user_info.user_data.user_id;
      const variables = {
        user_id,
        lead_id:null,
        queue:1,
        lead_queue_end_time:null
      }
      const result = await executeGraphQLMutation(
      START_CALLING_QUEUE,variables ,client);
      if (result.data && result?.data?.prioritize_lead?.length > 0) {
        setIsCallingQueVisible(true);
        localStorage.setItem("isQueueVisible", isCallingQueVisible);
        let leads = result?.data?.prioritize_lead || []
        setQueueLeads(leads);
        if (leads[0]?.lead_url) {
          const persistedState = JSON.parse(localStorage.getItem('reduxState'));
          if (persistedState && persistedState?.lead) {
              delete persistedState.lead;
              localStorage.setItem('reduxState', JSON.stringify(persistedState));
            } else {
              console.log('No user data found in localStorage.');
            }
            getLeadDetail(leads[0].lead_id)
          dispatch(setDispatchData("SET_PRIORITIZE_LEAD",leads[0].priority_id)) 
          setActiveLeadId(leads[0].lead_id);
          let initialTimer = 0;
          if (leads[0].lead_queue_start_time) {
              const startTime = new Date(leads[0].lead_queue_start_time).getTime();
              const currentTime = Date.now();
              initialTimer = Math.floor((currentTime - startTime) / 1000);
          }
          if (Ref.current) clearInterval(Ref.current);
          setTimer(initialTimer);
          setTimeout(()=>{
            navigate(leads[0].lead_url);
            setIsTimerRunning(true)
            let interval = setInterval(() => {
              setTimer((prev) => prev + 1);
          }, 1000);
          Ref.current = interval;
          },1500)
        }
      } else if(result?.data?.prioritize_lead?.length == 0) {
        setIsCallingQueVisible(false)
        toast.error("No leads found for calling");
      } else {
        toast.error("Internal Server Error");
      }
    }catch(error){
      console.log(error);
      setIsCallingQueVisible(false);
      localStorage.setItem("isQueueVisible", isCallingQueVisible);
      toast.error(error.message);
    }
  };

  const handleNextCallQueue = async() => {
    try{
      let user_id = user_info.user_data.user_id;
      let lead_id = activeLeadId;
      let lead_queue_start_time = new Date(queueLeads?.[0]?.lead_queue_start_time) || new Date();
      let start_seconds = lead_queue_start_time.getSeconds();
      lead_queue_start_time.setSeconds(start_seconds + timer) ;
      let lead_queue_end_time = lead_queue_start_time.toISOString();
      const variables = {
        user_id,
        lead_id,
        queue:null,
        lead_queue_end_time
      }
      const result = await executeGraphQLMutation(
        START_CALLING_QUEUE,variables ,client);
      if (result.data && result?.data?.prioritize_lead?.length > 0) {
        let leads = result?.data?.prioritize_lead || []
        setQueueLeads(leads);
        if (leads[0]?.lead_url) {
          const persistedState = JSON.parse(localStorage.getItem('reduxState'));
          if (persistedState && persistedState?.lead) {
              delete persistedState.lead;
              localStorage.setItem('reduxState', JSON.stringify(persistedState));
            } else {
              console.log('No user data found in localStorage.');
            }
            getLeadDetail(leads[0].lead_id)
          dispatch(setDispatchData("SET_PRIORITIZE_LEAD",leads[0].priority_id)) 
          setActiveLeadId(leads[0].lead_id);
          let initialTimer = 0;
          if (leads[0].lead_queue_start_time) {
              const startTime = new Date(leads[0].lead_queue_start_time).getTime();
              const currentTime = Date.now();
              initialTimer = Math.floor((currentTime - startTime) / 1000);
          }
          setTimer(initialTimer);
          if (Ref.current) clearInterval(Ref.current);
          setTimeout(()=>{
            navigate(leads[0].lead_url);
            setIsTimerRunning(true)
            let interval = setInterval(() => {
              setTimer((prev) => prev + 1);
          }, 1000);
          Ref.current = interval;
          },1500)
        }
      } else if(result?.data?.prioritize_lead?.length == 0) {
        toast.error("No leads found for calling");
        setIsCallingQueVisible(false)
      } else {
        toast.error("Internal Server Error");
      }
    }catch(error){
      console.log(error);
      setIsCallingQueVisible(false);
      localStorage.setItem("isQueueVisible", isCallingQueVisible);
      toast.error(error.message);
    }
  };
  
  const handleLeadSelection = (selectedOption) => {
    if (selectedOption?.value) {
      const persistedState = JSON.parse(localStorage.getItem('reduxState'));
      if (persistedState && persistedState?.lead) {
          delete persistedState.lead;
          localStorage.setItem('reduxState', JSON.stringify(persistedState));
        } else {
          console.log('No user data found in localStorage.');
        }
        getLeadDetail(selectedOption.value)
      dispatch(setDispatchData("SET_PRIORITIZE_LEAD",selectedOption?.lead_priority)) 
      setActiveLeadId(selectedOption.value);
      setTimeout(()=>{
        navigate(selectedOption.lead_url);
      },1500)
    }
  };

  return (
    <>
      <div className="header-main">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="logo">
            {/* <NavLink to='/dashboard'>Loan BOX</NavLink> */}

            {!playQueue && (
              <>
                {!isCallingQueVisible &&
            (lead_dashboard === 1 || lead_dashboard === 2) ? (
              <li>
                <NavLink to="/dashboard" />
              </li>
            ) : null}
            {isCallingQueVisible ? (
              <img
                src="https://static.ambak.com/saathi/ambak_logo_white.svg"
                alt="logo"
                width={182}
                height={56}
              />
            ) : (
              <NavLink to="/">
                <img
                  src="https://static.ambak.com/saathi/ambak_logo_white.svg"
                  alt="logo"
                  width={182}
                  height={56}
                />
              </NavLink>
            )}
              </>
            )}
          </div>

          <div className="right-panel-naviganion menupanel">
            {!playQueue && (
              <div className="nav">
              <ul>
                {!isCallingQueVisible &&
                (lead_dashboard === 1 || lead_dashboard === 2) ? (
                  <li>
                    <NavLink to="/dashboard">Lead Dashboard</NavLink>
                  </li>
                ) : (
                  ""
                )}
                {!isCallingQueVisible &&
                (partner_dashboard === 1 || partner_dashboard === 2) ? (
                  <li>
                    <NavLink to="/partner-dashboard">Partner Dashboard</NavLink>
                  </li>
                ) : (
                  ""
                )}
                {!isCallingQueVisible && add_apf_record === 1 && (
                  <li>
                    <NavLink to="/apf">APF Finder</NavLink>
                  </li>
                )}
                {/* {manage_dashboard === 1 || manage_dashboard === 2 ?   <li><NavLink to="/lead-list">Manage Leads</NavLink> </li>: ""} */}

                {!isCallingQueVisible &&
                (manage_dashboard === 1 || manage_dashboard === 2) ? (
                  <li className="dropdownmenu">
                    <NavLink
                      to={{ pathname: "/lead-list" }}
                      isActive={(match, location) => {
                        if (location.pathname == "/lead-list") {
                          return true;
                        }
                      }}
                    >
                      Manage Leads<span className="caret"></span>
                    </NavLink>
                    <ul className="submenu">
                      <li>
                        <Link to="/lead-list">All Leads</Link>
                      </li>
                      {start_queue === 1 && (
                        <li>
                          <Link onClick={() => handleCallingQueue()}>
                            Start Queuing
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                ) : (
                  ""
                )}

                {!isCallingQueVisible && (payouts === 1 || payouts === 2) ? (
                  <li className="dropdownmenu">
                    <NavLink
                      to={{ pathname: "/schemes/list" }}
                      isActive={(match, location) => {
                        if (
                          location.pathname == "/schemes" ||
                          location.pathname == "/schemes/list" ||
                          location.pathname == "/schemes/addscheme"
                        ) {
                          return true;
                        }
                      }}
                    >
                      Payouts<span className="caret"></span>
                    </NavLink>
                    <ul className="submenu">
                      <li>
                        {scheme_list === 1 && (
                          <Link to="/schemes/list">Scheme List</Link>
                        )}
                      </li>
                      <li>
                        {payout_list === 1 && (
                          <Link to="/payout/list">Payout List</Link>
                        )}
                      </li>
                      <li>
                        {payout_list === 1 && (
                          <Link to="/payout/leadwisescheme">Leadwise Scheme</Link>
                        )}
                      </li>
                      {/* <li>
                                                    {payout_point === 1 && ( <Link to="/payout/points">Payout Points</Link>)}
                                                   
                                                </li> */}
                      <li>
                        {bank_onboarding === 1 && (
                          <Link to="/bank/bank-onboarding">
                            Bank Onboarding
                          </Link>
                        )}
                      </li>
                      {/* <li>
                                                    {bank_onboarding === 1 && (<Link to="/bank/bank-onboarding">Bank</Link>)}
                                                    
                                                </li> */}
                      <li>
                        {mis_list === 1 && (
                          <Link to="/payout/mis-list">MIS List</Link>
                        )}
                      </li>
                      <li>
                        {mis_list === 1 && (
                          <Link to="/schemes/banklist">Bank Schemes</Link>
                        )}
                      </li>
                    </ul>
                  </li>
                ) : (
                  ""
                )}

                {start_queue === 1 &&
                  isCallingQueVisible &&
                  queueLeads?.length > 0 && (
                    <div className="calling-que-container">
                      <Select
                        className="current-call"
                        options={queOptions}
                        components={{ SingleValue }}
                        // onChange={(selectedOption) =>
                        //   handleLeadSelection(selectedOption)
                        // }
                        value={queOptions.find(
                          (option) => option.value === activeLeadId
                        )}
                      />
                      <button
                        className="next-call"
                        onClick={() => handleNextCallQueue()}
                        style={{ cursor: "pointer" }}
                      >
                        Next
                      </button>
                    </div>
                  )}

                {/* <li><NavLink to="/role-list">Role Management</NavLink> </li> */}
                {userReportAccess &&
                userReportAccess.report_access &&
                userReportAccess.report_access.length > 0 ? (
                  <li className="dropdownmenu">
                    <NavLink>Report</NavLink>
                    <ul className="submenu">
                      {userReportAccess.report_access.map((report, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            handleReportClick(
                              user_info.id,
                              index,
                              report.label
                            );
                            window.location.href = `/report-view/${index}`;
                          }}
                        >
                          {report.label}
                        </li>
                      ))}
                      <li>
                        <Link to="/report/reporting-month">
                          Reporting Month
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : null}

                {/* <li className="dropdownmenu"><NavLink>Manage Panel</NavLink>
                                    <ul className="submenu">
                                        <li><NavLink to="/lead-list">Lead List</NavLink> </li>
                                    </ul>
                                </li>
                               <li className="dropdownmenu"><NavLink>Rule Engine</NavLink>
                                    <ul className="submenu">
                                        <li><NavLink to="/rule-engine">Rule Engine</NavLink> </li>
                                    </ul>
                                </li>
                                <li className="dropdownmenu"><NavLink>More</NavLink>
                                    <ul className="submenu">
                                        {IS_USER_ACCESS_PAGE('role_management','role_management').is_access_page && 
                                            <li><NavLink to="/role-list">Role Management</NavLink> </li>}
                                        {IS_USER_ACCESS_PAGE('financier','financier').is_access_page && 
                                            <li><NavLink to="/financer">Financier List</NavLink> </li>}
                                    </ul>
                                </li> */}
              </ul>
            </div>
            )}
            
            <div className="user-detail ">
              <ul>
                <li className="dropdownmenu">
                  <NavLink to="#">
                    <span className="userarc">
                      {UserInfo && UserInfo.name?.[0]}
                    </span>
                    <div className="username">
                      <span>{UserInfo && UserInfo.name}</span>
                      <span className="user-degintion">
                        {UserInfo?.role_names?.join(",") || ""}
                      </span>
                    </div>
                  </NavLink>
                  <ul className="submenu">
                    <li>
                      <NavLink to="#" onClick={handleLogout}>
                        Logout
                      </NavLink>{" "}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            {/*<div className="user-status">
                        <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                <div className="status">
                                    <i className={`active-status ${LoginStatus.login_status === 'away' ? 'away-icon' : ''} `}></i>
                                    <div className="status-txt">
                                        <span>{LoginStatus.login_status == 'available' ? 'Active' :
                                            (LoginStatus.login_status == 'offline' ? 'Inactive' : 'Away')}</span>
                                        <span className="sub-status"></span>
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <ul className="satus-drop-downlist">
                                    <li className="cursor-pointer">
                                        <i className={LoginStatus.login_status == 'available' ? 'active-status' : 'inactive-status'}></i>
                                        <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'available', away_time: 0 })}>
                                            Active
                                        </span>
                                    </li>
                                    <li className="">
                                        <i className={LoginStatus.login_status == 'away' ? 'active-status' : 'inactive-status'}></i>
                                        <span className="">Set as Away</span>
                                        <ul className="away-time-stamp">
                                            <li className={LoginStatus.login_status == 'away' && LoginStatus.away_time == 30 ? 'cursor-pointer active' : 'cursor-pointer'}>
                                                <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'away', away_time: 30 })}>
                                                    30 Minutes
                                                </span>
                                            </li>
                                            <li className={LoginStatus.login_status == 'away' && LoginStatus.away_time == 60 ? 'active' : 'cursor-pointer'}>
                                                <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'away', away_time: 60 })}
                                                >
                                                    1 Hour
                                                </span>
                                            </li>
                                            <li className={LoginStatus.login_status == 'away' && LoginStatus.away_time == 120 ? 'cursor-pointer active' : 'cursor-pointer'}>
                                                <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'away', away_time: 120 })}
                                                >2 Hours
                                                </span>
                                            </li>
                                            <li className={LoginStatus.login_status == 'away' && LoginStatus.away_time == 240 ? 'cursor-pointer active' : 'cursor-pointer'}>
                                                <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'away', away_time: 240 })}
                                                >4 Hours</span>
                                            </li>
                                            <li className={LoginStatus.login_status == 'away' && LoginStatus.away_time == 480 ? 'cursor-pointer active' : 'cursor-pointer'}>
                                                <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'away', away_time: 480 })}
                                                >8 Hours</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                        </div>*/}
            {/* <div className="language">
                            <ul>
                                <li className="dropdownmenu">
                                    <NavLink to="#">
                                        ENG
                                    </NavLink>
                                    <ul className="submenu">
                                     
                                    </ul>
                                </li>

                            </ul>
                        </div> */}
          </div>
        </div>
      </div>
      <WhatsAppUnreadComponent />
    </>
  );
};

export default Navigation;

