import React from "react";

const PartnerHistoryTab = ({ data }) => {
  return (
    <div className="pre-lead-activity">
      <table className="activity-log-table">
        <thead>
          <tr>
            <th>Activity Name</th>
            <th>Comment</th>
            <th>Source</th>
            <th>Activity Date</th>
            <th>Activity Done By</th>
          </tr>
        </thead>
        <tbody>
          {data.length
            ? data.map((log, index) => (
                <tr key={index}>
                  {console.log("log" , log)}
                  <td>
                    <b>{log.logm_activity_name}</b>
                    {log.logm_activity_name == "Form Edited" && (
                      <span className="activity-details">
                        <strong>Previous:</strong> {log.log_prev_value || "N/A"}
                        <br />
                        <strong>Current:</strong>{" "}
                        {log.log_current_value || "N/A"}
                      </span>
                    )}
                  </td>
                  <td>{log.comment}</td>
                  <td>{log.log_source}</td>
                  <td>{new Date(log.log_created_date).toLocaleString()}</td>
                  <td>
                    <span className="activity-details">
                      <strong>Name: </strong>
                      {log.log_created_by_name}
                      <br />
                      <strong>Email: </strong>
                      {log.log_created_by_email}
                    </span>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
      {!data.length && (
        <div style={{ display: "flex", justifyContent: "center", padding: 40 }}>
          No Activity found
        </div>
      )}
    </div>
  );
};

export default PartnerHistoryTab;
