import React, { useEffect, useRef, useState } from "react";
import {
  GET_TICKETS,
  CREATE_TICKETS,
  GET_TICKETS_CONVERSATION,
} from "../../services/threads.gql";
import {
  executeGraphQLQuery,
  executeGraphQLMutation,
} from "../../common/executeGraphQLQuery";
import { useApolloClient } from "@apollo/client";
import { useSelector } from "react-redux";
import { freshdeskApisUrl, headersConfig, signatureMobile } from "../../config/constants";
// import replyicon from "./../../webroot/images/reply-icon.png";
// import refresh from "./../../webroot/images/refresh-icon.png";
import dateFormat from "dateformat";
import { toast } from "react-toastify";
// import Modal from "../elements/Modal";
import axios from "axios";
import { CKEditor } from "ckeditor4-react";
import CommonService from '../../services/common.service';
import { CHAT_TEMPLATE_LIST } from "../../services/leads.gql";
// import TemplateList from "../chat/TemplateList";
// ---------------------------- added -------------------------------
// import redtic from "./../../webroot/images/Ellipse3799.svg";
import attachmentsicon from "./../../webroot/images/attachment-icon.svg";
import docdummyimg from "./../../webroot/images/docdummyimg.png";
import FormsySelect from "../elements/FormsySelect";
import Formsy from "formsy-react";
import bin from "./../../webroot/images/Bin.svg";
import crosssvg from "./../../webroot/images/crosssvg.svg";
import replyimg from "./../../webroot/images/replysvg.svg";
// import forwardimg from "./../../webroot/images/forwardsvg.svg";
import backsvg from "./../../webroot/images/back.svg";
import EmptyFillSomething from "./../../webroot/images/EmptyFillSomething.svg";
// import pdfimg from "./../../webroot/images/PDFIcon.svg";
// import downloadpdf from "./../../webroot/images/downloadpdf.svg";
// import addimg from "./../../webroot/images/addtosngm.svg";
// import fordwardimg from "./../../webroot/images/forwadsvg.svg";
// --------------------------------------------------------end

const EmailThread = (props) => {

  const client = useApolloClient();
  const [activeTab, setActiveTab] = useState(null);
  const [ticketCreate, setTicketCreate] = useState({});
  const [ticket, setTicket] = useState({});
  const [tickets, setTickets] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsOriginal, setTagsOriginal] = useState([]);
  const [showEmail, setShowEmail] = useState(false);
  const [sendMailDisabled, setSendMailDisabled] = useState(true);
  const [modalName, setModalName] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [selectedFileCount, setSelectedFileCount] = useState(null);
  const [conversation, setConservation] = useState([]);
  const [defaultConversation, setDefaultConversation] = useState([]);
  const [emailDetails, setEmailDetails] = useState({
    emailFrom: props?.formType === 'customer' ? "communication@ambak.com" : "fulfilment@ambak.com",
    configEmailId: props?.formType === 'customer' ? 1 : 2,
    emailDescription: "",
  });
  const [listOfTicketIds, setListOfTicketIds] = useState([]);
  const [listOfTickets, setListOfTickets] = useState(null);
  const [tabEmailData, setTabEmailData] = useState(null);
  const [list, setList] = useState([]);
  const [showtemplate, setShowTemplate] = useState(false);
  const [inputField, setField] = useState({ chat_type: 'TEXT', mode_type: 1, message: '', files: '' });
  // -------------------------------Added-------------------------------->
  const [activeEmailTab, setActiveEmailTab] = useState("customer");
  const [showDetailedView, setShowDetailedView] = useState(false);
  const [loanDetailData, setLoanDetailData] = useState({ compose_option: null });
  const [showComposePopup, setShowComposePopup] = useState(false);
  const [showReplyEmail, setShowReplyEmail] = useState(false);
  // const [showforwardEmail, setShowforwardEmail] = useState(false);
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  // const [showCcInput, setShowCcInput] = useState(false);
  // const [ccTags, setCcTags] = useState([]);
  // const [emailDetails, setEmailDetails] = useState({ emailTo: "", emailCc: "" });
  // --------------------------------------------------------------end




  const { lead, userInfo } = useSelector(({ lead, user }) =>
    ({ lead, userInfo: (user.user_information) ? JSON.parse(user.user_information) : {} }));

  let user_info = JSON.parse(localStorage.getItem("user_information"));
  const editorRef = useRef(null);


  const fetchData = async (lead_id, ticket_id = 0, returnRes = false) => {
    let res = null;
    setTimeout(async () => {
      await executeGraphQLQuery(GET_TICKETS(lead_id, ticket_id), client)
        .then((response) => {
          if (response?.data?.get_email_tickets.length > 0) {
            const listOfIds = [];
            response?.data?.get_email_tickets.map((ticket) =>
              listOfIds.push(+ticket.ticket_id)
            );
            if (!returnRes) {
              setListOfTicketIds(listOfIds);
              setListOfTickets(response?.data?.get_email_tickets);
            }
            res = response?.data?.get_email_tickets;
          }
        })
        .catch((error) => { });
    }, 1000)

    if (returnRes) return res
  };

  const fetchTicketData = async (ticket_id) => {
    const res = { status: false, cnt: 0 }
    if (listOfTicketIds.includes(ticket_id)) {
      await executeGraphQLQuery(GET_TICKETS_CONVERSATION(ticket_id), client)
        .then((response) => {
          if (response?.data?.get_all_tickets_from_freshdesk) {
            const jsonData = JSON.parse(
              response?.data?.get_all_tickets_from_freshdesk
            );
            setConservation(jsonData);
            if (jsonData.length > 0) {
              setTicket(jsonData[0]);
            } else {
              setTicket({ ticket_id });
            }
            res.status = true
            res.cnt = jsonData.length;
          }
        })
        .catch((error) => console.error(error.message));
    }
    return res;
  };



  const fetchAllTickets = async (callActiveTab = true) => {
    try {
      if (tagsOriginal && Array.isArray(tagsOriginal) && tagsOriginal.length) {
        const response = await axios.get(
          `${freshdeskApisUrl}?email=${tagsOriginal[0]}`,
          {
            headers: headersConfig,
          }
        );
        const filteredData = response.data.filter(ticket => listOfTicketIds.includes(ticket.id));
        setTickets(filteredData);
        // setListOfTickets(filteredData);
        if (filteredData.length > 0 && callActiveTab) {
          setActiveTab(filteredData[0].id);
          fetchTicketData(filteredData[0].id);
          setEmailDetails((current) => ({
            ...current,
            emailDescription: "",
            emailSubject: "",
          }));
          setAttachments(null);
          setSelectedFileCount(null);
        }
      }
    } catch (error) {
      console.error("Error fetching ticket data:", error);
    }
  };



  useEffect(() => {
    if (lead?.leadDetail?.id) {
      fetchData(lead.leadDetail.id);
    }
  }, [lead]);
  useEffect(() => {
    if (props?.formType === "bank") {
      setActiveEmailTab("bank");
    } else {
      setActiveEmailTab("customer");
    }
  }, [props?.formType]);


  useEffect(() => {
    getTemplateList(lead?.leadDetail?.id, userInfo?.name, "", userInfo?.id, "false", "true");
  }, [lead.leadDetail?.id]);


  useEffect(() => {
    if (tagsOriginal && Array.isArray(tagsOriginal) && tagsOriginal.length) {
      fetchAllTickets()
    }
  }, [tagsOriginal]);

  useEffect(() => {
    if (props?.emailForComs) {
      let emailIdTags = [...tags]
      if (props?.emailForComs) {
        const propsEmailForComs = props?.emailForComs.split(",")
        if (propsEmailForComs.length) {
          emailIdTags = [...emailIdTags, ...propsEmailForComs];
        }
      }
      if (emailIdTags.length) {
        setEmailTags(emailIdTags, true);
      }
    }
  }, [props?.emailForComs]);

  useEffect(() => {
    fetchAllTickets();
  }, [listOfTicketIds]);

  const getTemplateList = (lead_id, username, tempelate_type, user_id, is_whatsapp, is_email) => {
    executeGraphQLQuery(CHAT_TEMPLATE_LIST(lead_id, username, tempelate_type, user_id, is_whatsapp, is_email), client).then((result) => {
      setList(result?.data?.chat_template_list);
    }).catch((error) => { });
  };

  const setEmailTags = (emailIds, setOriginal = false) => {
    if (typeof emailIds === 'string' && emailIds.length) {
      emailIds = emailIds.split(",");
    }
    if (typeof emailIds === 'object' && Array.isArray(emailIds) && emailIds.length) {
      const emailIdsUnq = [...new Set(emailIds)];
      setTags([...new Set(emailIdsUnq)]);
      if (setOriginal) {
        setTagsOriginal([...new Set(emailIdsUnq)]);
      }
    }
  }

  const handleTabClick = async (ticketId, ticketCreatedAt, ticketSubject) => {
    setActiveTab(ticketId);
    const convFetched = await fetchTicketData(ticketId);
    setEmailDetails((current) => ({
      ...current,
      emailDescription: "",
      emailSubject: "",
    }));
    setAttachments(null);
    setSelectedFileCount(null);

    let emailData = null;
    const fetchInitialTicket = await fetchData(lead.leadDetail.id, ticketId, true);
    if (fetchInitialTicket && fetchInitialTicket?.length) {
      emailData = fetchInitialTicket[0];
    } else {
      emailData = CommonService.objectDataByKeyValue(listOfTickets, 'ticket_id', `${ticketId}`)
      if (!emailData) {
        emailData = CommonService.objectDataByKeyValue(listOfTickets, 'id', `${ticketId}`)
      }
    }

    const defaultConversationData = {}
    if (emailData?.id) {
      emailData.created_at = dateFormat(ticketCreatedAt, "d mmm,yyyy hh:MM")
      emailData.subject = ticketSubject
      setTabEmailData(emailData);

      if (emailData?.ticket_initial_body?.length) {
        const ticketInitialBodySplit = emailData.ticket_initial_body.split('<!DOCTYPE html>');
        if (ticketInitialBodySplit.length) {
          defaultConversationData.body_text = ticketInitialBodySplit[0];//.replace(/<(?:.|\n)*?>/gm, '');
          if (defaultConversationData.body_text && (/<p>.*?<\/p>/i.test(defaultConversationData.body_text))) {
            defaultConversationData.body_text = defaultConversationData.body_text
              .replace(/<p>/g, '')
              .replace(/<\/p>/g, '<br />')
              .trim();
          }
        }
      }

      if (emailData?.ticket_initial_to_emails?.length) {
        const toEmails = emailData.ticket_initial_to_emails.split(',');
        if (toEmails.length) {
          defaultConversationData.toEmails = toEmails;
        }
      }
    }
    if (defaultConversationData?.toEmails && defaultConversationData?.body_text) {
      setDefaultConversation([{
        to_emails: defaultConversationData?.toEmails,
        body_text_initial: defaultConversationData?.body_text,//.replace(/<(?:.|\n)*?>/gm, ''),
        attachments: [],
      }])
    }
    setShowDetailedView(true);
    setShowComposePopup(false);
    setSelectedEmailId(ticketId);
  };

  const handleSubmit = async () => {
    if (!emailDetails?.emailSubject) {
      toast.error("Email Subject is missing!!");
      return;
    }
    let confirm = window.confirm(
      "Are you sure you want to send this E-Mail from the official Ambak ID?"
    );
    if (confirm) {
      if (modalName === "reply") {
        let formData = new FormData();
        formData.append(
          "body",
          emailDetails.emailDescription +
            `<!DOCTYPE html> 
            <html> 
            <head> </head> 
            <body> 
              <div dir='ltr' class='gmail_signature'> 
                <div dir='ltr'> 
                  <table style='color:rgb(0,0,0);font-family:Poppins,sans-serif;font-size:medium'> 
                    <tbody> 
                      <tr> 
                        <td style='font-size:12px'>
                          <span style='font-weight:600'>${user_info.name} |&nbsp;</span>
                          <font color='#0000ff'>Team Ambak</font>
                        </td> 
                      </tr> 
                      <tr style='display:flex;margin-top:5px'> 
                        <td style='margin-top:5px;display:flex'>
                          <a href='https://ambak.com/' style='color:rgb(17,85,204)' rel='noreferrer' target='_blank' data-saferedirecturl='https://www.google.com/url?q=https://ambak.com/&amp;source=gmail&amp;ust=1722318248056000&amp;usg=AOvVaw0tVwaNZz23ZIBv946jMbP4'>
                            <img src='https://ci3.googleusercontent.com/mail-sig/AIorK4yr2uCiizuiiSDXWwyUkGzq5kxyZyUukGNxRoJo9ssMOkiCu3O1wlYMu0pF9YxSTGFrMxMISv0' alt='faviconicon' width='45px' height='66px' style='margin-right:10px;border-right:1px solid lightgray;padding-right:10px' class='CToWUd' data-bit='iit'>
                          </a>
                        </td> 
                        <td> 
                          <div>
                            <a href='https://www.facebook.com/AmbakOfficial/' style='color:rgb(17,85,204)' rel='noreferrer' target='_blank' data-saferedirecturl='https://www.google.com/url?q=https://www.facebook.com/AmbakOfficial/&amp;source=gmail&amp;ust=1722318248056000&amp;usg=AOvVaw3-Qy2iDLy5mtTskTWBMYTF'>
                              <img src='https://ci3.googleusercontent.com/mail-sig/AIorK4xSv-h7oO1Ed44E9gjFnC_GcBGYaZ-87Rac2ozCexb62wxrM0IZfOC0Vh0mIwFgFCXTelcyAg8' alt='facebook' width='20px' height='20px' class='CToWUd' data-bit='iit'>
                            </a>&nbsp;
                            <a href='https://www.instagram.com/ambakofficial' style='color:rgb(17,85,204)' rel='noreferrer' target='_blank' data-saferedirecturl='https://www.google.com/url?q=https://www.instagram.com/ambakofficial&amp;source=gmail&amp;ust=1722318248056000&amp;usg=AOvVaw3O7fttVJo-lYNNmAnuknyZ'>
                              <img src='https://ci3.googleusercontent.com/mail-sig/AIorK4wh17UsbHaAdKESK_aDsw9mxSBCjYAoaCqNMbR7fWqAZIbuVNe9V2T1xseYmx18VGGsZ5jlh7U' alt='insta' width='20px' height='20px' class='CToWUd' data-bit='iit'>
                            </a>&nbsp;
                            <a href='https://www.linkedin.com/company/ambakofficial' style='color:rgb(17,85,204)' rel='noreferrer' target='_blank' data-saferedirecturl='https://www.google.com/url?q=https://www.linkedin.com/company/ambakofficial&amp;source=gmail&amp;ust=1722318248056000&amp;usg=AOvVaw2b2Fh1sb0gkUYkbu9RAv9s'>
                              <img src='https://ci3.googleusercontent.com/mail-sig/AIorK4wzbTmMt3ZRcaopcWFz5H9_lEXPXx7Ytl1stQ-49mCG_88MgScIFqZsDA63BUYh9Kk-VLutvAE' alt='link' width='20px' height='20px' class='CToWUd' data-bit='iit'>
                            </a>&nbsp;
                            <a href='https://www.youtube.com/@AmbakOfficial' style='color:rgb(17,85,204)' rel='noreferrer' target='_blank' data-saferedirecturl='https://www.google.com/url?q=https://www.youtube.com/@AmbakOfficial&amp;source=gmail&amp;ust=1722318248056000&amp;usg=AOvVaw1Z5_-JRGGpkgiZkhpJZU5n'>
                              <img src='https://ci3.googleusercontent.com/mail-sig/AIorK4we4kDPB3fXLe3NusrE8z94bZyUpd0If6qwcu6gGtv9A7-WZrQKURPQbptNFo-Zke_V51QZ03s' alt='link' width='20px' height='20px' class='CToWUd' data-bit='iit'>
                            </a>
                          </div> 
                        </td> 
                      </tr> 
                    </tbody> 
                  </table> 
                  <div style='text-align:left'><br></div> 
                </div> 
              </div> 
            </body> 
            </html>`
        );
                if (attachments) {
          for (let file of attachments) {
            formData.append("attachments[]", file);
          }
        }
        for (let cc of tags) {
          formData.append("cc_emails[]", cc);
        }

        axios({
          method: "post",
          url: `${freshdeskApisUrl}/${ticket?.ticket_id}/reply`,
          data: formData,
          headers: {
            Authorization: headersConfig.Authorization,
          },
        })
          .then((res) => {
            toast.success("Mail sent");
            setTimeout(async () => {
              // window.location.reload();
              hideModalEmail();

              await fetchAllTickets(false);
              fetchData(lead.leadDetail.id)
            }, 1000);
          })
          .catch((error) => console.error("ERROR : ", error.message));
      } else {

        const emailIdsUnq = [...new Set(tags)];
        const [toEmail, ...ccEmail] = emailIdsUnq;

        const emailsToFreshDesk = tags.join(",")
        const descriptionFreshDesk = emailDetails.emailDescription + `<!DOCTYPE html> 
            <html> 
            <head> </head> 
            <body> 
              <div dir='ltr' class='gmail_signature'> 
                <div dir='ltr'> 
                  <table style='color:rgb(0,0,0);font-family:Poppins,sans-serif;font-size:medium'> 
                    <tbody> 
                      <tr> 
                        <td style='font-size:12px'>
                          <span style='font-weight:600'>${user_info.name} |&nbsp;</span>
                          <font color='#0000ff'>Team Ambak</font>
                        </td> 
                      </tr> 
                      <tr style='display:flex;margin-top:5px'> 
                        <td style='margin-top:5px;display:flex'>
                          <a href='https://ambak.com/' style='color:rgb(17,85,204)' rel='noreferrer' target='_blank' data-saferedirecturl='https://www.google.com/url?q=https://ambak.com/&amp;source=gmail&amp;ust=1722318248056000&amp;usg=AOvVaw0tVwaNZz23ZIBv946jMbP4'>
                            <img src='https://ci3.googleusercontent.com/mail-sig/AIorK4yr2uCiizuiiSDXWwyUkGzq5kxyZyUukGNxRoJo9ssMOkiCu3O1wlYMu0pF9YxSTGFrMxMISv0' alt='faviconicon' width='45px' height='66px' style='margin-right:10px;border-right:1px solid lightgray;padding-right:10px' class='CToWUd' data-bit='iit'>
                          </a>
                        </td> 
                        <td> 
                          <div>
                            <a href='https://www.facebook.com/AmbakOfficial/' style='color:rgb(17,85,204)' rel='noreferrer' target='_blank' data-saferedirecturl='https://www.google.com/url?q=https://www.facebook.com/AmbakOfficial/&amp;source=gmail&amp;ust=1722318248056000&amp;usg=AOvVaw3-Qy2iDLy5mtTskTWBMYTF'>
                              <img src='https://ci3.googleusercontent.com/mail-sig/AIorK4xSv-h7oO1Ed44E9gjFnC_GcBGYaZ-87Rac2ozCexb62wxrM0IZfOC0Vh0mIwFgFCXTelcyAg8' alt='facebook' width='20px' height='20px' class='CToWUd' data-bit='iit'>
                            </a>&nbsp;
                            <a href='https://www.instagram.com/ambakofficial' style='color:rgb(17,85,204)' rel='noreferrer' target='_blank' data-saferedirecturl='https://www.google.com/url?q=https://www.instagram.com/ambakofficial&amp;source=gmail&amp;ust=1722318248056000&amp;usg=AOvVaw3O7fttVJo-lYNNmAnuknyZ'>
                              <img src='https://ci3.googleusercontent.com/mail-sig/AIorK4wh17UsbHaAdKESK_aDsw9mxSBCjYAoaCqNMbR7fWqAZIbuVNe9V2T1xseYmx18VGGsZ5jlh7U' alt='insta' width='20px' height='20px' class='CToWUd' data-bit='iit'>
                            </a>&nbsp;
                            <a href='https://www.linkedin.com/company/ambakofficial' style='color:rgb(17,85,204)' rel='noreferrer' target='_blank' data-saferedirecturl='https://www.google.com/url?q=https://www.linkedin.com/company/ambakofficial&amp;source=gmail&amp;ust=1722318248056000&amp;usg=AOvVaw2b2Fh1sb0gkUYkbu9RAv9s'>
                              <img src='https://ci3.googleusercontent.com/mail-sig/AIorK4wzbTmMt3ZRcaopcWFz5H9_lEXPXx7Ytl1stQ-49mCG_88MgScIFqZsDA63BUYh9Kk-VLutvAE' alt='link' width='20px' height='20px' class='CToWUd' data-bit='iit'>
                            </a>&nbsp;
                            <a href='https://www.youtube.com/@AmbakOfficial' style='color:rgb(17,85,204)' rel='noreferrer' target='_blank' data-saferedirecturl='https://www.google.com/url?q=https://www.youtube.com/@AmbakOfficial&amp;source=gmail&amp;ust=1722318248056000&amp;usg=AOvVaw1Z5_-JRGGpkgiZkhpJZU5n'>
                              <img src='https://ci3.googleusercontent.com/mail-sig/AIorK4we4kDPB3fXLe3NusrE8z94bZyUpd0If6qwcu6gGtv9A7-WZrQKURPQbptNFo-Zke_V51QZ03s' alt='link' width='20px' height='20px' class='CToWUd' data-bit='iit'>
                            </a>
                          </div> 
                        </td> 
                      </tr> 
                    </tbody> 
                  </table> 
                  <div style='text-align:left'><br></div> 
                </div> 
              </div> 
            </body> 
            </html>`;
        const freshDeskTicketData = {
          ticket_subject: emailDetails.emailSubject,
          ticket_initial_body: descriptionFreshDesk,
          ticket_initial_to_emails: emailsToFreshDesk
        };
        let formData = new FormData();
        formData.append("description", descriptionFreshDesk);
        formData.append("subject", emailDetails.emailSubject);
        formData.append("email", toEmail);
        formData.append("email_config_id", 1070000078051);
        formData.append("priority", 1);
        // formData.append("email_config_id", emailDetails.configEmailId);
        formData.append("status", 2);
        if (attachments && attachments.length > 0) {
          for (let file of attachments) {
            formData.append("attachments[]", file);
          }
        }

        for (let cc of emailIdsUnq) {
          formData.append("cc_emails[]", cc);
        }

        axios({
          method: "post",
          url: `${freshdeskApisUrl}`,
          data: formData,
          headers: {
            Authorization: headersConfig.Authorization,
          },
        })
          .then(async (res) => {
            const newTicket = {
              lead_id: lead?.leadDetail?.id,
              ticket_id: res.data?.id,
            };
            setTicketCreate(newTicket);
            setListOfTickets([{ ...res.data, ...newTicket }, ...listOfTicketIds])
            await linkFreshDeskTicketWithLead(newTicket, freshDeskTicketData);
            toast.success("Ticket created");
            setTimeout(async () => {
              // window.location.reload();
              hideModalEmail();
              await fetchAllTickets(false);
              fetchData(lead.leadDetail.id)
              handleTabClick(res.data.id, res.data.created_at, res.data.subject)
            }, 1000);
          })
          .catch((error) => console.error("ERROR : ", error.message));
      }
    }
  };

  const linkFreshDeskTicketWithLead = async (ticketCreateData, freshDeskTicketData = null) => {
    let mutation = CREATE_TICKETS;
    let variables = {
      createEmailTicketInput: {
        lead_id: ticketCreateData.lead_id,
        ticket_id: ticketCreateData.ticket_id,
      },
    };
    if (freshDeskTicketData?.ticket_subject) {
      variables.createEmailTicketInput.ticket_subject = freshDeskTicketData.ticket_subject
      variables.createEmailTicketInput.ticket_initial_body = freshDeskTicketData?.ticket_initial_body || null
      variables.createEmailTicketInput.ticket_initial_to_emails = freshDeskTicketData?.ticket_initial_to_emails || null
    }
    executeGraphQLMutation(mutation, variables, client);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    const { emailDescription, emailSubject } = emailDetails;

    setEmailDetails((current) => ({
      ...current,
      [id]: value,
    }));

    if (
      emailDescription != "" &&
      emailDescription !==
      undefined /* && (emailSubject != "" && emailSubject !== undefined) */
    ) {
      setSendMailDisabled(false);
    } else {
      setSendMailDisabled(true);
    }
  };

  // const handleInputMessgaeBodyChange = (text) => {
  //   setEmailDetails((current) => ({
  //     ...current,
  //     emailDescription: text,
  //   }));

  //   if (
  //     text != "" &&
  //     text !==
  //     undefined /* && (emailSubject != "" && emailSubject !== undefined) */
  //   ) {
  //     setSendMailDisabled(false);
  //   }
  // };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      if (validateEmail(emailDetails.emailTo)) {
        setEmailTags([...tags, emailDetails.emailTo]);
        setEmailDetails((current) => ({
          ...current,
          emailTo: "",
        }));
      } else {
        // alert('Invalid email address');
      }
    }
  };

  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  // const refreshPage = () => {
  //   window.location.reload();
  // };


  const showModalEmail = (e) => {
    const message = e ? "emailadd" : "emailreply";
    let id;
    if (e?.target) {
      ({ id } = e.target);
    }
    if (tagsOriginal.length) {
      setTags([...new Set(tagsOriginal)]);
    }
    (id === "emailadd" || message === "emailadd") ? setModalName("add") : setModalName("reply");
    // setShowEmail(true);
    setShowComposePopup(true);
    setShowDetailedView(false);
    setShowReplyEmail(false)

    document.body.classList.add("overflow-hidden");
  };

  useEffect(() => {
    if (inputField?.message) {
      editorRef?.current?.setData(inputField.message);

      showModalEmail({ target: { id: "emailadd" } });
      setEmailDetails((current) => ({
        ...current,
        emailDescription: inputField?.message,
      }));
    }
  }, [inputField?.message]);

  const hideModalEmail = () => {
    setEmailDetails({
      emailFrom: props?.formType === 'customer' ? "communication@ambak.com" : "fulfilment@ambak.com",
      configEmailId: props?.formType === 'customer' ? 1 : 2,
      emailDescription: "",
    })
    setField({ chat_type: 'TEXT', mode_type: 1, message: '', files: '' })
    editorRef?.current?.setData("");
    setShowEmail(false);
    document.body.classList.remove("overflow-hidden");
    handleCloseReply();
  };

  const handleAttachment = async (event) => {
    const files = Array.from(event.target.files);
    if (files && files.length) {
      setAttachments(event.target.files);
      setSelectedFileCount(files.length);
    }
  };

  const resetAttachment = () => {
    setSelectedFileCount(null);
    setAttachments(null);
  };

  const hideTemplate = () => {
    setShowTemplate(false);
    document.body.classList.add("overflow-hidden");
  };

  //  --------------------------------added------------------------------->
  const handleTabClick1 = (tab) => {
    props.sendToParent(tab);
  };

  const handleCloseCompose = () => {setShowComposePopup(false)};
  const handleCloseReply = () => { setShowReplyEmail(false) };
  const handleReplyClick = () => { setShowReplyEmail(true) };
  const handleBackClick = () => { setShowDetailedView(false) };
  const setTemplate = (value) => {
    hideTemplate()
    setShowComposePopup(true);
    setShowDetailedView(false);
    setShowReplyEmail(false)
    setField({ ...inputField, message: value })
  }
  const formType1 = props?.formType || "customer";
  //  -----------------------------------------------end

  return (
    <>
      <div className="emailpopup_conatiner">
        {/* {props?.emailForComs && ( */}
        <div className="left_container">
          <div className="emailstickycont">
            <div className="Compose_email">
              <div className="emailinbox">
                Inbox
              </div>
              <div className="formsyinEMAIL">
                <Formsy onSubmit={(formData) => console.log(formData)}>
                  <fieldset className="select-template">
                    <FormsySelect
                      name="compose_option"
                      id="emailadd"
                      inputProps={{
                        options: [
                          { value: "new_compose", label: "New Compose" },
                          ...list.map((ob) => ({
                            value: ob.template,
                            label: ob.name,
                          })),
                        ],
                        // placeholder: "Select a template",Selec
                        placeholder: loanDetailData["compose_option"] ? " " : "Select a template",
                        className: "react-select select-radius",
                        classNamePrefix: "react-select ",
                        value: [
                          { value: "new_compose", label: "New Compose" },
                          ...list.map((ob) => ({
                            value: ob.template,
                            label: ob.name,
                            subject: ob?.subject,
                          })),
                        ].find(({ value }) => value === loanDetailData["compose_option"]),
                        isDisabled: false,
                      }}
                      required={false}
                      value={loanDetailData["compose_option"]}
                      onChange={(data) => {
                        if (data.value === "new_compose") {
                          // Handle logic for "New Compose"
                          showModalEmail({ target: { id: "emailadd" } });
                          hideModalEmail();
                          document.body.classList.add("overflow-hidden");

                        } else {
                          const selectedTemplate = list.find((ob) => ob.template === data.value);
                          showModalEmail({ target: { id: "emailadd" } });
                          
                          setTimeout(() => {
                            setTemplate(selectedTemplate?.template || "");
                            setEmailDetails((current) => ({
                              ...current,
                              emailSubject: selectedTemplate?.subject || "",
                            }))
                          }, 1000);
                        }
                        loanDetailData["compose_option"] = data.value;
                      }}
                    />
                  </fieldset>


                </Formsy>

              </div>

            </div>

            <div className="email_tabs">
              <div
                className={`email_tab ${formType1 === "customer" ? "active" : ""}`}
                onClick={() => handleTabClick1("customer")}
              >
                Customer
              </div>
              <div
                className={`email_tab ${formType1 === "bank_poc" ? "active" : ""}`}
                onClick={() => handleTabClick1("bank")}
              >
                Banker
              </div>
              {/* <div
              className={`email_tab ${activeEmailTab === "Confirmation" ? "active" : ""}`}
              onClick={() => handleTabClick1("Confirmation")}
            >
              Confirmation
            </div> */}
            </div>
          </div>

          <div className="Recieve_Email_scroller">

            {tickets.map((ticket, index) => (
              <div className={`Email_textContainer ${selectedEmailId === ticket.id ? 'selected' : ''
                }`}
                onClick={() => handleTabClick(ticket.id, ticket.created_at, ticket.subject)}

                key={index}>

                <div className="dummyimgcont">
                  <img src={docdummyimg} alt="icon" width={50} height={50} />
                </div>
                <div className="message_container">

                  <div className="name_cont">
                    <div> {lead.leadDetail?.customer?.first_name + ' ' + lead.leadDetail?.customer?.last_name}</div>
                    <div className="timediv">  {dateFormat(ticket.created_at, "d mmm,yyyy hh:MM")}</div>
                  </div>
                  <div className="leaddoc_contain">
                    <div>

                      <div className="Lead_text">{ticket.subject}</div>
                      <div className="mess_text">
                        Ticket Id :- {ticket.id || ""}
                      </div>
                    </div>
                    {/* <div className="attach_cont">
                              <div>
                                <img src={redtic} alt="icon" width={8} height={8} />
                              </div>
                              <div className="attactfilediv"><img src={attachmentsicon} alt="icon" width={20} height={17.7} /></div>
                            </div> */}
                  </div>

                </div>
              </div>
            ))}




            {/* ---------------If no Email added--------------------- */}

            {/* <div className="noemail_added">
                <div>
                  No Customer Email Address is Added, <br />
                  Please Add First.
                </div>
                <div className="confirm_mail">
                  <input text="" placeholder="Add Email ID" />
                  <button type="submit">Confirm</button>
                </div>
              </div> */}

          </div>
        </div>
        {/* // )} */}
        {props?.emailForComs && (

          <div className="right_main_container">
            {/* -----------------------------------reply popup------------------------------ */}
            {showReplyEmail ? (
              <div className="Reply_main_container">
                <div className="cross_conatienr">
                  <div className="Reply_cont">
                    <img src={replyimg} alt="icon" width={17.6} height={15} />
                    Reply
                  </div>
                  <div className="Cursor_Pointer" onClick={handleCloseReply}>
                    <img src={crosssvg} alt="icon" width={22} height={22} />
                  </div>
                </div>
                <div className="to_cont">
                  <div className="allupperinputs3">
                    <div className="addmailinputs">
                      {/* <ul>
                      {tags.map((tag, index) => (
                        <li key={index} className="email_tag">
                          {tag} <i onClick={() => removeTag(index, "emailTo")}>X</i>
                        </li>
                      ))}
                      <input
                        type="text"
                        placeholder=""
                        className="to_input"
                        value={emailDetails.emailTo || ""}
                        onChange={(e) => handleInputChange(e, "emailTo")}
                        onKeyUp={(e) => handleKeyUp(e, "emailTo")}
                      />
                    </ul> */}
                      <ul>
                        {tags.map((tag, index) => (
                          <li key={index}>
                            {tag} <i onClick={() => removeTag(index)}>X</i>
                          </li>
                        ))}
                        <input
                          type="text"
                          id="emailTo"
                          value={emailDetails.emailTo || ""}
                          onChange={handleInputChange}
                          onKeyUp={handleKeyUp}
                          required={true}
                        />
                      </ul>
                    </div>
                  </div>
                  {/* <div onClick={toggleCcInput} className="Cc_cont">Cc</div> */}
                </div>
                {/* {showCcInput && (
                <div className="to_cont">
                  <div className="addmailinputs">
                    <ul>
                      {ccTags.map((tag, index) => (
                        <li key={index} className="email_tag">
                          {tag} <i onClick={() => removeTag(index, "emailCc")}>X</i>
                        </li>
                      ))}
                      <input
                        type="text"
                        placeholder=""
                        className="to_input"
                        value={emailDetails.emailCc || ""}
                        onChange={(e) => handleInputChange(e, "emailCc")}
                        onKeyUp={(e) => handleKeyUp(e, "emailCc")}
                      />
                    </ul>
                  </div>
                </div>
              )} */}

                <div className="to_cont">
                  <div className="allupperinputs3">
                    <div className="addmailinputs">
                      Subject: &nbsp;
                      <input
                        type="text"
                        id="emailSubject"
                        value={(modalName === "reply" ? (tabEmailData?.ticket_subject || tabEmailData?.subject) : (emailDetails.emailSubject || ""))}
                        onChange={handleInputChange}
                        required={true}
                        readOnly={!!(modalName === "reply")}
                      />

                    </div>
                  </div>
                  {/* <div onClick={toggleCcInput} className="Cc_cont">Cc</div> */}
                </div>
                <div className="full_input_container">
                  {/* <textarea placeholder="Enter text" className="reply_textarea"></textarea> */}
                  <CKEditor
                    initData={emailDetails?.emailDescription}
                    onInstanceReady={(event) => {
                      editorRef.current = event.editor;
                    }}
                    onChange={(event) => {
                      const data = event.editor.getData();
                      setEmailDetails((current) => ({
                        ...current,
                        emailDescription: data,
                      }));
                      setSendMailDisabled(!data);
                    }}
                    config={{
                      allowedContent: true,
                      extraAllowedContent: '*(*);*{*}',
                      extraPlugins: 'autogrow',
                      versionCheck: false,
                      toolbar: [
                        ['Source', '-', 'Bold', 'Italic', 'Underline', 'RemoveFormat'],
                        ['Link', 'Unlink', 'Anchor'],
                        ['Table', 'HorizontalRule'],
                        ['Styles', 'Format', 'Font', 'FontSize'],
                        ['TextColor', 'BGColor'],
                      ],
                      autoGrow_minHeight: 200, 
                      autoGrow_maxHeight: 270, 
                      autoGrow_onStartup: true,
                    }}
                  />
                </div>
                <div className="Send_cont">
                  <div className="send_btn">
                    <button onClick={handleSubmit} disabled={sendMailDisabled}>Send</button>
                  </div>
                  {selectedFileCount && (
                    <div className="attechedfillcount">
                      <p>Count : {selectedFileCount}</p>
                      <div className="crossicon" onClick={resetAttachment}>
                        X
                      </div>
                    </div>
                  )}
                  <div className="attchDoc_cont">
                    <label className="attach_doc">
                      <img src={attachmentsicon} alt="icon" width={20} height={17.7} /> Attach Doc
                      <input
                        type="file"
                        id="Uloadfil"
                        onChange={handleAttachment}
                        multiple
                         />
                    </label>
                    <img onClick={handleCloseReply} src={bin} alt="icon" width={32} height={32} />
                  </div>
                </div>
              </div>
            ) : (
              " "
            )}
            {/* {showforwardEmail ? (
            <div className="Reply_main_container">

              <div className="cross_conatienr">
                <div className="Reply_cont">
                  <img src={forwardimg} alt="icon" width={20.6} height={12} />

                  Forward</div>
                <div onClick={handleCloseForward} style={{ cursor: "pointer" }}>
                  <img src={crosssvg} alt="icon" width={22} height={22} />

                </div>
              </div>
              <div className="to_cont">

                <input
                  type="text"
                  placeholder="Enter recipient"
                  className="to_input"
                />
                <div onClick={toggleCcInput} className="Cc_cont">Cc</div>

              </div>
              {showCcInput && (
                <div className="to_cont">
                  <input
                    type="text"
                    placeholder="Enter Cc recipient"
                    className="to_input"
                  />
                </div>
              )}
              <div className="full_input_container">
                <textarea placeholder="Enter text" className="reply_textarea"></textarea>
              </div>
              <div className="Send_cont">
                <div className="send_btn">
                  <button>Send</button>
                </div>
                <div className="attchDoc_cont">
                  <label className="attach_doc">
                    <img src={attachmentsicon} alt="icon" width={20} height={17.7} /> Attach Doc
                    <input type="file" style={{ display: "none" }} />
                  </label>
                  <img src={bin} alt="icon" width={32} height={32} />
                </div>
              </div>
            </div>
          ) : (
            " "
          )} */}

            {/* ---------------------------new composepopup------------------------------------------------- */}
            {showComposePopup &&
              <div className="NewCompose_main_container">

                <div className="cross_conatienr">
                  <div>New Message</div>
                  <div onClick={handleCloseCompose} className="Cursor_Pointer">
                    <img src={crosssvg} alt="icon" width={22} height={22} />
                  </div>
                </div>
                <div className="to_cont">
                  To:
                  <div className="allupperinputs3">
                    <div className="addmailinputs">
                      {/* <ul>
                      {tags.map((tag, index) => (
                        <li key={index} className="email_tag">
                          {tag} <i onClick={() => removeTag(index, "emailTo")}>X</i>
                        </li>
                      ))}
                      <input
                        type="text"
                        placeholder=""
                        className="to_input"
                        value={emailDetails.emailTo || ""}
                        onChange={(e) => handleInputChange(e, "emailTo")}
                        onKeyUp={(e) => handleKeyUp(e, "emailTo")}
                      />
                    </ul> */}
                      <ul>
                        {tags.map((tag, index) => (
                          <li key={index}>
                            {tag} <i onClick={() => removeTag(index)}>X</i>
                          </li>
                        ))}
                        <input
                          type="text"
                          id="emailTo"
                          value={emailDetails.emailTo || ""}
                          onChange={handleInputChange}
                          onKeyUp={handleKeyUp}
                          required={true}
                        />
                      </ul>
                    </div>
                  </div>
                  {/* <div onClick={toggleCcInput} className="Cc_cont">Cc</div> */}
                </div>
                {/* {showCcInput && (
                <div className="to_cont">
                  <div className="addmailinputs">
                    <ul>
                      {ccTags.map((tag, index) => (
                        <li key={index} className="email_tag">
                          {tag} <i onClick={() => removeTag(index, "emailCc")}>X</i>
                        </li>
                      ))}
                      <input
                        type="text"
                        placeholder=""
                        className="to_input"
                        value={emailDetails.emailCc || ""}
                        onChange={(e) => handleInputChange(e, "emailCc")}
                        onKeyUp={(e) => handleKeyUp(e, "emailCc")}
                      />
                    </ul>
                  </div>
                </div>
              )} */}
                <div className="to_cont">
                  Subject :
                  <input
                    className="subject_input"
                    type="text"
                    id="emailSubject"
                    value={(modalName === "reply" ? (tabEmailData?.ticket_subject || tabEmailData?.subject) : (emailDetails.emailSubject || ""))}
                    onChange={handleInputChange}
                    required={true}
                    readOnly={!!(modalName === "reply")}
                  />
                </div>
                <div className="full_input_container">
                  <CKEditor
                    initData={emailDetails?.emailDescription}
                    onInstanceReady={(event) => {
                      editorRef.current = event.editor;
                    }}
                    onChange={(event) => {
                      const data = event.editor.getData();
                      setEmailDetails((current) => ({
                        ...current,
                        emailDescription: data,
                      }));
                      setSendMailDisabled(!data);
                    }}
                    config={{
                      allowedContent: true,
                      extraAllowedContent: '*(*);*{*}',
                      extraPlugins: 'autogrow',
                      versionCheck: false,
                      toolbar: [
                        ['Source', '-', 'Bold', 'Italic', 'Underline', 'RemoveFormat'],
                        ['Link', 'Unlink', 'Anchor'],
                        ['Table', 'HorizontalRule'],
                        ['Styles', 'Format', 'Font', 'FontSize'],
                        ['TextColor', 'BGColor'],
                      ],
                      autoGrow_minHeight: 200, 
                      autoGrow_maxHeight: 360, 
                      autoGrow_onStartup: true, 
                    }}
                  />
                </div>
                <div className="Send_cont">
                  <div className="send_btn">
                    <button onClick={handleSubmit} disabled={sendMailDisabled}>
                      Send</button>
                  </div>
                  {selectedFileCount && (
                    <div className="attechedfillcount">
                      <p>Count : {selectedFileCount}</p>
                      <div className="crossicon" onClick={resetAttachment}>
                        X
                      </div>
                    </div>
                  )}
                  <div className="attchDoc_cont">
                    <label className="attach_doc">
                      <img src={attachmentsicon} alt="icon" width={20} height={17.7} /> Attach Doc
                      <input
                        type="file"
                        id="Uloadfil"
                        onChange={handleAttachment}
                        multiple
                        />
                    </label>
                    <img onClick={handleCloseCompose} src={bin} alt="icon" width={32} height={32} />
                  </div>
                </div>
              </div>


            }
            {showDetailedView ? (
              (tabEmailData?.ticket_subject || tabEmailData?.subject) && (
                <div>
                  <div className="mainhead_cont">
                    <div onClick={handleBackClick} className="backsvglogo">
                      <img src={backsvg} alt="icon" width={20} height={20} />
                    </div>
                  </div>

                  {[...defaultConversation, ...conversation].map((con, index) => (
                    <div className="right_inbox_cont_main" key={con.id || index}>
                      <div className="right_inbox_cont">
                        <div className="profil_cont_main">
                          <div className="profil_cont">
                            <div>
                              <img src={docdummyimg} alt="icon" width={50} height={50} />
                            </div>
                            <div className="mail_user_info">
                              <div className="mail_usernam">
                                {lead.leadDetail?.customer?.first_name + ' ' + lead.leadDetail?.customer?.last_name}
                              </div>
                              <div className="mess_text">
                                <span>
                                  {Array.isArray(con.to_emails)
                                    ? con.to_emails.join(", ")
                                    : con.to_emails || "N/A"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="timediv1">
                            {dateFormat(con?.created_date, "d mmm, yyyy hh:MM")}
                          </div>
                        </div>

                        <div>
                          <p className="mail_body">
                            {con?.body_text_initial ? (
                              <h3 className="emailcommondetails" dangerouslySetInnerHTML={{ __html: con.body_text_initial }} />
                            ) : (
                              <h3 className="emailcommondetails">
                                {con?.body_text || "No Content Available"}
                              </h3>
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="reply_maincont">
                        <button id="emailreply" onClick={handleReplyClick}>
                          <img src={replyimg} alt="icon" width={11.6} height={10} />
                          Reply
                        </button>
                        {/* 
            <button onClick={handleReplyClick}>
              <img src={replyimg} alt="icon" width={11.6} height={10} />
              Reply all
            </button>
            <button onClick={handleForwardClick}>
              <img src={fordwardimg} alt="icon" width={16.7} height={20} />
              Forward
            </button>
            */}
                      </div>
                      
                    </div>
                  ))}
                </div>
              )
            ) : (
              " "
            )}

            {tickets.length === 0 && props?.emailForComs && (
              <>
                <div className="create_tickectDIV">
                  <h3 className="emailheadingticket1">
                    <span>➢</span>
                    {props?.formType === 'customer' ? "Please create a ticket to begin conversation with the customer" : "Please create a ticket to begin conversation with the bankers"}
                  </h3>
                </div>
              </>
            )}

          </div>
        )}

        {!props?.emailForComs && (
          <div className="addemail_bank_div">
            <img src={EmptyFillSomething} alt="icon" width={332} height={136} />

            <h3 className="emailheadingticket2" >
              {props?.formType === 'customer' ? "Please add this customer's email id to be able to communicate with them" : "Please add the bank POC along with the banker's email for this lead, enabling communication with them."}
            </h3>
          </div>
        )}

      </div>
    </>
  );
};

export default EmailThread;
